import isEqual from "lodash.isequal";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, Route, useParams } from "react-router-dom";
import { withLoadablePagePropsAndProps } from "../../base/Page";
import Button from "../../components/Button";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import useStore from "../../hooks/useStore";
import Api from "../../session/Api";
import { makeObservable } from "../../util/makeObservable";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";
import { ReportLayout } from "../components/report-layout/report-layout";
import { ReportTable } from "../components/table/report-table";
import { getReport } from "../reports/reports";
import { Report } from "./report";
import { ReportView } from "./report-view";
import { isMobileScreen } from "../../App";


export function routeReport(reportName) {
    const report = getReport(reportName);
    const path = report.customPath ?? ("/rb/" + reportName);
    return <Route exact path={path} key={"reporting-" + reportName} render={props => <ReportPage {...props} reportName={reportName} />} />
}


export function listDetailNew(reportName) {
    return getReportLink(reportName, { openNewPanelInitially: true })
}

export function listDetailItemId(reportName, itemId) {
    return getReportLink(reportName, { openNewPanelInitiallyItemId: itemId })
}

export function getReportLink(reportName, state) {
    const report = getReport(reportName);
    const path = report.customPath ?? ("/rb/" + reportName);
    if (state) {
        return path + '?query=' + btoa(JSON.stringify(state))
    } else {
        return path;
    }
}

export function getReportDesignLink(reportName, designId) {
    const report = getReport(reportName);
    const path = report.customPath ?? ("/rb/" + reportName);
    return path + '?reportId=' + designId
}



const stateStorageKey = reportName => 'rberp-re-' + reportName + '-state';

function saveReportState(reportName, state) {
    window.localStorage.setItem(stateStorageKey(reportName), JSON.stringify(state));
}

function loadReportState(reportName) {
    const item = window.localStorage.getItem(stateStorageKey(reportName))
    return Util.isStringExists(item) ? JSON.parse(item) : undefined;
}

const useReportState = (reportInfo, selectDefaultReportId, selectReportId) => {
    const store = useMemo(() => {
        const observable = makeObservable();

        const state = loadReportState(reportInfo.name)
        if (state) {
            observable.setObject(state)
            observable.set("showColumns", false)
            observable.set("showAdvancedFilter", false)
        } else {
            observable.set("selectedSavedReportId", 0)

            observable.set("showGroupNames", true)
            observable.set("showFooter", true)
            observable.set("showDarkMode", false)

            observable.set("showQuickFilter", true)
            observable.set("showFormulaValues", true)
            observable.set("showPayloadBar", true)

            observable.set("showColumns", false)
            observable.set("showAdvancedFilter", false)

            observable.set("refreshReportContentRequest", Util.newTempId())
        }

        if (selectDefaultReportId) {
            observable.set('selectedSavedReportId', 0)
        }
        if (selectReportId) {
            observable.set('selectedSavedReportId', parseInt(selectReportId))
        }

        const selectedSavedReportId = observable.get('selectedSavedReportId')
        if (Util.isNumberExist(selectedSavedReportId) && !reportInfo.savedReports.find(report => report.id === selectedSavedReportId)) {
            observable.set("selectedSavedReportId", 0)
        }
        return observable;
    }, [])
    useEffect(() => store.subscribeGlobal(() => saveReportState(reportInfo.name, store.toObject())), [reportInfo, store])
    return store;
}



function parseRequest(request) {
    if (request) {
        return JSON.parse(atob(request))
    } else {
        return {}
    }
}

const useDesignState = (report, request, store) => {
    const shouldConsumeQuery = useRef(false);
    const consumedQuery = useRef(false)

    const [reportState, setReportState] = useState(() => {
        const shouldSortById = report.isListMode && !Util.isNumberExist(store.get('selectedSavedReportId'))

        if (request || shouldSortById) {
            shouldConsumeQuery.current = true;

            if (request) {
                return parseRequest(request);
            } else {
                return {
                    sorts: [{
                        property: "id",
                        order: "DESC",
                        aloneSortOnly: true
                    }]
                }
            }
        } else {
            return {}
        }
    });
    const [originalState, setOriginalState] = useState(() => {
        const shouldSortById = report.isListMode && !Util.isNumberExist(store.get('selectedSavedReportId'))

        if (!request && shouldSortById) {
            return {
                sorts: [{
                    property: "id",
                    order: "DESC",
                    aloneSortOnly: true
                }]
            }
        } else {
            return {}
        }
    })

    useEffect(() => {
        const shouldSortById = report.isListMode && !Util.isNumberExist(store.get('selectedSavedReportId'))
        if (shouldSortById && isEqual(reportState, originalState) && isEqual(reportState, {})) {
            const update = {
                sorts: [{
                    property: "id",
                    order: "DESC",
                    aloneSortOnly: true
                }]
            };
            setReportState(update)
            setOriginalState(update)
        }
    }, [reportState, originalState])

    return [reportState, originalState, (arg, mode = { report: true, original: false }) => {
        if (shouldConsumeQuery.current && !consumedQuery.current) {
            consumedQuery.current = true;
            return;
        }
        if (mode.report) {
            setReportState(arg);
        }
        if (mode.original) {
            setOriginalState(arg);
        }
    }]
}


function View({ payload, embeddedMode, componentMode, selectionMode, overrideQuery }) {
    let { query, reportId: selectReportId } = useLocationQuery()
    if (overrideQuery) {
        query = overrideQuery;
        selectReportId = undefined;
    }

    const [reportInfo, setReportInfo] = useState(payload);
    const report = useMemo(() => getReport(reportInfo.name), [reportInfo.name])


    const store = useReportState(reportInfo, query !== undefined, selectReportId);
    const [state, originalState, setState] = useDesignState(report, query, store)

    // use this for links
    // console.log(JSON.stringify(state));
    if (isMobileScreen) {
        componentMode = true;
    }

    return (<>
        <ReportLayout info={reportInfo} setInfo={setReportInfo} store={store} state={state} originalState={originalState} setState={setState} isListMode={report.isListMode} isManageMode={report.isManageMode} isTreeSructure={report.isTreeSructure} viewBased={report.viewBased} embeddedMode={embeddedMode} componentMode={componentMode} selectionMode={selectionMode}>
            <ReportView info={reportInfo} store={store} state={state} setState={setState} isListMode={report.isListMode} isManageMode={report.isManageMode} viewBased={report.viewBased} embeddedMode={embeddedMode} componentMode={componentMode} selectionMode={selectionMode} />
        </ReportLayout>
    </>)
}

export const ReportPage = withLoadablePagePropsAndProps(props => listener => Api.getReportInfo(props.reportName, listener), View)