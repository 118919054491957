import { TextInput, ExpandableSearch, ComboBox, Checkbox, ComposedModal, ModalHeader, ModalBody, ModalFooter, Select, SelectItem, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import React, { useEffect, useRef, useState } from 'react'
import ProfilePic from '../../components/ProfilePic';
import { getObjectTypeUrl, OBJECT_TYPE_INVENTORY_BATCH_NO, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_GROUP } from '../../constants/ObjectTypes';
import Api from '../../session/Api';
import { RowDelete32, DataReference16, Barcode16, Categories16, Error16, AppConnectivity16, OverflowMenuVertical16, Search16, Box16 } from '@carbon/icons-react';
import Button from '../../components/Button';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import { Link } from 'react-router-dom';
import { BUNDLE_SHOULD_NOT_CONTAIN_GROUP, DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_SUPPLIER, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_WAREHOUSE } from '../../constants/Constants';
import WhiteCheckbox from '../../components/checkbox/WhiteCheckbox';
// import Checkbox from 'rc-checkbox';
// import 'rc-checkbox/assets/index.css';

import JsBarcode from 'jsbarcode'
import { printProductLabels } from '../../pdfs/product-labels/ProductLabelsPdf';
import ProductFinder from './ProductFinder';
import LabelPrintViewDialog from '../../pages/label-print/LabelPrintViewDialog';
import StockFlowUtil from '../../stock-system/StockFlowUtil';
import BundleReplaceDialog from './BundleReplaceDialog';
import ProductGroupReplaceDialog from './ProductGroupReplaceDialog';
import { ProductDivisionDialog } from '../../pages/product-group/ProductGroupDetailPage';
import { ProductGroupSetAttribute } from './ProductGroupSetAttribute';
import { IngredientSearchDialog } from '../../pages/pos/dialogs/any-search-dialog';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { DataListField } from '../../components/ComboBoxField';
import { ExtendableComboBox, NonStateExtendableComboBox, NonStateExtendableComboBoxTwo } from '../../components/extendable-combo-box';

export default class extends React.Component {

    shouldComponentUpdate(prevProps) {
        //return this.props.cartItem != prevProps.cartItem || this.props.scrollInto != prevProps.scrollInto || this.props.removingItem != prevProps.removingItem
        return true
    }

    render() {
        //return <CartItemF {...this.props} />
        return <ProductFieldF {...this.props} />
    }

}

const ProductFieldF = ({
    productFinderShowFakeTextFieldAtFirst, productFinderShowReplaceWithBundle, productFinderShowReplaceWithProductGroup, productId, onProductName, onProductLoaded,
    productType, defaultProductValues, onProductChange, onStockValueChanged, onValueChanged, labelText, nonExistent, onInputRemoved, stockItemMode, templateEditorMode,
    longList, quantityFieldOnly, allowBundlesWithGroups = false
}) => {
    const quantityTextFieldRef = useRef();
    const printCountTextFieldRef = useRef();
    const _trs_force_uom_id_ref = useRef();

    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState(Util.newTempId())
    const [barcodeValue, setBarcodeValue] = useState("")
    const [quantityValue, setQuantity] = useState(0);
    const [serialNoValue, setSerialNoValue] = useState("");
    const [batchNoValue, setBatchNoValue] = useState("");
    const [amount, setAmount] = useState(0);
    const [printCount, setPrintCount] = useState(0);
    const [defaultValues, setDefaultValues] = useState({});
    const [productMini, setProductMini] = useState(undefined);
    const [foc, setFoc] = useState(false);
    const [selectedBarcode, setSelectedBarcode] = useState(undefined);
    const [showReplaceDialog, setShowReplaceDialog] = useState(false);
    const [showReplaceGroupDialog, setShowReplaceGroupDialog] = useState(false);

    const [showReplaceAttributeDialog, setShowReplaceAttributeDialog] = useState(false);
    const [parentProductGroupId, setParentProductGroupId] = useState(0)

    const [requiresSerialNo, setRequiresSerialNo] = useState(false);
    const [trackInventoryBatchNo, setTrackInventoryBatchNo] = useState(false);
    const [productCost, setProductCost] = useState(undefined)

    const [unconvertedQuantity, setUnconvertedQuantity] = useState(0);
    const [measurementType, setMeasurementType] = useState(undefined);
    const [selectedUOM, setSelectedUOM] = useState()
    const getDefaultUOM = () => ({ symbol: measurementType.defaultUomSymbol, name: measurementType.defaultUomName })

    const hasProduct = Util.isNumberExist(productId);

    useEffect(() => {
        if (selectedUOM) {
            const value = parseFloat((parseFloat(unconvertedQuantity) * selectedUOM.conversionFactor).toFixed(4));
            setQuantity(value)
            onStockValueChanged('quantityValue', value)
        } else {
            // setQuantity(0)
            //setUnconvertedQuantity(quantityValue)
            setUnconvertedQuantity(0)
        }
    }, [selectedUOM, unconvertedQuantity])
    useEffect(() => {
        // setSelectedUOM(undefined)
        // onStockValueChanged('_selected_uom', undefined)

        if (measurementType && _trs_force_uom_id_ref.current) {
            const uom = measurementType.unitOfMeasurements.find($ => $.id == _trs_force_uom_id_ref.current.uomId);
            setSelectedUOM(uom)
            onStockValueChanged('_selected_uom', uom)

            const qv = _trs_force_uom_id_ref.current.qty;
            queueMicrotask(() => {
                // console.log({ qv })
                setUnconvertedQuantity(qv)
                onStockValueChanged('_unconverted_quantityValue', qv);
            })

            _trs_force_uom_id_ref.current = null;
        }

    }, [measurementType])

    // console.log({ defaultProductValues })

    useEffect(() => {
        if (Util.isNumberExist(productId)) {
            const onMiniLoaded = (response, focus = true) => {

                if (_trs_force_uom_id_ref.current === undefined && defaultProductValues._trs_force_uom_id) {
                    _trs_force_uom_id_ref.current = {
                        uomId: defaultProductValues._trs_force_uom_id,
                        qty: defaultProductValues.quantityValue,
                    };
                }

                if (response.status === true && response.payload && response.payload.measurementType) {
                    setMeasurementType(response.payload.measurementType)
                } else {
                    setMeasurementType(undefined)
                }

                if (response.status === true && response.payload && response.payload.requiresSerialNo) {
                    setRequiresSerialNo(true)
                } else {
                    setRequiresSerialNo(false)
                }

                setTrackInventoryBatchNo(response.status === true && response.payload && response.payload.trackInventoryBatchNo)

                if (response.status === true && response.payload) {
                    setProductCost(response.payload.cost)
                }

                if (response.status === true && response.payload && response.payload.parentProductGroupId) {
                    setParentProductGroupId(response.payload.parentProductGroupId)
                } else {
                    setParentProductGroupId(0)
                }

                if (response.status === true) {
                    setProductMini(response.payload)
                    if (response.payload && onProductName) {
                        onProductName(response.payload.name)
                    }
                    if (response.payload && onProductLoaded) {
                        onProductLoaded(response.payload)
                    }
                    if (focus) {
                        setTimeout(() => {
                            if (quantityTextFieldRef.current) {
                                quantityTextFieldRef.current.focus();
                                quantityTextFieldRef.current.select();
                            } else if (printCountTextFieldRef.current) {
                                printCountTextFieldRef.current.focus();
                                printCountTextFieldRef.current.select();
                            }
                        })
                    }

                    if (templateEditorMode) {
                        setPrintCount(1);

                        if (onValueChanged !== undefined) {
                            onValueChanged('_templateCopyCount', 1)
                        }
                    }

                    if (stockItemMode) {
                        let cost = response.payload.cost !== null ? response.payload.cost.toFixed(2) : 0;
                        let price = response.payload.amount.price !== null ? response.payload.amount.price.toFixed(2) : 0;
                        let refund = stockItemMode.destinationType == DESTINATION_TYPE_SUPPLIER ? cost : price;

                        let amount = 0;
                        let quantityValue = 1;

                        if (StockFlowUtil.isSales(stockItemMode.stockFlow)) {
                            // amount = price;

                            if (response.payload.amountDoesNotIncludeTax === true) {
                                amount = price;
                            } else {
                                const withTax = price;
                                const taxRate = 5 / 100;
                                const withoutTax = withTax - ((withTax / (1 + taxRate)) * taxRate);

                                amount = parseFloat(withoutTax.toFixed(2));
                            }
                        } else if (StockFlowUtil.isPurchase(stockItemMode.stockFlow) || StockFlowUtil.isCreation(stockItemMode.stockFlow)) {
                            amount = cost;
                        } else if (StockFlowUtil.isReturn(stockItemMode.stockFlow)) {
                            amount = refund;
                            // amount = 0;
                        }


                        setDefaultValues({ amount });

                        if (defaultProductValues.amount && defaultProductValues.amount !== amount) {
                            amount = defaultProductValues.amount;
                        }
                        if (defaultProductValues.quantityValue && defaultProductValues.quantityValue !== quantityValue) {
                            quantityValue = defaultProductValues.quantityValue;
                        }


                        if (response.status === true && response.payload && response.payload.requiresSerialNo) {
                            quantityValue = 1;
                        }



                        if (defaultProductValues.foc) {
                            amount = 0;
                        }
                        setAmount(amount);
                        setQuantity(quantityValue)
                        setFoc(defaultProductValues.foc);
                        setSerialNoValue(defaultProductValues.serialNumber || "");
                        setBatchNoValue(defaultProductValues.batchNo || "");
                        setUnconvertedQuantity(defaultProductValues._unconverted_quantityValue);
                        setSelectedUOM(defaultProductValues._selected_uom)
                        // console.log('have to set: ', defaultProductValues._selected_uom)
                        // setSelec


                        if (onStockValueChanged !== undefined) {
                            onStockValueChanged('amount', amount);
                            onStockValueChanged('quantityValue', quantityValue)
                            onStockValueChanged('serialNumber', defaultProductValues.serialNumber || "")
                            onStockValueChanged('batchNo', defaultProductValues.batchNo || "")
                            if (response.status === true && response.payload) {
                                onStockValueChanged("loadedProductMini", response.payload)
                            }
                        }
                    }
                } else {
                    onProductChange({ type: 0, id: 0 });
                    // onClearBtn();
                    // setTimeout(() => onClearBtn(), 1000)
                    if (response.payload === BUNDLE_SHOULD_NOT_CONTAIN_GROUP) {
                        UIUtil.showError("Bundle should not contain any product groups");
                    } else {
                        UIUtil.showError(response.message);
                    }
                }
                setLoading(false);
            };

            if (defaultProductValues?.loadedProductMini) {
                onMiniLoaded({ status: true, payload: defaultProductValues.loadedProductMini }, false)
            } else {
                Api.getProductMini(productType, productId, stockItemMode === undefined ? false : true, allowBundlesWithGroups, onMiniLoaded)
            }
        }
    }, [productId])

    const onSearchBtn = (forcedValue) => {
        const value = forcedValue !== undefined ? forcedValue : barcodeValue;
        if (!Util.isStringExists(value)) {
            return value;
        }

        setLoading(true)
        Api.barcodeSearch(value, response => {
            setBarcodeValue("")
            if (response.status === true) {
                if (response.payload.foundResult && (response.payload.resultObjectType == OBJECT_TYPE_PRODUCT || response.payload.resultObjectType == OBJECT_TYPE_PRODUCT_BUNDLE)) {
                    onProductChange({ type: response.payload.resultObjectType, id: response.payload.resultObjectId });
                    setSearchKey(Util.newTempId())

                    UIUtil.showSuccess("Product found!")
                } else {
                    UIUtil.showInfo("Barcode not found")
                    setLoading(false);
                }
            } else {
                UIUtil.showError(response.message);
                setLoading(false);
            }
        })
    }

    const onClearBtn = () => {
        onProductChange({ type: 0, id: 0 });
        if (onInputRemoved !== undefined) {
            onInputRemoved();
        }
    }


    const hasAmount = stockItemMode && productMini && (
        StockFlowUtil.isSales(stockItemMode.stockFlow) ||
        StockFlowUtil.isPurchase(stockItemMode.stockFlow) ||
        StockFlowUtil.isCreation(stockItemMode.stockFlow) ||
        StockFlowUtil.isReturn(stockItemMode.stockFlow)
    );

    const getAmountTotal = () => hasAmount ? (amount * quantityValue) : 0

    const getAmount = () => {
        if (foc) {
            return "FOC";
        }

        const total = getAmountTotal();

        let value = total.toFixed(2);
        if (Number.isInteger(parseFloat(value))) {
            value = parseFloat(value).toFixed(0);
        }
        //return 'AED' + " " + value;
        return value;
    }

    const onSelectAttributeBtn = () => {
        UIUtil.showOverlay2(onClose => <ProductGroupSetAttribute defaultProductId={productMini.id} open onClose={onClose} groupId={productMini.defaultProductGroupId} onSelect={id => onProductChange({ type: OBJECT_TYPE_PRODUCT, id: id })} />)
    }

    const onFindProductBtn = () => {
        UIUtil.showOverlay2(onClose => <ProductFinder
            showFakeTextFieldAtFirst={productFinderShowFakeTextFieldAtFirst}
            loadProductsAndRest
            onProductClick={product => onProductChange({ type: product.type, id: product.id })} onClose={onClose} />)
    }

    const onFindIngredientBtn = () => {
        UIUtil.showOverlay2(onClose => <IngredientSearchDialog visible onClose={onClose} onItemSelected={product => onProductChange({ type: OBJECT_TYPE_PRODUCT, id: product.id })} />)
    }


    const onSetQtyToSrcStock = async () => {
        const validSource = Util.isNumberExist(stockItemMode?.sourceId) && (stockItemMode.sourceType === SOURCE_TYPE_STORE || stockItemMode.sourceType === SOURCE_TYPE_WAREHOUSE);
        if (!validSource) {
            UIUtil.showInfo("No valid source location (i.e. store or warehouse) selected");
            return;
        }

        const stopLoading = await UIUtil.pageLoader()
        try {
            const stockQty = await Api.asyncGetProductStock(stockItemMode.sourceId, productId)
            const qty = Math.max(stockQty, 0);

            if (selectedUOM) {
                setUnconvertedQuantity(qty)
                onStockValueChanged('_unconverted_quantityValue', qty)
            } else {
                setQuantity(qty);
                onStockValueChanged('quantityValue', qty)
            }
        } catch (e) {
            UIUtil.showError("Could not load stock");
        } finally {
            stopLoading()
        }
    }

    const onSetToCostBtn = () => {
        if (productCost !== undefined && productCost !== null) {
            setAmount(productCost);
            onStockValueChanged('amount', productCost)
        } else {
            UIUtil.showInfo("Cost not loaded")
        }
    }

    const onRoundTotalBtn = () => {
        if (!hasAmount) {
            return;
        }

        if (quantityValue <= 0) {
            UIUtil.showError("Quantity must be a positive number!");
            return;
        }

        let total = getAmountTotal();
        total = Math.round(total);

        const unitAmount = total / quantityValue;

        setAmount(unitAmount);
        onStockValueChanged('amount', unitAmount);
    }

    if (quantityFieldOnly) {
        return <>
            {/* // REPEATED BELOW */}
            <div className="dark-text-input" style={{ marginLeft: 5, width: 250 }}>
                <TextInput ref={quantityTextFieldRef} value={selectedUOM ? unconvertedQuantity : quantityValue} onChange={e => {
                    if (selectedUOM) {
                        setUnconvertedQuantity(e.target.value)
                        onStockValueChanged('_unconverted_quantityValue', e.target.value)
                    } else {
                        setQuantity(e.target.value);
                        onStockValueChanged('quantityValue', e.target.value)
                    }
                }} size="md"
                    labelText={selectedUOM ? (<>
                        Quantity <strong style={{ color: 'white' }}>({quantityValue} {getDefaultUOM().symbol})</strong>
                    </>) : "Quantity"}
                    style={{ background: 'black', color: 'white' }} placeholder="Input..." />
            </div>

            {measurementType &&
                // REPEATED BELOW
                <div className="white-combo-box-arrow dark-text-input" style={{ marginLeft: 5 }}>
                    <ComboBox
                        size="md"
                        style={{ background: 'black', color: 'white', width: 150 }}
                        titleText="UOM"
                        value={!selectedUOM ? getDefaultUOM().symbol : selectedUOM.symbol}
                        onChange={item => {
                            setSelectedUOM(item.selectedItem)
                            onStockValueChanged('_selected_uom', item.selectedItem)
                        }}
                        itemToString={item => item ? item.symbol : ""}
                        items={measurementType.unitOfMeasurements}
                    />
                </div>}
        </>
    }

    return (
        <div>
            {labelText !== undefined && <label className="bx--label">{labelText}</label>}
            <div
                className={!hasProduct ? "product-field-class" : ""}
                style={{
                    display: 'flex', width: '100%', alignItems: 'center', padding: '0.5rem', paddingLeft: '1rem', transition: 'background 250ms', background: hasProduct ? '#161616' : (templateEditorMode ? 'white' : '#f4f4f4'), color: hasProduct ? 'white' : undefined, borderRadius: '0.25rem',
                }}>
                {hasProduct && !templateEditorMode && stockItemMode && !stockItemMode.hideLabelBtn &&
                    <Barcode16 className="icon-btn" onClick={() => setSelectedBarcode(productMini.mainBarcode)} style={{ marginRight: '1rem' }} />}

                {!longList && <div style={{ minWidth: 48 }}>
                    <ProfilePic size={48} notProfile src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, productId)} />
                </div>}

                <div style={{ width: '1rem', minWidth: '1rem' }} />



                {hasProduct ? (
                    productMini !== undefined ? (
                        <div style={{ flex: 1, minWidth: '25%', }} tabIndex="-1">
                            <Link to={getObjectTypeUrl(productType) + "/" + productId} style={{ textDecoration: 'none', color: 'inherit', background: 'red' }}>
                                <p className='two-line-text' style={{ opacity: '1', fontSize: 15 }}>{productMini.name}</p>
                                <p className='two-line-text' style={{ fontSize: 13, marginTop: -4 }}>{productMini.sku}</p>
                                {!templateEditorMode && !hasCapabilitySupport("restaurant") && <>
                                    {stockItemMode ? (
                                        <p style={{ opacity: '0.65', fontSize: 12, marginTop: 0 }}>{productType == OBJECT_TYPE_PRODUCT_BUNDLE ? "Bundle" : "Product"}</p>
                                    ) : (
                                        productType == OBJECT_TYPE_PRODUCT_GROUP ? (
                                            <p style={{ opacity: '0.65', fontSize: 12, marginTop: 0 }}>Product Group</p>
                                        ) : (
                                            <p style={{ opacity: '0.65', fontSize: 12, marginTop: 0 }}>{productMini.amount ? productMini.amount.price : ''}</p>
                                        )
                                    )}
                                </>}
                            </Link>
                            {stockItemMode && hasAmount && <div style={{ width: 100 }} className="white-carbon-check-box" onClick={() => {
                                const newFoc = !foc;

                                setFoc(newFoc);
                                setAmount(newFoc ? 0 : defaultValues.amount)

                                if (onStockValueChanged !== undefined) {
                                    onStockValueChanged('amount', newFoc ? 0 : defaultValues.amount)
                                    onStockValueChanged('foc', newFoc)
                                }
                            }}>
                                <Checkbox labelText="FOC" checked={foc} />
                            </div>}
                        </div>
                    ) : (
                        <p style={{ opacity: '0.65', fontSize: 14, flex: 1, minWidth: '25%' }}>Loading product</p>
                    )
                ) : (
                    <p style={{ opacity: '0.65', fontSize: 14, flex: 1, minWidth: '25%' }}>No product selected</p>
                )}

                {((stockItemMode || templateEditorMode) ? (productMini === undefined) : true) && <div className={hasProduct ? "white-search" : ""}>
                    <ExpandableSearch
                        key={searchKey}
                        size="xl"
                        disabled={loading}
                        hideLabel
                        style={hasProduct ? { background: 'transparent', color: 'white' } : {}}
                        value={barcodeValue}
                        renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Barcode16 />
                        </div>}
                        placeholder="Scan barcode"
                        onPaste={e => onSearchBtn((e.clipboardData || window.clipboardData).getData('text'))}
                        onKeyPress={e => {
                            if (e.key == "Enter") {
                                e.preventDefault();
                                onSearchBtn();
                            }
                        }}
                        onChange={e => setBarcodeValue(e.target.value)} />
                </div>}

                {((stockItemMode || templateEditorMode) ? (productMini === undefined) : true) && !hasProduct && <>
                    {hasCapabilitySupport("restaurant") && <div className={hasProduct ? "white-search" : ""} style={{ marginLeft: 5 }}>
                        <Button onClick={onFindIngredientBtn} kind="secondary" renderIcon={Search16}>Find Ingredient</Button>
                    </div>}
                    <div className={hasProduct ? "white-search" : ""} style={{ marginLeft: 5 }}>
                        <Button onClick={onFindProductBtn} kind="secondary" renderIcon={Search16}>Find Product</Button>
                    </div>
                </>}

                {hasAmount && !foc && <div style={{ height: 48, alignSelf: 'flex-end', marginRight: '0.5rem', }}>
                    <label style={{ color: 'white', opacity: 0.65, margin: 0 }} className="bx--label">AED</label>
                    <h3 style={{ fontWeight: 'bold', color: foc ? 'green' : undefined }}>{getAmount()}</h3>
                </div>}

                {templateEditorMode && productMini && <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'flex-end' }}>
                    <div className="dark-text-input" style={{ marginLeft: 5 }}>
                        <TextInput ref={printCountTextFieldRef} value={printCount} onChange={e => {
                            setPrintCount(e.target.value);
                            onValueChanged('_templateCopyCount', e.target.value)
                        }} size="lg" labelText="Print Count" style={{ background: 'black', color: 'white' }} placeholder="Input..." />
                    </div>
                </div>}

                {stockItemMode && productMini && <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'flex-end' }}>
                    {/* {hasAmount && <Button tabIndex="-1" hasIconOnly renderIcon={DataReference16} size="lg"
                        iconDescription="Round Total"
                        style={{ height: 48, alignSelf: 'flex-end', marginLeft: 5 }} disabled={nonExistent || !hasProduct || loading} onClick={onRoundTotalBtn} />} */}
                    {<OverflowMenu
                        tabIndex="-1"
                        ariaLabel="product-options"
                        renderIcon={OverflowMenuVertical16}
                        size="lg"
                        iconDescription="Round Total"
                        style={{ height: 48, alignSelf: 'flex-end', marginLeft: 5, background: 'white' }} disabled={nonExistent || !hasProduct || loading}
                    >

                        <OverflowMenuItem itemText={"Set qty to source"} onClick={onSetQtyToSrcStock} />
                        {hasAmount && <OverflowMenuItem itemText={"Set amount to cost"} onClick={onSetToCostBtn} />}
                        {hasAmount && <OverflowMenuItem itemText={"Round Total"} onClick={onRoundTotalBtn} />}
                    </OverflowMenu>}

                    {trackInventoryBatchNo && (
                        <div className="dark-text-input" style={{ marginLeft: 5, color: 'black' }}>
                            <NonStateExtendableComboBoxTwo
                                allItems={[]}
                                addedItems={stockItemMode.invBatchNos}
                                setAddedItems={stockItemMode.setInvBatchNos}
                                filterProperties={["batchNo"]} itemToString={$ => $.batchNo} objectType={OBJECT_TYPE_INVENTORY_BATCH_NO} setNewItem={({ batchNo }) => {
                                    setBatchNoValue(batchNo);
                                    onStockValueChanged('batchNo', batchNo);
                                }}>
                                {list => <DataListField height={48} skipMargin options={list} title={"Batch No"} placeholder={"Input..."}
                                    value={stockItemMode.invBatchNos?.find($ => $.value === batchNoValue)?.id}
                                    setValue={batchNoId => {
                                        const batchNo = stockItemMode.invBatchNos?.find($ => $.id === batchNoId)?.value
                                        setBatchNoValue(batchNo);
                                        onStockValueChanged('batchNo', batchNo);
                                    }} />}
                            </NonStateExtendableComboBoxTwo>
                            {/* <TextInput value={batchNoValue} onChange={e => {
                                setBatchNoValue(e.target.value);
                                onStockValueChanged('batchNo', e.target.value)
                            }} size="lg" labelText={"Batch No"} style={{ background: 'black', color: 'white' }} placeholder="Input..." /> */}
                        </div>
                    )}

                    {requiresSerialNo ? (
                        <div className="dark-text-input" style={{ marginLeft: 5 }}>
                            <TextInput ref={quantityTextFieldRef} value={serialNoValue} onChange={e => {
                                setSerialNoValue(e.target.value);
                                onStockValueChanged('serialNumber', e.target.value)
                            }} size="lg" labelText={"Serial Number"} style={{ background: 'black', color: 'white' }} placeholder="Input..." />
                        </div>
                    ) : (
                        // REPEATED ABOVE
                        <div className="dark-text-input" style={{ marginLeft: 5, width: 100 }}>
                            <TextInput ref={quantityTextFieldRef} value={selectedUOM ? unconvertedQuantity : quantityValue} onChange={e => {
                                if (selectedUOM) {
                                    setUnconvertedQuantity(e.target.value)
                                    onStockValueChanged('_unconverted_quantityValue', e.target.value)
                                } else {
                                    setQuantity(e.target.value);
                                    onStockValueChanged('quantityValue', e.target.value)
                                }
                            }} size="lg"
                                labelText={selectedUOM ? (<>
                                    Quantity <strong style={{ color: 'white' }}>({quantityValue} {getDefaultUOM().symbol})</strong>
                                </>) : "Quantity"}
                                style={{ background: 'black', color: 'white' }} placeholder="Input..." />
                        </div>
                    )}

                    {measurementType &&
                        // REPEATED ABOVE
                        <div className="white-combo-box-arrow dark-text-input" style={{ marginLeft: 5 }}>
                            <ComboBox
                                size="lg"
                                style={{ background: 'black', color: 'white', width: 100 }}
                                titleText="UOM"
                                value={!selectedUOM ? getDefaultUOM().symbol : selectedUOM.symbol}
                                onChange={item => {
                                    setSelectedUOM(item.selectedItem)
                                    onStockValueChanged('_selected_uom', item.selectedItem)
                                }}
                                itemToString={item => item ? item.symbol : ""}
                                items={measurementType.unitOfMeasurements}
                            />
                        </div>}

                    {(stockItemMode.sourceType == SOURCE_TYPE_SUPPLIER || stockItemMode.sourceType == SOURCE_TYPE_STOCK_CREATION) &&
                        <div className="dark-text-input" style={{ marginLeft: 5, width: 120 }}>
                            <TextInput value={foc ? '0.00' : amount} disabled={foc} onChange={e => {
                                // repeated above!
                                setAmount(e.target.value);
                                onStockValueChanged('amount', e.target.value)
                            }} size="lg" labelText={"Unit Cost (" + productMini.amount.currency + ")"} style={{ background: 'black', color: 'white' }} placeholder="Input..." />
                        </div>}

                    {(stockItemMode.destinationType == DESTINATION_TYPE_SUPPLIER || stockItemMode.sourceType == SOURCE_TYPE_CUSTOMER) &&
                        <div className="dark-text-input" style={{ marginLeft: 5, width: 120 }}>
                            <TextInput value={foc ? '0.00' : amount} disabled={foc} onChange={e => {
                                setAmount(e.target.value);
                                onStockValueChanged('amount', e.target.value)
                            }} size="lg" labelText={"Unit Refund (" + productMini.amount.currency + ")"} style={{ background: 'black', color: 'white' }} placeholder="Input..." />
                        </div>}

                    {stockItemMode.destinationType == DESTINATION_TYPE_CUSTOMER &&
                        <div className="dark-text-input" style={{ marginLeft: 5, width: 120 }}>
                            <TextInput value={foc ? '0.00' : amount} disabled={foc} onChange={e => {
                                setAmount(e.target.value);
                                onStockValueChanged('amount', e.target.value)
                            }} size="lg" labelText={"Unit Price (" + productMini.amount.currency + ")"} style={{ background: 'black', color: 'white' }} placeholder="Input..." />
                        </div>}
                </div>}

                {/* {productType == OBJECT_TYPE_PRODUCT && (stockItemMode) && productMini && Util.isNumberExist(parentProductGroupId) && <><div style={{ width: 5 }} />
                    <Button tabIndex="-1" kind="secondary" hasIconOnly renderIcon={Categories16} size="lg"
                        iconDescription="Replace by Attribute"
                        style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent || !hasProduct || loading} onClick={() => setShowReplaceAttributeDialog(true)} /></>} */}

                {/* AppConnectivity16 */}
                {productMini && hasProduct && !nonExistent && Util.isNumberExist(productMini.defaultProductGroupId) && <><div style={{ width: 5 }} />
                    <Button tabIndex="-1" kind="secondary" hasIconOnly renderIcon={AppConnectivity16} size="lg"
                        iconDescription="Select Attribute"
                        style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent || !hasProduct || loading} onClick={onSelectAttributeBtn} /></>}

                {productType == OBJECT_TYPE_PRODUCT && (stockItemMode || productFinderShowReplaceWithBundle) && productMini && <><div style={{ width: 5 }} />
                    <Button tabIndex="-1" kind="secondary" hasIconOnly renderIcon={Box16} size="lg"
                        iconDescription="Replace with Bundle"
                        style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent || !hasProduct || loading} onClick={() => setShowReplaceDialog(true)} /></>}

                {productType == OBJECT_TYPE_PRODUCT && (productFinderShowReplaceWithProductGroup) && productMini && <><div style={{ width: 5 }} />
                    <Button tabIndex="-1" kind="secondary" hasIconOnly renderIcon={Categories16} size="lg"
                        iconDescription="Replace with Group"
                        style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent || !hasProduct || loading} onClick={() => setShowReplaceGroupDialog(true)} /></>}

                <div style={{ width: 5 }} />
                <Button tabIndex="-1" kind="danger" hasIconOnly renderIcon={RowDelete32} size="lg"
                    iconDescription="Remove"
                    style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent || !hasProduct || loading} onClick={onClearBtn} />
            </div>


            {selectedBarcode &&
                <LabelPrintViewDialog
                    itemId={productId}
                    productBundle={productType == OBJECT_TYPE_PRODUCT_BUNDLE}
                    onClose={() => setSelectedBarcode(false)} />}

            {showReplaceDialog &&
                <BundleReplaceDialog
                    open onClose={() => setShowReplaceDialog(false)}
                    productId={productId}
                    onReplace={item => {
                        onProductChange({ type: OBJECT_TYPE_PRODUCT_BUNDLE, id: item.id });
                    }}
                />}

            {showReplaceGroupDialog &&
                <ProductGroupReplaceDialog
                    open onClose={() => setShowReplaceGroupDialog(false)}
                    productId={productId}
                    onReplace={item => {
                        onProductChange({ type: OBJECT_TYPE_PRODUCT_GROUP, id: item.id });
                    }}
                />}

            {/* {showReplaceAttributeDialog &&
                <ProductDivisionDialog
                    productGroupId={parentProductGroupId}
                    open onClose={() => setShowReplaceAttributeDialog(false)} />} */}
        </div>
    )
}