import { APP_NAME, INVOICE_ALT_PDF } from "./Config";

export function isRivas() {
    return APP_NAME === "Rivas";
}

export function isAbshar() {
    return APP_NAME === "Abshar Shop" || APP_NAME === "Grand Abshar";
}

export function isAbsharShop() {
    return APP_NAME === "Abshar Shop";
}

export function isAbsharRestaurant() {
    return APP_NAME === 'Grand Abshar';
}

export function isAnyAbshar() {
    return isAbshar() || isAbsharShop()
}

export function isRomario() {
    return APP_NAME === "Al Rahela" || APP_NAME.toLowerCase().includes("romario")
}

export function isFoodBazaar() {
    return APP_NAME === "Food Bazaar";
}

export function isAdidas() {
    return INVOICE_ALT_PDF === "adidas-romario";
}

export function isDeluxxe() {
    return APP_NAME === "Deluxxe";
}

export function isRahela() {
    return INVOICE_ALT_PDF === "rahela-romario";
}

export function isRunners() {
    return INVOICE_ALT_PDF === "runners-romario";
}

export function isQasr() {
    return APP_NAME === "Qasr Al Awany";
}

export function isPicard() {
    return APP_NAME === 'Picard Logistics';
}