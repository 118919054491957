import { ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, NumberInput, ClickableTile, TextInput, ComboBox, Select, SelectItem } from 'carbon-components-react';
import React, { useEffect, useState, useRef } from 'react'
import Button from '../../components/Button';

import {
    Purchase16,
    Money32,
    Finance32,
    Wallet32,
    Wallet16,
    Currency32,
    Percentage16,
    ErrorFilled16,
    Warning16,
    Purchase32,
    Account32,
    Money16,
    PenFountain16,
    SubtractAlt16,
    SubtractAlt32,
    CutOut16,
    CutOut32,
    Wifi32,
    Settings32,
    RecentlyViewed32,
    RecentlyViewed16
} from '@carbon/icons-react'
import { PAYMENT_METHOD_CASH, PAYMENT_METHOD_CHEQUE, PAYMENT_METHOD_CREDIT, PAYMENT_METHOD_DEBIT, PAYMENT_METHOD_OTHER, PAYMENT_METHOD_BANK, PAYMENT_METHOD_PROXY_ADVANCE, PAYMENT_METHOD_ONLINE_GATEWAY, PAYMENT_METHOD_PROXY_DISCOUNT, PAYMENT_METHOD_COUPON, PAYMENT_METHOD_TALABAT, PAYMENT_METHOD_FRESH_BAZAAR, PAYMENT_METHOD_ART_BAZAAR, PAYMENT_METHOD_COMPANY_EXPENSE, PAYMENT_METHOD_ZOMATO, PAYMENT_METHOD_DELIVEROO, PAYMENT_METHOD_CAREEM, PAYMENT_METHOD_NOON, PAYMENT_METHOD_PROXY_OUTSTANDING, PAYMENT_METHOD_INSTASHOP, PAYMENT_METHOD_COURIER } from '../../constants/Constants';
import Util, { big } from '../../util/Util';

import ReactTooltip from 'react-tooltip';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

import { Link } from 'react-router-dom'
import { CARD_PROCESSING_FEE_PERC, TIP_ENABLED } from '../../app/Config';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { isAbshar, isAbsharRestaurant, isAbsharShop, isAnyAbshar, isFoodBazaar, isQasr } from '../../app/app-util';


export const paymentMethodText = (methodType) => {
    switch (methodType) {
        case PAYMENT_METHOD_CASH:
            return "Cash";
        case PAYMENT_METHOD_CREDIT:
            return isQasr() ? "Card" : "Credit Card";
        case PAYMENT_METHOD_DEBIT:
            return "Debit Card";
        case PAYMENT_METHOD_CHEQUE:
            return "Cheque";
        case PAYMENT_METHOD_BANK:
            return "Bank";
        case PAYMENT_METHOD_ONLINE_GATEWAY:
            return "Online";
        case PAYMENT_METHOD_OTHER:
            return "Other";

        case PAYMENT_METHOD_PROXY_DISCOUNT:
            return "Discount";
        case PAYMENT_METHOD_COUPON:
            return "System Coupon";

        case PAYMENT_METHOD_TALABAT:
            return "Talabat";
        case PAYMENT_METHOD_ZOMATO:
            return "Zomato";
        case PAYMENT_METHOD_DELIVEROO:
            return "Deliveroo";
        case PAYMENT_METHOD_INSTASHOP:
            return "InstaShop";
        case PAYMENT_METHOD_FRESH_BAZAAR:
            return "Fresh Bazaar";
        case PAYMENT_METHOD_COMPANY_EXPENSE:
            return "Company Expense";
        case PAYMENT_METHOD_ART_BAZAAR:
            return "Art Bazaar";
        case PAYMENT_METHOD_CAREEM:
            return "Careem";
        case PAYMENT_METHOD_NOON:
            return "Noon";
        case PAYMENT_METHOD_COURIER:
            return "Courier";

        case PAYMENT_METHOD_PROXY_ADVANCE:
            return "Advance";
        case PAYMENT_METHOD_PROXY_OUTSTANDING:
            return "Outstanding";
    }
}


export const paymentMethodTextWithArabic = (methodType) => {
    switch (methodType) {
        case PAYMENT_METHOD_CASH:
            return "Cash نقدا";
        case PAYMENT_METHOD_CREDIT:
            return isQasr() ? "Card بطاقة" : "Credit Card بطاقة";
        case PAYMENT_METHOD_DEBIT:
            return "Debit Card بطاقة";
        case PAYMENT_METHOD_CHEQUE:
            return "Cheque";
        case PAYMENT_METHOD_BANK:
            return "Bank";
        case PAYMENT_METHOD_ONLINE_GATEWAY:
            return "Online";
        case PAYMENT_METHOD_OTHER:
            return "Other";

        case PAYMENT_METHOD_PROXY_DISCOUNT:
            return "Discount";
        case PAYMENT_METHOD_COUPON:
            return "System Coupon";

        case PAYMENT_METHOD_TALABAT:
            return "Talabat";
        case PAYMENT_METHOD_ZOMATO:
            return "Zomato";
        case PAYMENT_METHOD_DELIVEROO:
            return "Deliveroo";
        case PAYMENT_METHOD_INSTASHOP:
            return "InstaShop";
        case PAYMENT_METHOD_FRESH_BAZAAR:
            return "Fresh Bazaar";
        case PAYMENT_METHOD_COMPANY_EXPENSE:
            return "Company Expense";
        case PAYMENT_METHOD_ART_BAZAAR:
            return "Art Bazaar";
        case PAYMENT_METHOD_CAREEM:
            return "Careem";
        case PAYMENT_METHOD_NOON:
            return "Noon";
        case PAYMENT_METHOD_COURIER:
            return "Courier";

        case PAYMENT_METHOD_PROXY_ADVANCE:
            return "Advance";
        case PAYMENT_METHOD_PROXY_OUTSTANDING:
            return "Outstanding";
    }
}

const PaymentMethodIcon = ({ methodType, ...props }) => {
    switch (methodType) {
        case PAYMENT_METHOD_CASH:
            return <Money32 {...props} />;
        case PAYMENT_METHOD_CREDIT:
            return <Purchase32 {...props} />;
        case PAYMENT_METHOD_DEBIT:
            return <Wallet32 {...props} />;
        case PAYMENT_METHOD_CHEQUE:
            return <Account32 {...props} />;
        case PAYMENT_METHOD_BANK:
            return <Finance32 {...props} />;
        case PAYMENT_METHOD_OTHER:
            return <Currency32 {...props} />;
        case PAYMENT_METHOD_ONLINE_GATEWAY:
            return <Wifi32 {...props} />

        case PAYMENT_METHOD_PROXY_DISCOUNT:
            return <SubtractAlt32 {...props} />;
        case PAYMENT_METHOD_COUPON:
            return <CutOut32 {...props} />;
        case PAYMENT_METHOD_PROXY_ADVANCE:
            return <RecentlyViewed32 {...props} />;

        default:
            return <Settings32 {...props} />
    }
}

const PaymentMethodItem = ({ incoming, supportedCurrencies, cheques, posMode, paymentMethod, onRemove, onPropertyUpdated, readOnly, noFocus }) => {
    // const [paymentId, setPaymentId] = useState(paymentMethod.paymentId);
    // const [amount, setAmount] = useState(paymentMethod.amount);
    const amountTextInputRef = useRef(null);

    const [fade, setFade] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(readOnly ? (Util.isNumberExist(paymentMethod.nonDefaultCurrencyAmount) ? {
        code: paymentMethod.nonDefaultCurrencyCode,
        rate: paymentMethod.nonDefaultCurrencyRate,
    } : undefined) : undefined);

    const [nonDefaultCurrencyAmount, setNonDefaultCurrencyAmount] = useState(readOnly ? (Util.isNumberExist(paymentMethod.nonDefaultCurrencyAmount) ? paymentMethod.nonDefaultCurrencyAmount : 0) : 0);


    useEffect(() => {
        if (!readOnly) {
            onPropertyUpdated('chequeId', 0)
        }
    }, [incoming])
    useEffect(() => {
        if (!readOnly) {
            if ((paymentMethod.methodType == PAYMENT_METHOD_DEBIT || paymentMethod.methodType == PAYMENT_METHOD_CREDIT) && Util.isNumberExist(CARD_PROCESSING_FEE_PERC) && Util.isNumberExist(paymentMethod.amount)) {
                onPropertyUpdated('processingFeeAmount', big(paymentMethod.amount).times(big(CARD_PROCESSING_FEE_PERC)).toFixed(2))
            } else {
                onPropertyUpdated('processingFeeAmount', 0)
            }
        }
    }, [readOnly, paymentMethod.amount, paymentMethod.methodType])

    //const [selectedCheque, setSelectedCHeque]

    //useEffect(() => setFade(true), []);
    useEffect(() => {
        setTimeout(() => {
            setFade(true);

            if (!noFocus && amountTextInputRef.current) {
                amountTextInputRef.current.focus();
                amountTextInputRef.current.select();
            }
        })
    }, []);

    let background;
    if (paymentMethod.methodType == PAYMENT_METHOD_COUPON) {
        //background = '#0f62fe'
        background = '#17255A'
    } else if (paymentMethod.methodType == PAYMENT_METHOD_PROXY_DISCOUNT) {
        background = '#990000'
    } else {
        background = '#161616'
    }

    const renderProcessingFee = () => {
        const processingFeeAmount = paymentMethod.processingFeeAmount;
        if (Util.isNumberExist(processingFeeAmount) && readOnly) {
            const actualAmount = paymentMethod.amount - processingFeeAmount;
            return (
                //<p style={{ marginLeft: '0.25rem' }}>{actualAmount.toFixed(2)} + {processingFeeAmount.toFixed(2)} <span style={{ fontSize: 12, opacity: 0.65 }}>(processing fee)</span> = <strong>AED {paymentMethod.amount.toFixed(2)}</strong></p>
                <p style={{ fontSize: 14, opacity: 0.65 }}>{actualAmount.toFixed(2)} + {processingFeeAmount.toFixed(2)} <span style={{ fontSize: 12, opacity: 0.65 }}>(processing fee)</span></p>
            )
        } else {
            return null;
        }
    }

    const [changingMethodType, setChangingMethodType] = useState(false);
    const changeMethodType = async () => {
        setChangingMethodType(true);
        try {
            const allTypes = isAbsharRestaurant() ? [
                { id: PAYMENT_METHOD_CASH, value: "Cash" },
                { id: PAYMENT_METHOD_CREDIT, value: "Credit Card" },
                { id: PAYMENT_METHOD_DEBIT, value: "Debit Card" },
                { id: PAYMENT_METHOD_TALABAT, value: "Talabat" },
                { id: PAYMENT_METHOD_ZOMATO, value: "Zomato" },
                { id: PAYMENT_METHOD_DELIVEROO, value: "Deliveroo" },
                { id: PAYMENT_METHOD_CAREEM, value: "Careem" },
                { id: PAYMENT_METHOD_NOON, value: "Noon" },
            ] : isAbsharShop() ? [
                { id: PAYMENT_METHOD_CASH, value: "Cash" },
                { id: PAYMENT_METHOD_CREDIT, value: "Credit Card" },
                { id: PAYMENT_METHOD_DEBIT, value: "Debit Card" },
                { id: PAYMENT_METHOD_TALABAT, value: "Talabat" },
                { id: PAYMENT_METHOD_CAREEM, value: "Careem" },
                { id: PAYMENT_METHOD_NOON, value: "Noon" },
                { id: PAYMENT_METHOD_DELIVEROO, value: "Deliveroo" },
                { id: PAYMENT_METHOD_INSTASHOP, value: "InstaShop" }
            ] : isFoodBazaar() ? [
                { id: PAYMENT_METHOD_CASH, value: "Cash" },
                { id: PAYMENT_METHOD_CREDIT, value: "Credit Card" },
                { id: PAYMENT_METHOD_DEBIT, value: "Debit Card" },
                { id: PAYMENT_METHOD_FRESH_BAZAAR, value: "Fresh Bazaar" },
                { id: PAYMENT_METHOD_ART_BAZAAR, value: "Art Bazaar" },
                { id: PAYMENT_METHOD_COMPANY_EXPENSE, value: "Company Expense" },
            ] : [
                { id: PAYMENT_METHOD_CASH, value: "Cash" },
                { id: PAYMENT_METHOD_CREDIT, value: "Credit Card" },
                { id: PAYMENT_METHOD_DEBIT, value: "Debit Card" },
            ]
            const newType = await UIUtil.listPrompt("New Type", allTypes)
            if (!newType) {
                return;
            }

            await Api.asyncSetPaymentMethodType(paymentMethod.id, newType.id);
            paymentMethod.methodType = newType.id;
            UIUtil.showSuccess();
        } catch (e) {
            UIUtil.showError();
        } finally {
            setChangingMethodType(false)
        }
    }

    return (
        <div style={{
            background: background, padding: '1rem', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '0.25rem', color: 'white', marginBottom: '0.5rem',
            transition: '250ms', opacity: fade ? 1 : 0, transform: fade ? 'translateY(0px)' : 'translateY(-60px)'
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PaymentMethodIcon methodType={paymentMethod.methodType} />
                <div style={{ marginLeft: '1rem', flex: ((paymentMethod.methodType == PAYMENT_METHOD_CASH && !readOnly) || ((paymentMethod.methodType == PAYMENT_METHOD_CHEQUE || paymentMethod.methodType == PAYMENT_METHOD_COUPON) && readOnly)) ? undefined : 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <h4>{paymentMethodText(paymentMethod.methodType)}</h4>
                        {Util.isStringExists(paymentMethod.onlinePaymentGateway) && <p style={{ fontSize: 14, opacity: 0.65 }}>{paymentMethod.onlinePaymentGateway} - {paymentMethod.onlinePaymentMethod}</p>}
                    </div>


                    {renderProcessingFee()}
                </div>

                {paymentMethod.methodType == PAYMENT_METHOD_CASH && !readOnly && <div style={{ width: '1rem' }} />}
                {paymentMethod.methodType == PAYMENT_METHOD_CASH && !readOnly && <div className="white-combo-box-arrow">
                    <Select
                        size="xl"
                        hideLabel
                        light
                        style={{ background: 'black', color: 'white', maxWidth: 150 }}
                        value={selectedCurrency ? selectedCurrency.id : -1}
                        onChange={e => {
                            let selected = null;
                            for (const currency of supportedCurrencies) {
                                if (currency.id == e.target.value) {
                                    selected = currency;
                                    break;
                                }
                            }

                            if (selected) {
                                setSelectedCurrency(selected);
                            } else {
                                setSelectedCurrency(undefined)
                            }
                            setNonDefaultCurrencyAmount(0)
                            onPropertyUpdated('amount', 0)
                            onPropertyUpdated('nonDefaultCurrencyCode', selected ? selected.code : null)
                            onPropertyUpdated('nonDefaultCurrencyAmount', selected ? 0 : null)
                            onPropertyUpdated('nonDefaultCurrencyRate', selected ? selected.rate : null);
                        }}
                    >
                        <SelectItem value={-1} text={"AED"} style={{ color: 'white', height: 45, fontSize: 18 }} />
                        {supportedCurrencies.map(store => <SelectItem style={{ color: 'white', height: 45, fontSize: 18 }} value={store.id} text={store.code} />)}
                    </Select>

                    {/* <ComboBox
                    size="xl"
                    hideLabel
                    light
                    style={{background: 'black', color: 'white', maxWidth: 150}}
                    //style={hasProduct ? {background: 'transparent', color: 'white',} : {}}
                    items={supportedCurrencies}
                    itemToString={item => item !== null ? item.code : ""}
                    itemToElement={item => (
                        <div style={{display: 'flex', alignItems: 'center', height: '100%', pointerEvents: 'none'}}>
                            <span style={{flex: '1'}}>
                                {item.code}
                            </span>
                        </div>
                    )}
                    placeholder="AED"
                    onChange={item => {
                        if (item.selectedItem) {
                            setSelectedCurrency(item.selectedItem);
                        } else {
                            setSelectedCurrency(undefined)
                        }
                        setNonDefaultCurrencyAmount(0)
                        onPropertyUpdated('amount', 0)
                    }} 
                    
                    /> */}
                </div>}

                {readOnly && paymentMethod.methodType == PAYMENT_METHOD_CHEQUE && Util.isNumberExist(paymentMethod.chequeId) &&
                    <div style={{ flex: 1, paddingLeft: '1rem' }}>
                        <Link target="_blank" to={"/cheques/" + paymentMethod.chequeId}>
                            {/* <p style={{fontSize: 12, opacity: 0.65}}>{paymentMethod.cheque.bankName + ' - ' + paymentMethod.cheque.chequeNo}</p> */}
                            <Button renderIcon={PenFountain16}>
                                {paymentMethod.cheque ? (paymentMethod.cheque.bankName + ' - ' + paymentMethod.cheque.chequeNo) : 'View Cheque'}
                            </Button>
                        </Link>
                    </div>}

                {readOnly && paymentMethod.methodType == PAYMENT_METHOD_COUPON && Util.isNumberExist(paymentMethod.couponId) &&
                    <div style={{ flex: 1, paddingLeft: '1rem' }}>
                        <Link target="_blank" to={"/coupons/" + paymentMethod.couponId}>
                            {/* <p style={{fontSize: 12, opacity: 0.65}}>{paymentMethod.cheque.bankName + ' - ' + paymentMethod.cheque.chequeNo}</p> */}
                            <Button kind="secondary" renderIcon={CutOut16}>
                                View Coupon - {paymentMethod.couponCode}
                            </Button>
                        </Link>
                    </div>}

                {paymentMethod.methodType == PAYMENT_METHOD_CASH && !readOnly && <div style={{ flex: 1 }} />}

                {!readOnly && paymentMethod.methodType == PAYMENT_METHOD_CHEQUE &&
                    <Select
                        size="xl"
                        hideLabel
                        light
                        style={{ background: 'black', color: 'white', }}
                        value={Util.isNumberExist(paymentMethod.chequeId) ? paymentMethod.chequeId : 0}
                        onChange={e => {
                            onPropertyUpdated('chequeId', e.target.value)

                            let amount = 0;
                            for (const cheque of cheques) {
                                if (cheque.id == e.target.value) {
                                    amount = cheque.amount;
                                    break;
                                }
                            }
                            onPropertyUpdated('amount', amount);
                        }}
                    >
                        <SelectItem value={0} text={"Select cheque"} style={{ color: 'white', height: 45, fontSize: 18 }} />
                        {cheques.map(cheque => <SelectItem style={{ color: 'white', height: 45, fontSize: 18 }} value={cheque.id} text={cheque.bankName + ' - ' + cheque.chequeNo} />)}
                    </Select>
                }

                {(true ? paymentMethod.methodType != PAYMENT_METHOD_CASH && paymentMethod.methodType != PAYMENT_METHOD_CHEQUE && paymentMethod.methodType != PAYMENT_METHOD_PROXY_DISCOUNT && paymentMethod.methodType != PAYMENT_METHOD_COUPON : true) && (readOnly ? (Util.isStringExists(paymentMethod.paymentId)) : true) && <div style={{ display: 'flex', alignItems: 'center', }}>
                    <p>ID</p>
                    <div className="dark-text-input" style={{ marginLeft: '1rem' }}>
                        <TextInput //value={paymentId} onChange={e => setPaymentId(e.target.value)} 
                            disabled={readOnly}
                            value={paymentMethod.paymentId} onChange={e => onPropertyUpdated('paymentId', e.target.value)}
                            size="lg" style={{ background: 'black', color: 'white' }}
                            placeholder={isQasr() ? "Payment ID" : "Payment ID (optional)"} />
                    </div>
                </div>}

                {paymentMethod.methodType == PAYMENT_METHOD_PROXY_DISCOUNT && <div style={{ display: 'flex', alignItems: 'center', }}>
                    <p>Clearance</p>
                    <div className="dark-text-input" style={{ marginLeft: '1rem' }}>
                        <TextInput //value={paymentId} onChange={e => setPaymentId(e.target.value)} 
                            disabled={readOnly}
                            type="password"
                            value={paymentMethod.paymentId} onChange={e => onPropertyUpdated('paymentId', e.target.value)}
                            size="lg" style={{ background: 'black', color: 'white' }} placeholder="Clearance code" />
                    </div>
                </div>}

                {paymentMethod.methodType == PAYMENT_METHOD_COUPON && (<>
                    {readOnly ? (
                        <p style={{ fontSize: 12, opacity: 0.65, }}>Coupon was generated on transaction</p>
                    ) : (
                        <p style={{ fontSize: 12, opacity: 0.65, }}>A coupon will be generated</p>
                    )}
                </>)}

                {selectedCurrency && <div style={{ display: 'flex', alignItems: 'center', marginLeft: '3rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                        <p>{selectedCurrency.code}</p>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Rate {selectedCurrency.rate}</p>
                    </div>
                    <div className="dark-text-input" style={{ marginLeft: '1rem' }}>
                        <TextInput //value={amount} onChange={e => setAmount(e.target.value)} 
                            disabled={readOnly}
                            value={nonDefaultCurrencyAmount} onChange={e => {
                                if (!isNaN(e.target.value)) {
                                    let value = e.target.value;
                                    if (value.startsWith("0")) {
                                        value = value.substring(1);
                                    }
                                    value = value == '' ? 0 : value;


                                    setNonDefaultCurrencyAmount(value)
                                    onPropertyUpdated('amount', (value * selectedCurrency.rate).toFixed(2))
                                    onPropertyUpdated('nonDefaultCurrencyAmount', value)
                                }
                            }}
                            size="lg" style={{ background: 'black', color: 'white' }} placeholder="Amount" />
                    </div>
                </div>}


                <div style={{ display: 'flex', alignItems: 'center', marginLeft: selectedCurrency ? 0 : '3rem' }}>
                    {!selectedCurrency && <p>AED</p>}
                    <div className="dark-text-input" style={{ marginLeft: '1rem' }}>
                        <TextInput //value={amount} onChange={e => setAmount(e.target.value)} 
                            ref={amountTextInputRef}
                            disabled={readOnly || selectedCurrency || Util.isNumberExist(paymentMethod.chequeId)}
                            value={
                                selectedCurrency ? (
                                    'AED ' + (paymentMethod.amount.toFixed !== undefined ? paymentMethod.amount.toFixed(2) : paymentMethod.amount)
                                ) : (
                                    paymentMethod.amount.toFixed !== undefined ? paymentMethod.amount.toFixed(2) : paymentMethod.amount
                                )
                            } onChange={e => {
                                if (!isNaN(e.target.value)) {
                                    let value = e.target.value;
                                    if (value.startsWith("0")) {
                                        value = value.substring(1);
                                    }
                                    onPropertyUpdated('amount', value == '' ? 0 : value)
                                }
                            }}
                            size="lg" style={{ background: 'black', color: 'white' }} placeholder="Amount" />
                    </div>
                </div>


                {/* agala */}
                {/* <div className="dark-text-input" style={{ marginLeft: '1rem' }}>
                    <TextInput //value={paymentId} onChange={e => setPaymentId(e.target.value)} 
                        disabled={readOnly}
                        value={paymentMethod.processingFeeAmount} onChange={e => onPropertyUpdated('processingFeeAmount', e.target.value)}
                        size="lg" style={{ background: 'black', color: 'white' }} placeholder="processingFeeAmount" />
                </div> */}

                {!readOnly && <Button tabIndex="-1" onClick={onRemove} kind="danger" hasIconOnly renderIcon={ErrorFilled16} iconDescription="Remove" style={{ marginLeft: '1rem' }} />}

                {(hasCapabilitySupport('restaurant') || isAnyAbshar()) && readOnly && paymentMethod.methodType !== PAYMENT_METHOD_CHEQUE &&
                    <Button onClick={changeMethodType} loading={changingMethodType} renderIcon={Wallet16}>Change Type</Button>}
            </div>
        </div>
    )
}

class TransactionEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            paymentMethods: props.defaultPaymentMethods !== undefined ? props.defaultPaymentMethods : [],
            showAddCashPaymentDialog: false,
            loading: true,

            supportedCurrencies: [],
            payableCheques: [],
            receivableCheques: [],

            advanceBalance: undefined
        }
    }

    async componentDidMount() {
        ReactTooltip.rebuild()

        this.setState({ loading: true })
        try {
            const methodInfo = await Api.asyncGetPaymentMethodInfo();
            const advanceBalance = Util.isNumberExist(this.props.customerId)
                ? await Api.asyncGetCustomerAdvanceBalance(this.props.customerId)
                : undefined;

            this.setState({
                loading: false,
                supportedCurrencies: methodInfo.supportedCurrencies,
                payableCheques: methodInfo.payableCheques,
                receivableCheques: methodInfo.receivableCheques,
                advanceBalance: advanceBalance
            })
        } catch (e) {
            UIUtil.showError("Failed to initialize payment methods");
        }

        // Api.getPaymentMethodInfo(response => {
        //     if (response.status === true) {
        //         this.setState({
        //             loading: false, //supportedCurrencies: response.payload,
        //             supportedCurrencies: response.payload.supportedCurrencies,
        //             payableCheques: response.payload.payableCheques,
        //             receivableCheques: response.payload.receivableCheques,
        //         })
        //     } else {

        //     }
        // })
    }

    canUseAdvance() {
        return (hasCapabilitySupport("restaurant") || hasCapabilitySupport("extendedPosCheckout")) && big(this.state.advanceBalance).gt(big(0));
    }

    getRemainingAmount() {
        return this.props.totalAmount - this.getTotalAmountPaid();
    }

    getTotalAmountPaid() {
        let totalPaid = 0;
        for (const paymentMethod of this.state.paymentMethods) {
            if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                totalPaid += parseFloat(paymentMethod.amount);
            }
        }
        return totalPaid;
    }

    getTotalProcessingFeeAmount() {
        let total = 0;
        for (const paymentMethod of this.state.paymentMethods) {
            if (!isNaN(paymentMethod.processingFeeAmount) && paymentMethod.processingFeeAmount != '') {
                total += parseFloat(paymentMethod.processingFeeAmount);
            }
        }
        return total;
    }

    addPaymentMethod(methodType) {
        let amount = 0;
        if (methodType != PAYMENT_METHOD_PROXY_DISCOUNT && this.getRemainingAmount() > 0) {
            amount = this.getRemainingAmount();
        }
        this.setState(prevState => ({ paymentMethods: [...prevState.paymentMethods, { tempId: Util.newTempId(), methodType, amount }] }), () => this.props.onUpdate(this.state.paymentMethods))
    }

    removePaymentMethod(paymentMethod) {
        this.setState(prevState => ({ paymentMethods: prevState.paymentMethods.filter(method => method !== paymentMethod) }), () => this.props.onUpdate(this.state.paymentMethods))
    }

    renderNoPaymentMethod() {
        return (
            <div style={{ width: '100%', height: 75, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>No payment methods added</p>
            </div>
        )
    }

    // renderAddCashPaymentDialog() {
    //     return (
    //         <ComposedModal key="set-item-qty-dialog" size="xs" open={this.state.showAddCashPaymentDialog} onClose={() => this.setState({showAddCashPaymentDialog: false})}>
    //             <ModalHeader label="Cart Item" title="Set Quantity" />
    //             <ModalBody>
    //                 <NumberInput
    //                 invalidText="Invalid number"
    //                 data-modal-primary-focus
    //                 //value={posState.getQuantitySetValue()}
    //                 //onChange={(e, { value }) => posState.setQuantitySetValue(value)}
    //                 hideSteppers
    //                 label="Quantity"
    //                 />
    //             </ModalBody>
    //             <ModalFooter
    //             //primaryButtonDisabled={(posState.getShowQuantitySetItem() !== undefined && posState.getQuantitySetValue() == posState.getShowQuantitySetItem().quantityValue) || (isNaN(posState.getQuantitySetValue()) || posState.getQuantitySetValue() === "")}
    //             onRequestSubmit={() => {
    //                 this.setState({showAddCashPaymentDialog: false})
    //             }}
    //             primaryButtonText="Set" secondaryButtonText="Cancel" />
    // USE NEW BUTTONS BECAUSE OF CANCEL BUTTON BUG
    //         </ComposedModal>
    //     )
    // }

    isNonCashAmountExceeded() {
        if (TIP_ENABLED) {
            let totalPaid = 0;
            for (const paymentMethod of this.state.paymentMethods) {
                if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                    totalPaid += parseFloat(paymentMethod.amount);
                }
            }
            return totalPaid > this.props.totalAmount;
        }

        let totalPaid = 0;
        for (const paymentMethod of this.state.paymentMethods) {
            if (paymentMethod.methodType != PAYMENT_METHOD_CASH && !isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                totalPaid += parseFloat(paymentMethod.amount);
            }
        }
        // console.log("noncash " + totalPaid + " > " + this.props.totalAmount, totalPaid > this.props.totalAmount)
        return totalPaid > this.props.totalAmount;
    }

    isCashAmountExceeded() {
        if (TIP_ENABLED) {
            return false;
        }

        let totalPaid = 0;
        let totalNonCashPaid = 0;
        for (const paymentMethod of this.state.paymentMethods) {
            if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                if (paymentMethod.methodType == PAYMENT_METHOD_CASH) {
                    totalPaid += parseFloat(paymentMethod.amount);
                } else {
                    totalNonCashPaid += parseFloat(paymentMethod.amount);
                }
            }
        }

        return totalPaid > (this.props.totalAmount - totalNonCashPaid).toFixed(2);
        // return totalPaid.toFixed(2) > (this.props.totalAmount - totalNonCashPaid).toFixed(2);
    }

    getCashReturnAmount() {
        if (TIP_ENABLED) {
            return 0;
        }

        let totalPaid = 0;
        let totalNonCashPaid = 0;
        for (const paymentMethod of this.state.paymentMethods) {
            if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                if (paymentMethod.methodType == PAYMENT_METHOD_CASH) {
                    totalPaid += parseFloat(paymentMethod.amount);
                } else {
                    totalNonCashPaid += parseFloat(paymentMethod.amount);
                }
            }
        }
        return totalPaid - (this.props.totalAmount - totalNonCashPaid)
    }

    renderReadOnlyAmount() {
        const processingFeeAmount = this.getTotalProcessingFeeAmount();
        if (Util.isNumberExist(processingFeeAmount)) {
            const actualAmount = this.props.totalAmount - processingFeeAmount;
            return (<>
                <h2>{this.props.currency} {actualAmount.toFixed(2)}</h2>
                <p style={{ fontSize: 14, opacity: 0.65, marginLeft: 5 }}>+ Processing Fee: AED {processingFeeAmount.toFixed(2)}</p>

                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DirectionIndicator inward={this.isInward()} suffix={this.isInward() ? "(receipt)" : "(payment)"} />
                    <p style={{ marginLeft: '0.25rem' }}>{actualAmount.toFixed(2)} + {processingFeeAmount.toFixed(2)} <span style={{ fontSize: 12, opacity: 0.65 }}>(processing fee)</span> = <strong>AED {this.props.totalAmount.toFixed(2)}</strong></p>
                </div> */}
            </>)
        } else {
            return (<h2>{this.props.currency} {this.props.totalAmount.toFixed(2)}</h2>)
        }
    }

    renderContent() {
        return (<>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '1rem' }}>
                <div style={{ flex: 1 }}>
                    {!this.props.hideDirection && <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h5>{this.props.incoming ? 'Inward' : 'Outward'}</h5>
                        <p style={{ fontSize: 12, marginLeft: '0.25rem', color: this.props.showColorForIncome || true ? (this.props.incoming ? 'green' : 'red') : undefined }}>({this.props.incoming ? 'receipt' : 'payment'})</p>
                    </div>}
                    {this.props.readOnly ? (
                        // !this.props.hideTotal && <h2>{this.props.currency} {this.props.totalAmount.toFixed(2)}</h2>
                        !this.props.hideTotal && this.renderReadOnlyAmount()
                    ) : (<>
                        {!this.props.hideTotal && <h2>Total: {this.props.currency} {this.props.totalAmount.toFixed(2)}</h2>}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h4 style={{ color: 'green', }}>{this.props.incoming ? 'Received' : 'Paid'}: {this.props.currency} {this.getTotalAmountPaid().toFixed(2)}</h4>


                            {this.getTotalAmountPaid() < this.props.totalAmount && <>
                                <p style={{ fontSize: 12, marginLeft: '0.25rem', alignSelf: 'flex-end', marginBottom: 3 }}>Remaining: {this.props.currency} {this.getRemainingAmount().toFixed(2)} left</p>
                            </>}

                            {!this.props.posMode && this.getTotalAmountPaid() > this.props.totalAmount && <>
                                <div style={{ width: '1rem' }} />
                                <Warning16 />
                                <p style={{ fontSize: 14, marginLeft: '0.5rem', opacity: 0.65 }}>Amount {this.props.incoming ? 'received' : 'paid'} is greater than actual amount</p>
                            </>}

                            {this.props.posMode && this.isNonCashAmountExceeded() && <>
                                {TIP_ENABLED && this.props.incoming ? (<>
                                    <div style={{ width: '1rem' }} />
                                    <Warning16 style={{ fill: 'darkorange' }} />
                                    <p style={{ fontSize: 14, marginLeft: '0.5rem', opacity: 1, color: 'darkorange', fontWeight: 'bold' }}>You are receiving more money as tip</p>
                                </>) : (<>
                                    <div style={{ width: '1rem' }} />
                                    <Warning16 style={{ fill: 'red' }} />
                                    <p style={{ fontSize: 14, marginLeft: '0.5rem', opacity: 1, color: 'red' }}>Non-cash amount {this.props.incoming ? 'received' : 'paid'} is greater than total amount</p>
                                </>)}
                            </>}
                        </div>
                        {this.getTotalProcessingFeeAmount() > 0 &&
                            <p style={{ fontSize: 14, opacity: 0.65 }}>Processing Fee: AED {this.getTotalProcessingFeeAmount().toFixed(2)}</p>}
                    </>)}


                </div>

                {!this.props.readOnly && (
                    this.props.posMode ? (
                        <div style={{
                            display: 'flex', gap: '0.5rem', flexWrap: 'wrap',
                            minWidth: 0, flex: this.canUseAdvance() ? 1.5 : 1, justifyContent: 'flex-end'
                        }}>
                            <div className="cash-button">
                                <Button renderIcon={Money32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_CASH)}>Cash</Button>
                            </div>
                            <div className="credit-card-button">
                                <Button renderIcon={Purchase16} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_CREDIT)}>
                                    {isQasr() ? 'Card' : 'Credit Card'}
                                </Button>
                            </div>
                            {isQasr() && <Button renderIcon={Finance32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_BANK)}>Bank</Button>}

                            {!isQasr() && <div className="debit-card-button">
                                <Button renderIcon={Wallet32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_DEBIT)}>Debit Card</Button>
                            </div>}
                            {this.canUseAdvance() &&
                                <div className="advance-payment-button">
                                    <Button renderIcon={RecentlyViewed32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_PROXY_ADVANCE)}>Advance</Button>
                                </div>}

                            {isFoodBazaar() && <>
                                <Button renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_FRESH_BAZAAR)}>Fresh Bazaar</Button>
                                <Button renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_ART_BAZAAR)}>Art Bazaar</Button>
                                <Button kind={"danger"} renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_COMPANY_EXPENSE)}>Company Expense</Button>
                            </>}

                            {/* talabat, careem, nooon */}

                            {isAbsharRestaurant() && <>
                                <Button className="cst-btn-orange" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_TALABAT)}>Talabat</Button>
                                <Button className="cst-btn-pink-red" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_ZOMATO)}>Zomato</Button>
                                <Button className="cst-btn-cyan" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_DELIVEROO)}>Deliveroo</Button>
                                <Button className="cst-btn-green" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_CAREEM)}>Careem</Button>
                                <Button className="cst-btn-yellow" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_NOON)}>Noon</Button>
                            </>}

                            {isAbsharShop() && <>
                                <Button className="cst-btn-orange" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_TALABAT)}>Talabat</Button>
                                <Button className="cst-btn-green" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_CAREEM)}>Careem</Button>
                                <Button className="cst-btn-yellow" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_NOON)}>Noon</Button>

                                <Button className="cst-btn-cyan" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_DELIVEROO)}>Deliveroo</Button>
                                <Button className="cst-btn-pink-red" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_INSTASHOP)}>InstaShop</Button>
                            </>}

                            {this.props.hasCourierPM && <>
                                <Button className="cst-btn-dark-blue" renderIcon={Settings32} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_COURIER)}>Courier</Button>
                            </>}
                        </div>
                    ) : (
                        <div style={{ display: 'flex', gap: '0.1rem' }}>
                            {/* <Button kind="secondary" renderIcon={Percentage16} hasIconOnly iconDescription="Divide" /> */}
                            <div style={{ width: '0.1rem' }} />
                            <Button renderIcon={Money32} hasIconOnly iconDescription="Cash" data-tip="Cash" onClick={() => this.addPaymentMethod(PAYMENT_METHOD_CASH)} />
                            <Button renderIcon={Purchase16} hasIconOnly iconDescription="Credit Card" data-tip="Credit Card" onClick={() => this.addPaymentMethod(PAYMENT_METHOD_CREDIT)} />
                            <Button renderIcon={Wallet32} hasIconOnly iconDescription="Debit Card" data-tip="Debit Card" onClick={() => this.addPaymentMethod(PAYMENT_METHOD_DEBIT)} />
                            <Button renderIcon={Account32} hasIconOnly iconDescription="Cheque" data-tip="Cheque" onClick={() => this.addPaymentMethod(PAYMENT_METHOD_CHEQUE)} />
                            <Button renderIcon={Finance32} hasIconOnly iconDescription="Bank" data-tip="Bank" onClick={() => this.addPaymentMethod(PAYMENT_METHOD_BANK)} />
                            <Button renderIcon={Currency32} hasIconOnly iconDescription="Other" data-tip="Other" onClick={() => this.addPaymentMethod(PAYMENT_METHOD_OTHER)} tooltipAlignment="end" />

                            {this.props.hasCouponMethod &&
                                <Button style={{ marginLeft: '0.5rem' }} kind="secondary" renderIcon={CutOut16} onClick={() => this.addPaymentMethod(PAYMENT_METHOD_COUPON)}>Generate Coupon</Button>}
                        </div>
                    )
                )}
            </div>

            {this.canUseAdvance() &&
                <div style={{
                    borderRadius: 5, border: '1px solid #00990020', paddingBlock: '0.15rem', paddingBlock: '0.5rem', gap: '0.25rem', background: "darkgreen",
                    display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
                    color: 'white', marginBottom: '1rem'
                }}>
                    <RecentlyViewed16 />
                    <p style={{ fontSize: 14 }}>Advance payment balance: <strong style={{ fontSize: 15 }}>AED {Util.formatMoney(this.state.advanceBalance)}</strong> </p>
                </div>
            }

            {this.state.paymentMethods.length > 0 ? (
                this.state.paymentMethods.map((paymentMethod, index) =>
                    <PaymentMethodItem
                        noFocus={this.props.noFocus}
                        incoming={this.props.incoming}
                        supportedCurrencies={this.state.supportedCurrencies}
                        cheques={this.props.incoming ? this.state.receivableCheques : this.state.payableCheques}
                        posMode={this.props.posMode} readOnly={this.props.readOnly} key={Util.isStringExists(paymentMethod.tempId) ? paymentMethod.tempId : ('payment-method-' + paymentMethod.id)} paymentMethod={paymentMethod}
                        onPropertyUpdated={(property, value) => this.setState(prevState => {

                            let paymentMethods = [...prevState.paymentMethods];
                            //let methodIndex = paymentMethods.indexOf(paymentMethod)
                            let methodIndex = -1;
                            for (const method of paymentMethods) {
                                if (Util.isStringExists(paymentMethod.tempId)) {
                                    if (paymentMethod.tempId == method.tempId) {
                                        methodIndex = paymentMethods.indexOf(method);
                                        break;
                                    }
                                } else {
                                    if (paymentMethod.id == method.id) {
                                        methodIndex = paymentMethods.indexOf(method);
                                        break;
                                    }
                                }
                            }

                            paymentMethods[methodIndex] = {
                                ...paymentMethods[methodIndex],
                                [property]: value
                            }

                            // console.log("updating methods on poerpty " + property, methodIndex)
                            return { paymentMethods };
                        }, () => this.props.onUpdate(this.state.paymentMethods))}
                        onRemove={() => this.removePaymentMethod(paymentMethod)} />)
            ) : this.renderNoPaymentMethod()}
        </>)
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ width: '100%', height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <InlineLoading />
                    </div>
                </div>
            )
        }

        return (
            <div>
                {this.renderContent()}

                {this.props.posMode && <div style={{ height: '3rem' }} />}
                {this.props.posMode && !this.isNonCashAmountExceeded() && this.isCashAmountExceeded() &&
                    <div style={{ color: 'red', marginBottom: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', color: 'red' }}>
                            <Money16 />
                            <p>Cash Back</p>
                        </div>
                        <h2 style={{ fontWeight: 'bold' }}>AED {this.getCashReturnAmount().toFixed(2)}</h2>
                    </div>}
            </div>
        )
    }

}

export default TransactionEditor;