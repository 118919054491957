import { useState } from "react";
import Button from "../Button";
import UploadWrapper from "../UploadWrapper";
import { Upload16, Close16, Download16 } from '@carbon/icons-react'
import Util from "../../util/Util";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";


export function UploadField({
    label, value, setValue, readonly,
    selectMsg = 'Select file to upload',
    uploadMsg = 'Upload',
    imageMode,
    dontClear
}) {
    // const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false)

    const onUpload = async file => {
        let cancelled = false;

        setLoading(true)
        let actualFile;
        if (imageMode) {
            try {
                actualFile = await Api.compressImage(file)
            } catch (e) {
                UIUtil.showError();
                setLoading(false)
                return;
            }
        } else {
            actualFile = file;
        }
        Api.uploadFile(actualFile, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setValue(response.payload)
                UIUtil.showSuccess(response)
            } else {
                UIUtil.showError(response.message);
            }

            setLoading(false)
        })

        return () => {
            cancelled = true
            setLoading(false);
        };
    }


    const hasFile = Util.isStringExists(value);
    return (<>
        {Util.isStringExists(label) && <>
            <label className="bx--label">{label}</label>
            <br />
        </>}
        <div style={{ position: 'relative', background: '#f4f4f4', borderRadius: 0, borderBottom: hasFile ? '1px solid #0f62fe' : '1px solid #8d8d8d', width: '100%', overflow: 'hidden' }}>
            {hasFile ? (<>
                {imageMode ? (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '0rem', gap: '0rem', minHeight: 40 }}>
                        <img onClick={() => {
                            UIUtil.showOverlay2(onClose => <img onClick={onClose} src={Api.downloadUploadFieldFileAsImage(value)} style={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center' }} />)
                        }} src={Api.downloadUploadFieldFileAsImage(value)} style={{ flex: 1, height: 100, objectFit: 'cover', objectPosition: 'center' }} />
                        {!readonly && !dontClear && <Button //className="bx--tooltip--hidden" 
                            onClick={() => setValue(null)}
                            tooltipPosition="left" tooltipAlignment="end" iconDescription="Clear upload"
                            hasIconOnly kind="ghost" renderIcon={Close16} style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} size="sm" />}
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '0rem', gap: '0rem', minHeight: 40 }}>
                        <div style={{ width: 40, height: 40, marginRight: '0rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#0f62fe' }}>
                            <Download16 />
                        </div>
                        <a style={{ flex: 1, minWidth: 0 }} download href={Api.downloadUploadFieldFile(value)}>
                            <p style={{ fontSize: 14, wordWrap: 'break-word' }}>Download</p>
                        </a>
                        {!readonly && <Button //className="bx--tooltip--hidden" 
                            onClick={() => setValue(null)}
                            tooltipPosition="left" tooltipAlignment="end" iconDescription="Clear upload"
                            hasIconOnly kind="ghost" renderIcon={Close16} style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} size="sm" />}
                    </div>
                )}
            </>) : (
                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', height: 40 }}>
                    <p style={{ fontSize: 12, opacity: 0.65, flex: 1 }}>{!loading ? selectMsg : 'Uploading...'}</p>
                    <UploadWrapper accept={imageMode ? "image/gif,image/jpeg,image/png" : undefined} onChange={e => onUpload(e.target.files[0])}>
                        <Button loading={loading} kind="ghost" renderIcon={Upload16} style={{ height: 40 }} size="sm">{!loading && uploadMsg}</Button>
                    </UploadWrapper>
                </div>
            )}
        </div>
    </>)
}