import { hasCapabilitySupport } from "../../../app/Capabilities"
import Button from "../../../components/Button"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { openPdf } from "../../../markup-template-engine"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection } from "../../../templates/form/form"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24 } from '@carbon/icons-react'

export const InvAdjustmentList = newListDetail({
    title: "Stock Adjustment",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: Form,

    openDetailOnCreate: true,
    clearStateOnSave: true,
    skipInlinePadding: true,
    canDelete: false,

    defaultValues: {
        items: [],
    },

    isAvailable: () => {
        return false;

        if (hasCapabilitySupport("cargo")) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

function Form({ endpoint, form, data }) {
    return (
        <div>
            {!!data?.id && <Button onClick={() => openPdf("InvAdjustmentDoc", data.id)}>Print</Button>}
            <FormSection title={"Memo"} titleStyle={{ paddingInline: '3rem' }}>
                <form.TextAreaField fieldKey="memo" />
            </FormSection>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
