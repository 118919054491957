import './App.scss';
import './Theme.scss';
import { Button, Content, Header, HeaderContainer, Pagination, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, HeaderPanel, Modal, SideNav, SideNavItems, SideNavLink, SideNavMenuItem, SkipToContent, Switcher, SwitcherDivider, SwitcherItem, TableContainer, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tile, HeaderNavigation, Link, ComposedModal, ModalHeader, ModalBody, ButtonSet } from 'carbon-components-react';
//import { iconAddSolid } from 'carbon-icons'
import { Fade16, Search20, Scale32, DocumentPdf16, ArrowRight16, DeliveryParcel20, OpenPanelFilledBottom20, Printer20, Reset20, Email16, CheckmarkFilled16, ErrorFilled16, CheckmarkOutline16, Product20, Logout16, Barcode20, FitToScreen20, Login20, Menu20, Close20, Settings20 } from '@carbon/icons-react'
import React, { createRef, useState } from 'react';
import { SideNavMenu } from 'carbon-components-react/lib/components/UIShell/SideNavMenu';

import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from 'carbon-components-react';
import ProfilePic from './components/ProfilePic';
import ClockRender from './components/ClockRender';
import Page from './base/Page';
import { clearAccount, getAccount, getAccountRole, getTerminal, hasTerminal, isLoggedIn, saveTheme } from './session/SessionManager';
import AuthView from './views/auth/AuthView';
import { BrowserRouter, Route, Switch, Link as RL } from 'react-router-dom';
import UserListPage from './pages/user/UserListPage';
import UserDetailPage from './pages/user/UserDetailPage';
import ErrorPage from './components/page-messages/ErrorPage';
import FullMenu from './components/FullMenu';
import ProductCategoryDetailPage from './pages/product-category/ProductCategoryDetailPage';
import ProductCategoryListPage from './pages/product-category/ProductCategoryListPage';
import StoreListPage from './pages/store/StoreListPage';
import StoreDetailPage from './pages/store/StoreDetailPage';
import TerminalDetailPage from './pages/terminal/TerminalDetailPage';
import TerminalListPage from './pages/terminal/TerminalListPage';
import ProductBundleListPage from './pages/product-bundle/ProductBundleListPage';
import ProductBundleDetailPage from './pages/product-bundle/ProductBundleDetailPage';
import ProductListPage from './pages/product/ProductListPage';
import ProductDetailPage from './pages/product/ProductDetailPage';
import LoyaltyCardDetailPage from './pages/loyalty-card/LoyaltyCardDetailPage';
import LoyaltyCardListPage from './pages/loyalty-card/LoyaltyCardListPage';
import SingleBarcodeScannerDialog from './views/barcodes/SingleBarcodeScannerDialog';
import PosPage from './pages/pos/PosPage';
import LoyaltyCardIssueDetailPage from './pages/loyalty-card/LoyaltyCardIssueDetailPage';
import ProductInfoPage from './pages/product/ProductInfoPage';
import TerminalSetDialog from './views/terminal/TerminalSetDialog';
import Util, { isV2 } from './util/Util';
import CustomerListPage from './pages/customer/CustomerListPage';
import CustomerDetailPage from './pages/customer/CustomerDetailPage';

import { Link as L } from 'react-router-dom'

import Logo from './images/LogoTransparent.png'
import RoboticLogo from './images/RL.png'


import SupplierListPage from './pages/supplier/SupplierListPage';
import SupplierDetailPage from './pages/supplier/SupplierDetailPage';
import CustomerGroupListPage from './pages/customer-group/CustomerGroupListPage';
import CustomerGroupDetailPage from './pages/customer-group/CustomerGroupDetailPage';
import CouponListPage from './pages/coupon/CouponListPage';
import CouponDetailPage from './pages/coupon/CouponDetailPage';
import DiscountListPage from './pages/discount/DiscountListPage';
import DiscountDetailPage from './pages/discount/DiscountDetailPage';
import ContactListPage from './pages/contact/ContactListPage';
import ContactDetailPage from './pages/contact/ContactDetailPage';
import WarehouseListPage from './pages/warehouse/WarehouseListPage';
import WarehouseDetailPage from './pages/warehouse/WarehouseDetailPage';
import GroupPriceOverrideDetailPage from './views/price-override/GroupPriceOverrideDetailPage';
import PriceOverrideDetailPage from './views/price-override/PriceOverrideDetailPage';
import StockFlowCreatorPage from './pages/stock-flow/StockFlowCreatorPage';
import TransactionCreatorPage from './pages/transaction/TransactionCreatorPage';
import SocketSession from './session/SocketSession';
import MonitoringPage from './pages/monitoring/MonitoringPage';
import Api from './session/Api';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_FNB_EVENT_MENU, OBJECT_TYPE_FNB_EVENT_SALON, OBJECT_TYPE_FNB_EVENT_SERVICE, getObjectTypeUrl } from './constants/ObjectTypes';
import StockFlowItemPage from './pages/stock-flow/StockFlowItemPage';
import TransactionItemPage from './pages/transaction/TransactionItemPage';
import TransactionsPage from './pages/transaction/TransactionsPage';
import StockFlowsPage from './pages/stock-flow/StockFlowsPage';
import QuotationsPage from './pages/quotation/QuotationsPage';
import IncomeReportPage from './pages/reporting/IncomeReportPage';
import ExpenseReportPage from './pages/reporting/ExpenseReportPage';
import ProfitReportPage from './pages/reporting/ProfitReportPage';
import Menu from './components/Menu';

import { Scrollbars } from 'react-custom-scrollbars';
import AccountLedgerGroupsListPage from './pages/accounting/account-ledger-groups/AccountLedgerGroupsListPage';
import AccountLedgersListPage from './pages/accounting/account-ledgers/AccountLedgersListPage';
import AccountLedgerGroupsDetailPage from './pages/accounting/account-ledger-groups/AccountLedgerGroupsDetailPage';
import AccountLedgersDetailPage from './pages/accounting/account-ledgers/AccountLedgersDetailPage';
import JournalEntriesPage from './pages/journal-entries/JournalEntriesPage';
import JournalEntriesCreatorPage from './pages/journal-entries/JournalEntriesCreatorPage';
import JournalEntriesItemPage from './pages/journal-entries/JournalEntriesItemPage';
import StatementReportPage from './pages/accounting/statement-reports/StatementReportPage';
import { ACCOUNTING_STATEMENT_BALANCE_SHEET, ACCOUNTING_STATEMENT_INCOME_SHEET, ACCOUNTING_STATEMENT_TRIAL_BALANCE, ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_CASHIER, ACCOUNT_TYPE_CMS_MANAGER, ACCOUNT_TYPE_DELIVERY_MANAGER, ACCOUNT_TYPE_GUARDIAN, ACCOUNT_TYPE_LEARNING_MANAGER, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_REAL_ESTATE_AGENT, ACCOUNT_TYPE_REST_DELIVERY_MANAGER, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SALES_ADMIN, ACCOUNT_TYPE_SECOND_DISPLAY, ACCOUNT_TYPE_SHIPPING_PORT_STAFF, ACCOUNT_TYPE_STORE_MASTER, ACCOUNT_TYPE_STUDENT, ACCOUNT_TYPE_SUPERVISOR, ACCOUNT_TYPE_TUTOR, ACCOUNT_TYPE_TYPE_KITCHEN, ACCOUNT_TYPE_TYPE_WAITER, ACCOUNT_TYPE_VENUE_MANAGER, ACCOUNT_TYPE_WAREHOUSE_KEEPER } from './constants/Constants';
import SalesSummaryReport from './pages/sale-reports/SalesSummaryReport';
import SalesReportPage from './pages/sale-reports/SalesReportPage';
import PurchasesReportPage from './pages/sale-reports/PurchasesReportPage';
import StockReportPage from './pages/reporting/StockReportPage';
import ProductStockReportPage from './pages/reporting/ProductStockReportPage';
import ProductPerformanceReport from './pages/sale-reports/ProductPerformanceReport';
import POSSessionReportPage from './pages/reporting/POSSessionReportPage';
import PosSessionItemPage from './pages/pos-session/PosSessionItemPage';
import { FailedTaskPosReceiptDownload, printPosReceipt } from './pdfs/pos-receipt/PosReceiptPdf';
import UIUtil from './util/UIUtil';
import AccountTreePage from './pages/accounting/AccountTreePage';
import ProductLabelsPage from './pages/product-labels/ProductLabelsPage';
import TaxListPage from './pages/tax/TaxListPage';
import TaxDetailPage from './pages/tax/TaxDetailPage';

import ReactTooltip from 'react-tooltip';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ChequeListPage from './pages/cheque/ChequeListPage';
import ChequeDetailPage from './pages/cheque/ChequeDetailPage';
import LowStockProductReport from './pages/product-reports/LowStockProductReport';
import ImportDataPage from './pages/import-data/ImportDataPage';
import ImportDataTypePage from './pages/import-data/ImportDataTypePage';
import SystemLogsPage from './pages/system-logs/SystemLogsPage';
import ThermalPrinter, { THERMAL_PRINTER_STATUS_CONNECTED, THERMAL_PRINTER_STATUS_DISCONNECTED, THERMAL_PRINTER_STATUS_LOADING } from './integrations/thermal-printer/ThermalPrinter';
import EpsonThermalPrinterDriver from './integrations/thermal-printer/impl/EpsonThermalPrinterDriver';
import WelcomePage from './pages/message-pages/WelcomePage';
import ProductBundleLabelsPage from './pages/product-labels/ProductBundleLabelsPage';
import EmailSender from './templates/EmailSender';
import { getDefTheme, THEME_LIST } from './themes/ThemeList';
import DashboardPage from './pages/dashboard/DashboardPage';
import ClearanceCodeDialog from './views/auth/ClearanceCodeDialog';
import SupportedCurrencyListPage from './pages/supported-currency/SupportedCurrencyListPage';
import SupportedCurrencyDetailPage from './pages/supported-currency/SupportedCurrencyDetailPage';
import ProductDepartmentListPage from './pages/product-department/ProductDepartmentListPage';
import ProductDepartmentDetailPage from './pages/product-department/ProductDepartmentDetailPage';
import ProductSubcategoryListPage from './pages/product-subcategory/ProductSubcategoryListPage';
import ProductSubcategoryDetailPage from './pages/product-subcategory/ProductSubcategoryDetailPage';
import ProductBrandListPage from './pages/product-brand/ProductBrandListPage';
import ProductBrandDetailPage from './pages/product-brand/ProductBrandDetailPage';
import ScrollToTopBtn from './components/ScrollToTopBtn';
import LabelDesignerPage from './pages/label-designer/LabelDesignerPage';
import { saveComponentAsImage } from './util/ComponentSaver';
import TemplatesListPage from './pages/templates/TemplatesListPage';
import ProductFinder from './views/product/ProductFinder';
import SalesAppPage from './pages/sales-app/SalesAppPage';
import DeliveryAppPage from './pages/sales-app/DeliveryAppPage';
import GeneralLedgerReportPage from './pages/accounting-reports/general-ledger-report/GeneralLedgerReportPage';
import GeneralLedgerResultPage from './pages/accounting-reports/general-ledger-report/GeneralLedgerResultPage';
import SubsidiaryLedgerResultPage from './pages/accounting-reports/subsidiary-ledger-report/SubsidiaryLedgerResultPage';
import SubsidiaryLedgerReportPage from './pages/accounting-reports/subsidiary-ledger-report/SubsidiaryLedgerReportPage';
import SystemValuesPage from './pages/system-values/SystemValuesPage';
import { hasCapabilitySupport } from './app/Capabilities';
import CRVCreatorPage from './pages/crv/CRVCreatorPage';
import CRVPage from './pages/crv/CRVPage';
import CRVReportPage from './pages/crv/CRVReportPage';
import MobileUnitDetailPage from './pages/mobile-unit/MobileUnitDetailPage';
import MobileUnitListPage from './pages/mobile-unit/MobileUnitListPage';
import CashierSalesReturnPage from './pages/sales-return-and-exchanges/CashierSalesReturnPage'
import PocketPosAppPage from './pages/sales-app/PocketPosAppPage'
import MeasurementTypeDetailPage from './pages/measurement-type/MeasurementTypeDetailPage';
import MeasurementTypeListPage from './pages/measurement-type/MeasurementTypeListPage';
import SalesCartListPage from './pages/sales-system/SalesCartListPage';
import SalesCartTypesDetailPage from './pages/sales-cart-types/SalesCartTypesDetailPage';
import SalesCartTypesListPage from './pages/sales-cart-types/SalesCartTypesListPage';
import SalesCartPage from './pages/sales-system/SalesCartPage';
import CRVItemPage from './pages/crv/CRVItemPage';
import OutstandingReportPage from './pages/accounting-reports/OutstandingReportPage';
import OutstandingStudentReportPage from './pages/accounting-reports/OutstandingStudentReportPage';
import { HAS_ROBOTIC_BRANDING, MENU_PACKAGE } from './app/Config';
import SalesReturnAndExchangesPage from './pages/sales-return-and-exchanges/SalesReturnAndExchangesPage';
import SideMenu from './components/menu/SideMenu';
import ProductPriceReport from './pages/product-sales-reports/price-performance-report/ProductPriceReport';
import ProductSalesReport from './pages/product-sales-reports/product-performance-report/ProductSalesReport';
import SystemSettingsPage from './pages/system-settings/SystemSettingsPage';
import ProductTimeReport from './pages/product-sales-reports/hourly-performance-report/ProductTimeReport';
import ProductAttributesDetailPage from './pages/product-attributes/ProductAttributesDetailPage';
import ProductAttributesListPage from './pages/product-attributes/ProductAttributesListPage';
import ProductGroupListPage from './pages/product-group/ProductGroupListPage';
import ProductGroupDetailPage from './pages/product-group/ProductGroupDetailPage';
import SalesOrderStateListPage from './pages/sales-order-state/SalesOrderStateListPage';
import SalesOrderStateDetailPage from './pages/sales-order-state/SalesOrderStateDetailPage';
import OnlineStorePaymentMethodsListPage from './pages/online-store/online-store-payment-methods/OnlineStorePaymentMethodsListPage';
import OnlineStorePaymentMethodsDetailPage from './pages/online-store/online-store-payment-methods/OnlineStorePaymentMethodsDetailPage';
import CustomPagesListPage from './pages/online-store/custom-pages/CustomPagesListPage';
import CustomPagesDetailPage from './pages/online-store/custom-pages/CustomPagesDetailPage';
import DeliverablesPage from './pages/sales-order/DeliverablesPage';
import NewsletterSubscriptionListPage from './pages/newsletter-subscription/NewsletterSubscriptionListPage';
import BlogPostsListPage from './pages/blog/blog-posts/BlogPostsListPage';
import BlogPostsDetailPage from './pages/blog/blog-posts/BlogPostsDetailPage';
import BlogCategoriesListPage from './pages/blog/blog-categories/BlogCategoriesListPage';
import BlogCategoriesDetailPage from './pages/blog/blog-categories/BlogCategoriesDetailPage';
import DeliverableItemPage from './pages/sales-order/DeliverableItemPage';
import SlidesListPage from './pages/online-store/slides/SlidesListPage';
import SlidesDetailPage from './pages/online-store/slides/SlidesDetailPage';
import DynamicContentPage from './pages/online-store/dynamic-content/DynamicContentPage';
import DesignationDetailPage from './pages/hr/designations/DesignationDetailPage';
import DesignationListPage from './pages/hr/designations/DesignationListPage';
import LeaveListPage from './pages/hr/leave/LeaveListPage';
import LeaveTypeListPage from './pages/hr/leave-types/LeaveTypeListPage';
import LeaveDetailPage from './pages/hr/leave/LeaveDetailPage';
import EmployeeListPage from './pages/hr/employee/EmployeeListPage';
import EmployeeDetailPage from './pages/hr/employee/EmployeeDetailPage';
import SalaryPackageListPage from './pages/hr/salary-package/SalaryPackageListPage';
import SalaryPackageDetailPage from './pages/hr/salary-package/SalaryPackageDetailPage';
import PayHeadListPage from './pages/hr/pay-head/PayHeadListPage';
import PayHeadDetailPage from './pages/hr/pay-head/PayHeadDetailPage';
import LeaveTypeDetailPage from './pages/hr/leave-types/LeaveTypeDetailPage';
import StatementOfAccountsPage from './pages/statement-of-accounts/StatementOfAccountsPage';
import VendorListPage from './pages/vendor/VendorListPage';
import VendorDetailPage from './pages/vendor/VendorDetailPage';
import PageHeadListPage from './pages/online-store/page-heads/PageHeadListPage';
import PageHeadDetailPage from './pages/online-store/page-heads/PageHeadDetailPage';
import ThirdPartyPos from './pages/store-master/third-party-pos/ThirdPartyPosPage';
import MyStockRequestList from './pages/store-master/stock-request/MyStockRequestList';
import MyStockRequestDetail from './pages/store-master/stock-request/MyStockRequestDetail';
import CreateStockRequest from './pages/store-master/stock-request/CreateStockRequest';
import StockRequestListPage from './pages/stock-requests/StockRequestListPage';
import StockRequestDetailPage from './pages/stock-requests/StockRequestDetailPage';
import VenueListPage from './pages/venue/VenueListPage';
import VenueDetailPage from './pages/venue/VenueDetailPage';
import { InvoiceDocument } from './pdfs/invoice-document/InvoiceDocumentPdf';
import InvoiceDocumentListPage from './pages/invoice-documents/InvoiceDocumentListPage';
import InvoiceDocumentDetailPage from './pages/invoice-documents/InvoiceDocumentDetailPage';
import BillsPage from './pages/bill/BillsPage';
import BillCreatorPage from './pages/bill/BillCreatorPage';
import BillItemPage from './pages/bill/BillItemPage';

import PayoutsPage from './pages/payout/PayoutsPage';
import PayoutCreatorPage from './pages/payout/PayoutCreatorPage';
import PayoutItemPage from './pages/payout/PayoutItemPage';

import NoPosPage from './pages/message-pages/NoPosPage';
import VenueSalesReportPage from './pages/sale-reports/VenueSalesReportPage';
import GalleryPostsDetailPage from './pages/gallery-posts/GalleryPostsDetailPage';
import GalleryPostsListPage from './pages/gallery-posts/GalleryPostsListPage';
import TestimonyPostsListPage from './pages/testimony-posts/TestimonyPostsListPage';
import TestimonyPostsDetailPage from './pages/testimony-posts/TestimonyPostsDetailPage';
import PrivateCustomPagesListPage from './pages/online-store/private-custom-pages/PrivateCustomPagesListPage';
import PrivateCustomPagesDetailPage from './pages/online-store/private-custom-pages/PrivateCustomPagesDetailPage';
import PurchasesPage from './pages/purchase/PurchasesPage';
import PurchaseCreatorPage from './pages/purchase/PurchaseCreatorPage';
import PurchaseItemPage from './pages/purchase/PurchaseItemPage';
import ProductServiceTable from './views/editable-tables/ProductServiceTable';
import ServiceListPage from './pages/service/ServiceListPage';
import ServiceDetailPage from './pages/service/ServiceDetailPage';
import ServiceCategoryDetailPage from './pages/service-category/ServiceCategoryDetailPage';
import ServiceCategoryListPage from './pages/service-category/ServiceCategoryListPage';
import ServiceSubcategoryListPage from './pages/service-subcategory/ServiceSubcategoryListPage';
import ServiceSubcategoryDetailPage from './pages/service-subcategory/ServiceSubcategoryDetailPage';
import ServiceDepartmentListPage from './pages/service-department/ServiceDepartmentListPage';
import ServiceDepartmentDetailPage from './pages/service-department/ServiceDepartmentDetailPage';
import ServicePackageListPage from './pages/service-package/ServicePackageListPage';
import ServicePackageDetailPage from './pages/service-package/ServicePackageDetailPage';
import ServiceSalesPage from './pages/service-sale/ServiceSalesPage';
import ServiceSaleCreatorPage from './pages/service-sale/ServiceSaleCreatorPage';
import ServiceSaleItemPage from './pages/service-sale/ServiceSaleItemPage';
import SalesPage from './pages/sale/SalesPage';
import SaleCreatorPage from './pages/sale/SaleCreatorPage';
import SaleItemPage from './pages/sale/SaleItemPage';
import { ActivityBlueprintDetailPage } from './pages/activities/pages/blueprint/detail';
import { ActivityBlueprintListPage } from './pages/activities/pages/blueprint/list';
import { BoardView, TestView } from './views/board-view/BoardView';

import RealEstateCityListPage from './pages/real-estate/city/CityListPage';
import RealEstateCityDetailPage from './pages/real-estate/city/CityDetailPage';

import RealEstateAreaListPage from './pages/real-estate/area/AreaListPage';
import RealEstateAreaDetailPage from './pages/real-estate/area/AreaDetailPage';

import RealEstateProjectListPage from './pages/real-estate/project/ProjectListPage';
import RealEstateProjectDetailPage from './pages/real-estate/project/ProjectDetailPage';

import RealEstatePropertyListPage from './pages/real-estate/property/PropertyListPage';
import RealEstatePropertyDetailPage from './pages/real-estate/property/PropertyDetailPage';

import RealEstateIndividualListPage from './pages/real-estate/individual/IndividualListPage';
import RealEstateIndividualDetailPage from './pages/real-estate/individual/IndividualDetailPage';

import RealEstateDeveloperListPage from './pages/real-estate/developer/DeveloperListPage';
import RealEstateDeveloperDetailPage from './pages/real-estate/developer/DeveloperDetailPage';

import RealEstateProjectTypeListPage from './pages/real-estate/project-type/ProjectTypeListPage';
import RealEstateProjectTypeDetailPage from './pages/real-estate/project-type/ProjectTypeDetailPage';

import RealEstatePropertyTypeListPage from './pages/real-estate/property-type/PropertyTypeListPage';
import RealEstatePropertyTypeDetailPage from './pages/real-estate/property-type/PropertyTypeDetailPage';
import { ActivityStatusesDetailPage } from './pages/activities/pages/statuses/detail';
import { ActivityStatusesListPage } from './pages/activities/pages/statuses/list';
import { ActivityMemberTagsListPage } from './pages/activities/pages/member-tags/list';
import { ActivityMemberTagsDetailPage } from './pages/activities/pages/member-tags/detail';
import { CommentView } from './views/comment/comment-view';
import { ActivityDetailPage } from './pages/activities/pages/activities/detail';
import { FeaturedPropertyListPage } from './pages/real-estate/featured-property/list';
import { FeaturedPropertyDetailPage } from './pages/real-estate/featured-property/detail';
import { FeaturedOffPlanDetailPage } from './pages/real-estate/featured-off-plan/detail';
import { FeaturedOffPlanListPage } from './pages/real-estate/featured-off-plan/list';
import { LeadSourcesDetailPage } from './pages/leads/sources/detail';
import { LeadSourcesListPage } from './pages/leads/sources/list';
import { LeadTagsListPage } from './pages/leads/tags/list';
import { LeadTagsDetailPage } from './pages/leads/tags/detail';
import { AllLeadFlowList } from './pages/leads/lead-list/all-list'
import { MyLeadFlowList } from './pages/leads/lead-list/my-list'
import { LeadFlowDetail } from './pages/leads/lead-flow/detail';
import { CalendarPage } from './pages/calendar/calendar-page';
import { BoardViewPage } from './pages/board-view/board-view-page';
import { StudentsListPage } from './pages/lms/students/list';
import { GuardianListPage } from './pages/lms/guardians/list';
import { GuardianDetailPage } from './pages/lms/guardians/detail';
import { CoursesDetailPage } from './pages/lms/courses/detail';
import { CoursesListPage } from './pages/lms/courses/list';
import { TutorsListPage } from './pages/lms/tutors/list';
import { TutorsDetailPage } from './pages/lms/tutors/detail';
import { StudentsDetailPage } from './pages/lms/students/detail';
import { CourseEnrolmentDetailPage } from './pages/lms/course-enrolments/detail';
import { StudentProfilePage } from './pages/lms/role-pages/student-profile';
import { TutorProfilePage } from './pages/lms/role-pages/tutor-profile';
import { MyCoursesPage } from './pages/lms/role-pages/my-courses';
import { MyCourseDetailPage } from './pages/lms/role-pages/my-courses/detail';
import { StudentWelcomePage } from './pages/lms/role-pages/welcome/student-welcome';
import { TutorWelcomePage } from './pages/lms/role-pages/welcome/tutor-welcome';
import { LmsAuthView } from './views/auth/lms-auth-view';
import { EnrolmentRequestList } from './pages/lms/enrolment-requests/list';
import { EnrolmentRequestDetail } from './pages/lms/enrolment-requests/detail';
import { SessionTimeout } from './session/session-timeout';
import { OnlineStoreAdminView } from './pages/online-store/admin-view';
import { ProductGroupSetAttribute } from './views/product/ProductGroupSetAttribute';
import { PredefinedClassesListPage } from './pages/predefined-classes/list';
import { PredefinedClassesDetailPage } from './pages/predefined-classes/detail';
import { isStockEntityFifoEngine } from './stock-system/stock-engine';
import { StripePaymentMessagePage } from './views/payment-dialog/stripe-payment-message-page';
import { StudentEntranceActivityPage } from './pages/lms/student-entrance-activity/list';
import { GuardianWelcomePage } from './pages/lms/role-pages/welcome/guardian-welcome';
import { GuardianProfilePage } from './pages/lms/role-pages/guardian-profile';
import CommissionsPage from './pages/property-finances/commission/CommissionsPage';
import CommissionCreatorPage from './pages/property-finances/commission/CommissionCreatorPage';
import CommissionItemPage from './pages/property-finances/commission/CommissionItemPage';
import PropertyDealsPage from './pages/property-finances/property-deals/PropertyDealsPage';
import { PropertyDealItemPage } from './pages/property-finances/property-deals/PropertyDealItemPage';
import MyStudentsPage from './pages/lms/role-pages/my-students/my-students-page';
import { CinemaListPage } from './pages/cinema/cinema/list';
import { CinemaDetailPage } from './pages/cinema/cinema/detail';
import { MovieListPage } from './pages/cinema/movie/list';
import { MovieDetailPage } from './pages/cinema/movie/detail';
import { MovieDistributorListPage } from './pages/cinema/movie-distributor/list';
import { MovieDistributorDetailPage } from './pages/cinema/movie-distributor/detail';
import { SeatingPlanListPage } from './pages/cinema/seating-plan/list';
import { SeatingPlanDetailPage } from './pages/cinema/seating-plan/detail';
import { TheaterTypeListPage } from './pages/cinema/theater-type/list';
import { TheaterTypeDetailPage } from './pages/cinema/theater-type/detail';
import { MovieLanguageListPage } from './pages/cinema/movie-language/list';
import { MovieLanguageDetailPage } from './pages/cinema/movie-language/detail';
import { MovieGenreListPage } from './pages/cinema/movie-genre/list';
import { MovieGenreDetailPage } from './pages/cinema/movie-genre/detail';
import { ShowtimeScheduler } from './pages/cinema/showtime-scheduler';
import TicketSalesPage from './pages/cinema/ticket-sales/TicketSalesPage';
import { TicketScanActivityPage } from './pages/cinema/ticket-scan/list';
import { ReportPage, getReportLink, routeReport } from './reporting-engine/base/report-page';
import { ReportingDashboard } from './reporting-engine/reporting-dashboard';
import RealEstateDashboardPage from './pages/dashboard-real-estate/RealEstateDashboardPage';
import { ShipmentItemPage } from './pages/shipping/shipment/item-page';
import RetailLMSDashboardPage from './pages/dashboard-real-estate/RetailLMSDashboardPage';
import { ProductClassificationsListPage } from './pages/product-classifications/list';
import { ProductClassificationsDetailPage } from './pages/product-classifications/detail';
import { ShipmentMasterDetailPage } from './pages/shipping/master/detail';
import { ShipmentPortListPage } from './pages/shipping/port/list';
import { ShipmentPortDetailPage } from './pages/shipping/port/detail';
import { ShipmentPackageTypeListPage } from './pages/shipping/package-type/list';
import { ShipmentPackageTypeDetailPage } from './pages/shipping/package-type/detail';
import { ShipmentItemTypeDetailPage } from './pages/shipping/item-type/detail';
import { ShipmentItemTypeListPage } from './pages/shipping/item-type/list';
import { ShipmentConsigneeDetailPage } from './pages/shipping/consignee/detail';
import { ShipmentConsigneeListPage } from './pages/shipping/consignee/list';
import { StaffPortShippingPage } from './pages/shipping/port-staff/shipping';
import { PortShipmentItemPage } from './pages/shipping/port-staff/shipment-item';
import { FundTransferDetailPage } from './pages/fund-transfer/detail';
import { Timeline } from './components/timeline/timeline';
import { LectureListPage } from './pages/learning/lecture/list';
import { LectureDetailPage } from './pages/learning/lecture/detail';
import { LectureVideoListPage } from './pages/learning/lecture-video/list';
import { LectureVideoDetailPage } from './pages/learning/lecture-video/detail';
import { LectureQuizListPage } from './pages/learning/lecture-quiz/list';
import { LectureQuizDetailPage } from './pages/learning/lecture-quiz/detail';
import { ShipmentLoadingModeTypeListPage } from './pages/shipping/loading-mode-type/list';
import { ShipmentLoadingModeTypeDetailPage } from './pages/shipping/loading-mode-type/detail';
import { ProductTargetListPage } from './pages/product/product-targets/list';
import { ProductTargetDetailPage } from './pages/product/product-targets/detail';
import { LmsGroupListPage } from './pages/lms/lms-group/list';
import { LmsGroupDetailPage } from './pages/lms/lms-group/detail';
import { LmsNotificationDetail } from './pages/lms/lms-notification/detail';
import { LmsNotificationPublish } from './pages/lms/lms-notification/publish';
import { BitmapManager } from './util/useBitmap';
import { EmailDesignerPage } from './pages/email-designer';
import { IngredientDetailPage } from './pages/restaurant/ingredients/detail';
import { FoodDetailPage } from './pages/restaurant/foods/detail';
import { Frame, FrameTest } from './frame';
import { RestComponentListPage } from './pages/restaurant-components/list';
import { RestComponentDetailPage } from './pages/restaurant-components/detail';
import { KitchenPage } from './pages/restaurant/kitchen';
import { BulkRecipeManagerPage } from './pages/restaurant/bulk-recipe-manager';
import { DeliveryLocationListPage } from './pages/delivery-location/list';
import { DeliveryLocationDetailPage } from './pages/delivery-location/detail';
import AllOrdersPage from './pages/sales-order/AllOrdersPage';
import { PackingListPage } from './pages/packing-list';
import { SalesWorkInventoryStatusListPage } from './pages/sales-system/work-statuses/list';
import { SalesWorkInventoryStatusDetailPage } from './pages/sales-system/work-statuses/detail';
import { AdminSalesWorks } from './pages/sales-system/work-lists/admin-list';
import { WarehouseKeeperSalesWorks } from './pages/sales-system/work-lists/warehouse-keeper-list';
import { ShipmentDeliveryAgentListPage } from './pages/shipping/delivery-agent/list';
import { ShipmentDeliveryAgentDetailPage } from './pages/shipping/delivery-agent/detail';
import { CouponPromotionListPage } from './pages/coupon-promotion/list';
import { CouponPromotionDetailPage } from './pages/coupon-promotion/detail';
import { PrinterListPage } from './pages/printer/list';
import { PrinterDetailPage } from './pages/printer/detail';
import { FoodStockValuePage } from './pages/restaurant/food-stock';
import { PrintTest } from './util/print-test';
import { PrintEventDesignerPage } from './pages/print-event-designer';
import { SearchTelPage } from './pages/sales-order/search-tel-page';
import { DeliveryZoneListPage } from './pages/delivery-zone/list';
import { DeliveryZoneDetailPage } from './pages/delivery-zone/detail';
import { DeliveryManagerPosKotPage, DeliveryManagerPosOrderPage } from './pages/restaurant/delivery';
import { DeliveryCityListPage } from './pages/delivery-city/list';
import { DeliveryCityDetailPage } from './pages/delivery-city/detail';
import { isAbshar, isDeluxxe, isRivas } from './app/app-util';
import DashboardWelcomePage from './pages/message-pages/DashboardWelcomePage';
import { InventoryBatchNoListPage } from './pages/inventory-batching/list';
import { InventoryBatchNoDetailPage } from './pages/inventory-batching/detail';
import { RestDashboard } from './pages/dashboard-rest';
import EscPosPrintServerPage from './pages/esc-pos-print-server/EscPosPrintServerPage';
import TerminalMonitoringPage from './pages/monitoring/TerminalMonitoringPage';
import { RestBusinessSummaryPage } from './pages/rest-business-summary/page';
import { makeObservable } from './util/makeObservable';
import { ImperativeRender } from './components/imperative-render';
import { FnbEventSalonListPage } from './pages/fnb-event/salon/list';
import { FnbEventSalonDetailPage } from './pages/fnb-event/salon/detail';
import { FnbEventMenuListPage } from './pages/fnb-event/menu/list';
import { FnbEventMenuDetailPage } from './pages/fnb-event/menu/detail';
import { FnbEventServiceListPage } from './pages/fnb-event/service/list';
import { FnbEventServiceDetailPage } from './pages/fnb-event/service/detail';
import { FnbEventItemPage } from './pages/fnb-event/event/item-page';
import { MarkupTemplatesPage } from './markup-template-engine/pages/templates';
import { loadAppData } from './app/data';
import { CogsCalculator } from './pages/cogs-calculator';


const FailedPrintTaskItem = ({ task, index, thermalPrinter }) => {

    return (
        <div style={{
            transition: '250ms', height: undefined, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ height: undefined, display: 'flex', flex: 1, alignItems: 'center', padding: '15px' }}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>Failed Task #{index + 1} at {Util.getDate(task.date)}</p>
                    <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', marginBottom: '0rem' }}>ID: {task.id}</p>

                    {/* <div style={{display: 'flex', alignItems: 'flex-end', marginBottom: '0.25rem'}}>
                        <h2>AED {stockFlow.amountPaid.toFixed(2)}</h2>
                        <p style={{marginLeft: '0.25rem', paddingBottom: '0.25rem'}}>(Amount Paid Already)</p>
                    </div>

                    <p style={{paddingBottom: '0.25rem'}}>AED {stockFlow.amount.toFixed(2)} (Total {stockFlowType} Amount)</p>

                    <p style={{color: 'red', fontSize: 14,}}>AED {(stockFlow.amount - stockFlow.amountPaid).toFixed(2)} left</p> */}
                </div>
                {/* <p style={{fontSize: 12, opacity: 0.65, marginRight: '1rem'}}>{Util.getDate(stockFlow.initiationDate)}</p> */}
                {/* <Button className="bx--tooltip--hidden" onClick={() => {}} disabled={task.loading} kind={"secondary"} renderIcon={DocumentPdf16} hasIconOnly /> */}
                <FailedTaskPosReceiptDownload posSessionSnapshot={task.posSessionSnapshot} />
                <div style={{ width: '0.5rem' }} />
                <Button onClick={() => new EmailSender().sendSalesReceipt(task.posSessionSnapshot.lastStockFlowRecordId)} kind={"secondary"} renderIcon={Email16}>Email</Button>
                <div style={{ width: '0.5rem' }} />
                <Button onClick={() => thermalPrinter.retryTask(task.id)} disabled={task.loading} renderIcon={CheckmarkOutline16}>Retry</Button>
                <div style={{ width: '0.5rem' }} />
                <Button onClick={() => UIUtil.confirm(() => thermalPrinter.cancelTask(task.id))} disabled={task.loading} kind={"danger"} renderIcon={ErrorFilled16}>Remove</Button>
            </div>
        </div>
    )
}

const ThemeIcon = ({ selected, theme, onClick }) => (
    <div onClick={onClick} className={(selected ? "theme-icon-selected" : "theme-icon") + " " + theme.getName() + "-theme-btn"} style={{ width: 30, height: 30, background: theme.getColor(), borderRadius: 5 }}>
    </div>
)


export const isMobileScreen = window.matchMedia("(max-width: 767px)").matches
// window.addEventListener('resize', () => isMobileScreen = window.matchMedia("(max-width: 767px)").matches);

export const MainApp = {
    instance: undefined
}

export const globalStore = makeObservable();



class App extends Page {

    /**
     * @type {ThermalPrinter}
     */
    thermalPrinter = new ThermalPrinter();

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loggedIn: isLoggedIn(),
            terminal: getTerminal(),

            openMenu: false,
            openNotifications: false,
            openProfileCard: false,

            showScanBarcodeDialog: false,
            showSetTerminalDialog: false,
            showOpenCashDrawerDialog: false,

            posPageId: Util.newTempId(),

            inFullScreen: false,

            printerConnected: false,
            printerLoading: false,
            failedPrintTasks: [],
            showFailedPrintTasksDialog: false,

            selectedTheme: getDefTheme()
        }

        this.rootRef = createRef();

        MainApp.instance = this;
    }

    componentDidMount() {
        //this.logout()
        super.componentDidMount();
        if (this.state.loggedIn) {
            SocketSession.open();
            loadAppData();
        }

        this.thermalPrinterStatusListener = (status => {
            switch (status) {
                case THERMAL_PRINTER_STATUS_DISCONNECTED:
                    this.setState({ printerConnected: false, printerLoading: false })
                    break;
                case THERMAL_PRINTER_STATUS_CONNECTED:
                    this.setState({ printerConnected: true, printerLoading: false })
                    break;
                case THERMAL_PRINTER_STATUS_LOADING:
                    this.setState({ printerLoading: true })
                    break;
            }
        }).bind(this);

        this.thermalPrinterFailedTasksListener = (failedTasks => this.setState({ failedPrintTasks: failedTasks })).bind(this);

        this.thermalPrinter.registerListener(this.thermalPrinterStatusListener);
        this.thermalPrinter.registerFailedTasksListener(this.thermalPrinterFailedTasksListener);
        this.thermalPrinter.loadConfig();
    }

    componentWillUnmount() {
        this.thermalPrinter.unregisterListener(this.thermalPrinterStatusListener);
        this.thermalPrinter.unregisterFailedTasksListener(this.thermalPrinterFailedTasksListener);
    }

    renderRoutes() {
        const accountRole = getAccountRole();

        const isNonRestSupervisor = accountRole == ACCOUNT_TYPE_SUPERVISOR && !hasCapabilitySupport("restaurant")

        let routes = [];

        const crudRoute = (path, list, detail) => {
            routes.push(<Route exact path={`/${path}`} component={list} />);
            routes.push(<Route exact path={`/${path}/:itemId`} component={detail} />);
        }

        const pages = {
            customerPage: () => {
                routes.push(<Route exact path="/customers" component={CustomerListPage} />);
                routes.push(<Route exact path="/customers/:itemId" component={CustomerDetailPage} />);
            },
            contactPage: () => {
                routes.push(<Route exact path="/contacts" component={ContactListPage} />);
                routes.push(<Route exact path="/contacts/:itemId" component={ContactDetailPage} />);
            },
            realEstateProjectPage: () => crudRoute('real-estate-project', RealEstateProjectListPage, RealEstateProjectDetailPage),
            realEstatePropertyPage: () => crudRoute('real-estate-property', RealEstatePropertyListPage, RealEstatePropertyDetailPage),

            leadPage: () => {
                routes.push(<Route exact path="/lead/:itemId" component={LeadFlowDetail} />);
                routes.push(<Route exact path="/my-leads" component={MyLeadFlowList} />);
                //crudRoute("leads", LeadFlowList, LeadFlowDetail)
            },

            cms: () => {
                crudRoute("page-heads", PageHeadListPage, PageHeadDetailPage)
                crudRoute("gallery-posts", GalleryPostsListPage, GalleryPostsDetailPage)
                crudRoute("customer-testimonies", TestimonyPostsListPage, TestimonyPostsDetailPage)

                routes.push(<Route exact path="/dynamic-content" component={DynamicContentPage} />);

                routes.push(<Route exact path="/slides" component={SlidesListPage} />);
                routes.push(<Route exact path="/slides/:itemId" component={SlidesDetailPage} />);

                routes.push(<Route exact path="/online-store-custom-pages" component={CustomPagesListPage} />);
                routes.push(<Route exact path="/online-store-custom-pages/:itemId" component={CustomPagesDetailPage} />);

                routes.push(<Route exact path="/private-online-store-custom-pages" component={PrivateCustomPagesListPage} />);
                routes.push(<Route exact path="/private-online-store-custom-pages/:itemId" component={PrivateCustomPagesDetailPage} />);

                routes.push(<Route exact path="/online-store-payment-methods" component={OnlineStorePaymentMethodsListPage} />);
                routes.push(<Route exact path="/online-store-payment-methods/:itemId" component={OnlineStorePaymentMethodsDetailPage} />);

                routes.push(<Route exact path="/newsletter-subscriptions" component={NewsletterSubscriptionListPage} />);

                routes.push(<Route exact path="/blog-posts" component={BlogPostsListPage} />);
                routes.push(<Route exact path="/blog-posts/:itemId" component={BlogPostsDetailPage} />);

                routes.push(<Route exact path="/blog-categories" component={BlogCategoriesListPage} />);
                routes.push(<Route exact path="/blog-categories/:itemId" component={BlogCategoriesDetailPage} />);
            },

            product: () => {
                crudRoute("product-targets", ProductTargetListPage, ProductTargetDetailPage)

                routes.push(<Route exact path="/product-labels" component={ProductLabelsPage} />);
                routes.push(<Route exact path="/bundle-labels" component={ProductBundleLabelsPage} />);

                routes.push(routeReport("ProductList"))
                routes.push(<Route exact path="/products" component={ProductListPage} />);
                routes.push(<Route exact path="/products/:itemId" component={ProductDetailPage} />);

                routes.push(<Route exact path="/product-groups" component={ProductGroupListPage} />);
                routes.push(<Route exact path="/product-groups/:itemId" component={ProductGroupDetailPage} />);

                routes.push(<Route exact path="/product-attribute-packs" component={ProductAttributesListPage} />);
                routes.push(<Route exact path="/product-attribute-packs/:itemId" component={ProductAttributesDetailPage} />);

                routes.push(<Route exact path="/product-bundles" component={ProductBundleListPage} />);
                routes.push(<Route exact path="/product-bundles/:itemId" component={ProductBundleDetailPage} />);

                routes.push(<Route exact path="/product-brands" component={ProductBrandListPage} />);
                routes.push(<Route exact path="/product-brands/:itemId" component={ProductBrandDetailPage} />);

                routes.push(<Route exact path="/product-classifications" component={ProductClassificationsListPage} />);
                routes.push(<Route exact path="/product-classifications/:itemId" component={ProductClassificationsDetailPage} />);

                routes.push(<Route exact path="/product-departments" component={ProductDepartmentListPage} />);
                routes.push(<Route exact path="/product-departments/:itemId" component={ProductDepartmentDetailPage} />);

                routes.push(<Route exact path="/product-categories" component={ProductCategoryListPage} />);
                routes.push(<Route exact path="/product-categories/:itemId" component={ProductCategoryDetailPage} />);

                routes.push(<Route exact path="/product-subcategories" component={ProductSubcategoryListPage} />);
                routes.push(<Route exact path="/product-subcategories/:itemId" component={ProductSubcategoryDetailPage} />);

                routes.push(<Route exact path="/low-stock-product-report" component={LowStockProductReport} />);

                // routes.push(<Route exact path="/packing-list" component={PackingListPage} />);
                routes.push(routeReport("PackingList"))


                routes.push(<Route exact path="/inventory-batch-nos" component={InventoryBatchNoListPage} />);
                routes.push(<Route exact path="/inventory-batch-nos/:itemId" component={InventoryBatchNoDetailPage} />);
            },

            $fnbEvents() {
                if (!hasCapabilitySupport("fnbEvent")) {
                    return;
                }

                if (accountRole !== ACCOUNT_TYPE_ADMINISTRATION) {
                    return;
                }

                crudRoute(getObjectTypeUrl(OBJECT_TYPE_FNB_EVENT_SALON).substring(1), FnbEventSalonListPage, FnbEventSalonDetailPage)
                crudRoute(getObjectTypeUrl(OBJECT_TYPE_FNB_EVENT_MENU).substring(1), FnbEventMenuListPage, FnbEventMenuDetailPage)
                crudRoute(getObjectTypeUrl(OBJECT_TYPE_FNB_EVENT_SERVICE).substring(1), FnbEventServiceListPage, FnbEventServiceDetailPage)


                routes.push(routeReport("FnbEventList"))
                routes.push(<Route exact path="/fnb-event/:eventId" component={FnbEventItemPage} />);
            },

            $invRequests() {
                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                    routes.push(routeReport("V1PoList"))

                    routes.push(routeReport("InvRequests"))
                    routes.push(routeReport("ActiveInvRequests"))
                    routes.push(routeReport("AllInvRequests"))
                }
            },

            $cargo() {
                if (!hasCapabilitySupport("cargo")) {
                    return;
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                    routes.push(routeReport("CargoStockReport"));
                    // routes.push(routeReport("CargoLocationReport"));
                    routes.push(routeReport("CargoReceiptNoteList"));
                    routes.push(routeReport("CargoIssueForTransferList"));
                    routes.push(routeReport("CargoReceiptFromTransferList"));
                    routes.push(routeReport("CargoInventory"));
                    routes.push(routeReport("ItemConditionNamesList"));
                }
            }
        }



        pages.$fnbEvents();
        pages.$invRequests();
        pages.$cargo();


        //Reports -- START
        {
            routes.push(<Route exact path="/reporting-dashboard" component={ReportingDashboard} />);

            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                routes.push(routeReport("SystemLogsReport"))
                routes.push(routeReport("StockValuationByLocReport"));
                routes.push(routeReport("StockValuationReport"));
            }

            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_ACCOUNTANT || accountRole == ACCOUNT_TYPE_SALES) {
                routes.push(routeReport("SalesReport"))
                routes.push(routeReport("SalesBySupplier"))
                routes.push(routeReport("SalesByItem"))
            }

            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || isNonRestSupervisor || accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
                // routes.push(routeReport("FreeSalesReport"))
                routes.push(routeReport("LedgerReport"))
                routes.push(routeReport("V2LedgerReport"))
                routes.push(routeReport("AccountingEntitiesReport"))

                routes.push(routeReport("ProductMergeReport"))
            }


            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_PRODUCT_MANAGER) {
                routes.push(routeReport("StockQtyByLocReport"));
            }

            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                routes.push(routeReport("Productions"));
            }
        }
        //Reports -- END

        if (accountRole == ACCOUNT_TYPE_CASHIER) {
            if (hasCapabilitySupport("CRV")) {
                routes.push(<Route exact path="/cash-receipt-vouchers" component={CRVPage} />);
                routes.push(<Route exact path="/crv-creator" component={CRVCreatorPage} />);
                routes.push(<Route exact path="/crv/:itemId" component={CRVItemPage} />);

                // routes.push(<Route exact path="/crv-report-legacy" component={CRVReportPage} />);
                routes.push(<Route exact path="/crv-report" key="crv-report" render={(props) => <ReportPage {...props} reportName={"CRVSummary"} />} />);
                routes.push(<Route exact path="/crv-report-detail" key="crv-report-detail" render={(props) => <ReportPage {...props} reportName={"CRVDetail"} />} />);
            }
        }


        if (hasCapabilitySupport("restaurant")) {
            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_ACCOUNTANT || accountRole == ACCOUNT_TYPE_CASHIER) {
                routes.push(routeReport("PaymentMethodReport"));
                routes.push(routeReport("PaymentMethodDetail"));
            }

            if (accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_CASHIER) {
                routes.push(<Route exact path="/receipt-voucher-creator" key="receipt-voucher-creator" render={(props) => <TransactionCreatorPage {...props} receiptVoucher />} />);
            }
            if (accountRole == ACCOUNT_TYPE_CASHIER) {
                routes.push(<Route exact path="/transaction/:transactionId" component={TransactionItemPage} />);
            }
        } else {
            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
                routes.push(routeReport("PaymentMethodReport"));
                routes.push(routeReport("PaymentMethodDetail"));
            }
        }




        if (hasCapabilitySupport("activities")) {
            routes.push(<Route exact path="/calendar" component={CalendarPage} />);
            routes.push(<Route exact path="/board-view" component={BoardViewPage} />);

            routes.push(<Route exact path="/activities/:itemId" component={ActivityDetailPage} />);

            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                crudRoute("activity-blueprints", ActivityBlueprintListPage, ActivityBlueprintDetailPage)
                crudRoute("activity-statuses", ActivityStatusesListPage, ActivityStatusesDetailPage)
                crudRoute("activity-member-tags", ActivityMemberTagsListPage, ActivityMemberTagsDetailPage)
            }
        }


        if (accountRole == ACCOUNT_TYPE_SECOND_DISPLAY) {
            routes.push(<Route exact path="/" component={WelcomePage} />);
            routes.push(<Route exact path="/monitor" component={TerminalMonitoringPage} />)
        } else if (accountRole == ACCOUNT_TYPE_REAL_ESTATE_AGENT) {
            routes.push(<Route exact path="/" component={WelcomePage} />);

            pages.customerPage();
            pages.realEstateProjectPage();
            pages.realEstatePropertyPage();
            pages.contactPage();

            pages.leadPage();

            routes.push(<Route exact path="/blog-posts" component={BlogPostsListPage} />);
            routes.push(<Route exact path="/blog-posts/:itemId" component={BlogPostsDetailPage} />);

            routes.push(<Route exact path="/blog-categories" component={BlogCategoriesListPage} />);
            routes.push(<Route exact path="/blog-categories/:itemId" component={BlogCategoriesDetailPage} />);

        } else if (accountRole == ACCOUNT_TYPE_CMS_MANAGER) {
            routes.push(<Route exact path="/" component={WelcomePage} />);
            if (hasCapabilitySupport("onlineStore")) {
                pages.cms();
            }

        } else if (accountRole == ACCOUNT_TYPE_SHIPPING_PORT_STAFF) {
            routes.push(<Route exact path="/" component={StaffPortShippingPage} />);
            routes.push(<Route exact path="/shipment/:shipmentId" component={PortShipmentItemPage} />);
        } else if (accountRole == ACCOUNT_TYPE_TYPE_KITCHEN) {
            // routes.push(<Route exact path="/" component={KitchenPage} />);
            routes.push(<Route exact path="/" render={(props) => <KitchenPage {...props} key={this.state.posPageId} mainApp={this} />} />);
        } else if (accountRole == ACCOUNT_TYPE_REST_DELIVERY_MANAGER) {
            // routes.push(<Route exact path="/" component={WelcomePage} />);
            routes.push(<Route exact path="/" render={(props) => <DeliveryManagerPosOrderPage {...props} key={this.state.posPageId} mainApp={this} />} />);
            routes.push(<Route exact path="/kitchen" render={(props) => <DeliveryManagerPosKotPage {...props} key={this.state.posPageId + "kot"} mainApp={this} />} />);

            routes.push(<Route exact path="/tel-dir" component={SearchTelPage} />);
            routes.push(<Route exact path="/deliverables" render={props => <AllOrdersPage {...props} key={"all-order-items-page"} mainApp={this} />} />);

            routes.push(<Route exact path="/mobile-units" component={MobileUnitListPage} />);
            routes.push(<Route exact path="/mobile-units/:itemId" component={MobileUnitDetailPage} />);

            crudRoute('delivery-locations', DeliveryLocationListPage, DeliveryLocationDetailPage);
            crudRoute('delivery-zones', DeliveryZoneListPage, DeliveryZoneDetailPage);
            crudRoute('delivery-cities', DeliveryCityListPage, DeliveryCityDetailPage);
        } else if (accountRole == ACCOUNT_TYPE_SALES_ADMIN) {
            routes.push(<Route exact path="/" component={WelcomePage} />);

            routes.push(<Route exact path="/sales" component={SalesPage} />);
            routes.push(<Route exact path="/sale-creator" component={SaleCreatorPage} />);
            routes.push(<Route exact path="/sale/:saleId" component={SaleItemPage} />);

            routes.push(<Route exact path="/stock-flow/:stockFlowId" component={StockFlowItemPage} />);
            routes.push(<Route exact path="/stock-flows" component={StockFlowsPage} />);
            routes.push(<Route exact path="/stock-flow-creator" component={StockFlowCreatorPage} />);
            routes.push(routeReport("InvAdjustmentList"));

            routes.push(<Route exact path="/sales-carts" component={SalesCartListPage} />)
            routes.push(<Route exact path="/sales-carts/:itemId" component={SalesCartPage} />);

            routes.push(<Route exact path="/sales-cart-types" component={SalesCartTypesListPage} />);
            routes.push(<Route exact path="/sales-cart-types/:itemId" component={SalesCartTypesDetailPage} />);

            crudRoute('sales-work-inventory-statuses', SalesWorkInventoryStatusListPage, SalesWorkInventoryStatusDetailPage);
            routes.push(<Route exact path="/sales-works" component={AdminSalesWorks} />)
            routes.push(routeReport("ProductList"))
            routes.push(<Route exact path="/products" component={ProductListPage} />);
            routes.push(<Route exact path="/products/:itemId" component={ProductDetailPage} />);

            routes.push(routeReport("StockReport"))
        } else if (accountRole == ACCOUNT_TYPE_TUTOR) {
            routes.push(<Route exact path="/" component={TutorWelcomePage} />);
            routes.push(<Route exact path="/profile" component={TutorProfilePage} />);
            routes.push(<Route exact path="/my-courses" component={MyCoursesPage} />);
            routes.push(<Route exact path="/my-courses/:itemId" component={MyCourseDetailPage} />);

            pages.leadPage();

            routes.push(<Route exact path="/payouts" component={PayoutsPage} />);
            routes.push(<Route exact path="/payout/:payoutId" component={PayoutItemPage} />);


            routes.push(routeReport("MyLmsNotificationList"));
            routes.push(<Route exact path="/lms-notifications/:id" component={LmsNotificationDetail} />);
            routes.push(<Route exact path="/lms-notifications-publish" component={LmsNotificationPublish} />);


        } else if (accountRole == ACCOUNT_TYPE_STUDENT) {
            routes.push(<Route exact path="/" component={StudentWelcomePage} />);
            routes.push(<Route exact path="/profile" component={StudentProfilePage} />);
            routes.push(<Route exact path="/my-courses" component={MyCoursesPage} />);
            routes.push(<Route exact path="/my-courses/:itemId" component={MyCourseDetailPage} />);

            routes.push(<Route exact path="/invoices" component={ServiceSalesPage} />);
            routes.push(<Route exact path="/invoice/:serviceSaleId" component={ServiceSaleItemPage} />);
            routes.push(<Route exact path="/invoice-payment-result/:serviceSaleId" component={StripePaymentMessagePage} />);
        } else if (accountRole == ACCOUNT_TYPE_GUARDIAN) {
            routes.push(<Route exact path="/" component={GuardianWelcomePage} />);
            routes.push(<Route exact path="/profile" component={GuardianProfilePage} />);
            routes.push(<Route exact path="/my-students" component={MyStudentsPage} />);

            routes.push(<Route exact path="/invoices" component={ServiceSalesPage} />);
            routes.push(<Route exact path="/invoice/:serviceSaleId" component={ServiceSaleItemPage} />);
            routes.push(<Route exact path="/invoice-payment-result/:serviceSaleId" component={StripePaymentMessagePage} />);
        } else if (accountRole == ACCOUNT_TYPE_WAREHOUSE_KEEPER) {
            routes.push(<Route exact path="/" component={WelcomePage} />);
            routes.push(<Route exact path="/sales-works" component={WarehouseKeeperSalesWorks} />)
            routes.push(routeReport("PackingList"))

            routes.push(routeReport("StockReport"));
            routes.push(routeReport("ProductStockReport"));
            routes.push(routeReport("SerialNoReport"))
            routes.push(routeReport("StockQtyByLocReport"));
            routes.push(routeReport("StockValuationByLocReport"));
            routes.push(routeReport("StockValuationReport"));
            //stockr

        } else if (accountRole == ACCOUNT_TYPE_PRODUCT_MANAGER) {
            routes.push(<Route exact path="/" component={WelcomePage} />);
            pages.product();

            routes.push(<Route exact path="/templates" component={TemplatesListPage} />)
            routes.push(<Route exact path="/template-designer/:templateId" component={LabelDesignerPage} />)

            if (hasCapabilitySupport("continuousStockType")) {
                routes.push(<Route exact path="/measurement-types" component={MeasurementTypeListPage} />);
                routes.push(<Route exact path="/measurement-types/:itemId" component={MeasurementTypeDetailPage} />);
            }

            routes.push(<Route exact path="/stock-flow/:stockFlowId" component={StockFlowItemPage} />);
            routes.push(<Route exact path="/stock-flows" component={StockFlowsPage} />);
            routes.push(<Route exact path="/stock-flow-creator" component={StockFlowCreatorPage} />);
            routes.push(routeReport("InvAdjustmentList"));

            routes.push(<Route exact path="/stock-flows-purchases" render={(props) => <StockFlowsPage {...props} key="purchase-stock-flow-list-filter-force" forcePurchaseFilter />} />);
            routes.push(<Route exact path="/stock-flows-sales" render={(props) => <StockFlowsPage {...props} key="sale-stock-flow-list-filter-force" forceSalesFilter />} />);

            routes.push(<Route exact path="/suppliers" component={SupplierListPage} />);
            routes.push(<Route exact path="/suppliers/:itemId" component={SupplierDetailPage} />);


            if (hasCapabilitySupport("thirdPartyPos")) {
                crudRoute("stock-requests", StockRequestListPage, StockRequestDetailPage);
            }


            // routes.push(<Route exact path="/stock-report" component={StockReportPage} />);
            routes.push(routeReport("StockReport"))
            routes.push(routeReport("ProductStockReport"))
            routes.push(routeReport("SerialNoReport"))
            // routes.push(<Route exact path="/product-stock-report" component={ProductStockReportPage} />);
            // routes.push(<Route exact path="/product-stock-report/:loadDefaultProductId" render={props => <ProductStockReportPage {...props} loadDefaultProduct />} />);
            // routes.push(<Route exact path="/purchases-report" component={PurchasesReportPage} />);
            routes.push(routeReport("PurchaseReport"))


            routes.push(<Route exact path="/import-data" component={ImportDataPage} />);
            routes.push(<Route exact path="/import-data/:objectType" component={ImportDataTypePage} />);


            routes.push(<Route exact path="/loyalty-cards" component={LoyaltyCardListPage} />);
            routes.push(<Route exact path="/loyalty-cards/:itemId" component={LoyaltyCardDetailPage} />);
            routes.push(<Route exact path="/loyalty-cards-issue/:itemId" component={LoyaltyCardIssueDetailPage} />);
            routes.push(<Route exact path="/coupons" component={CouponListPage} />);
            routes.push(<Route exact path="/coupons/:itemId" component={CouponDetailPage} />);
            crudRoute("coupon-promotions", CouponPromotionListPage, CouponPromotionDetailPage)

            routes.push(<Route exact path="/discounts" component={DiscountListPage} />);
            routes.push(<Route exact path="/discounts/:itemId" component={DiscountDetailPage} />);


            routes.push(<Route exact path="/group-price-overrides/:itemId" component={GroupPriceOverrideDetailPage} />);
            routes.push(<Route exact path="/price-overrides/:itemId" component={PriceOverrideDetailPage} />);

        } else if (accountRole == ACCOUNT_TYPE_STORE_MASTER) {
            routes.push(<Route exact path="/" component={ThirdPartyPos} />);

            // crudRoute("my-stock-requests", MyStockRequestList, MyStockRequestDetail);
            // routes.push(<Route exact path="/new-stock-request" component={CreateStockRequest} />);
        } else if (accountRole == ACCOUNT_TYPE_TYPE_WAITER) {
            routes.push(<Route exact path="/" render={(props) => <PosPage {...props} key={this.state.posPageId} mainApp={this} />} />);
        } else {
            if (hasCapabilitySupport("thirdPartyPos")) {
                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_VENUE_MANAGER) {
                    crudRoute("venues", VenueListPage, VenueDetailPage);
                    crudRoute("stock-requests", StockRequestListPage, StockRequestDetailPage);
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                    crudRoute("invoice-documents", InvoiceDocumentListPage, InvoiceDocumentDetailPage)
                }
            }

            if (accountRole == ACCOUNT_TYPE_SUPERVISOR && hasCapabilitySupport('restaurant')) {
                routes.push(<Route exact path="/pos" render={(props) => <PosPage {...props} key={this.state.posPageId} mainApp={this} />} />);
            }

            if (hasCapabilitySupport("restaurant")) {
                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                    routes.push(<Route exact path="/business-summary" component={RestBusinessSummaryPage} />);
                }
            }

            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || isNonRestSupervisor) {
                if (hasCapabilitySupport("realEstate")) {
                    routes.push(<Route exact path="/" component={DashboardWelcomePage} />);
                    routes.push(<Route exact path="/dashboard" component={RealEstateDashboardPage} />);
                } else if (hasCapabilitySupport("lms") && hasCapabilitySupport("terminalPos")) {
                    routes.push(<Route exact path="/" component={DashboardWelcomePage} />);
                    routes.push(<Route exact path="/dashboard" component={RetailLMSDashboardPage} />);
                } else if (hasCapabilitySupport("restaurant")) {
                    routes.push(<Route exact path="/" component={DashboardWelcomePage} />);
                    routes.push(<Route exact path="/dashboard" component={RestDashboard} />);
                } else {
                    if (isDeluxxe() || hasCapabilitySupport("cargo")) {
                        routes.push(<Route exact path="/" component={WelcomePage} />);
                    } else {
                        routes.push(<Route exact path="/" component={DashboardWelcomePage} />);
                        routes.push(<Route exact path="/dashboard" component={DashboardPage} />);
                        // routes.push(<Route exact path="/" component={DashboardPage} />);
                    }
                }

                if (hasCapabilitySupport("terminalPos")) {
                    routes.push(<Route exact path="/pos" render={(props) => <PosPage {...props} key={this.state.posPageId} mainApp={this} />} />);
                } else if (hasCapabilitySupport("thirdPartyPos")) {
                    routes.push(<Route exact path="/pos" component={NoPosPage} />);
                }
            } else if (accountRole == ACCOUNT_TYPE_CASHIER || accountRole == ACCOUNT_TYPE_SALES) {

                if (hasCapabilitySupport("terminalPos")) {
                    routes.push(<Route exact path="/" render={(props) => <PosPage {...props} key={this.state.posPageId} mainApp={this} />} />);
                } else {
                    routes.push(<Route exact path="/" component={WelcomePage} />);
                }
            } else {
                routes.push(<Route exact path="/" component={WelcomePage} />);
            }

            //if (accountRole == ACCOUNT_TYPE_CASHIER) {-
            if (hasTerminal() && !hasCapabilitySupport("restaurant")) {
                routes.push(<Route exact path="/sales-return" component={CashierSalesReturnPage} />)
                // routes.push(<Route exact path="/returns-and-exchanges" component={SalesReturnAndExchangesPage} />)
            }
            //}




            if (hasCapabilitySupport("onlineLearning")) {
                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_LEARNING_MANAGER) {
                    crudRoute("lectures", LectureListPage, LectureDetailPage)
                    crudRoute("lecture-videos", LectureVideoListPage, LectureVideoDetailPage)
                    crudRoute("lecture-quizzes", LectureQuizListPage, LectureQuizDetailPage)
                    routes.push(routeReport("OnlineLearnersList"))
                }
            }




            if (accountRole != ACCOUNT_TYPE_CASHIER) {
                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_SALES || accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
                    routes.push(<Route exact path="/sales" component={SalesPage} />);
                    routes.push(<Route exact path="/sale-creator" component={SaleCreatorPage} />);
                    routes.push(<Route exact path="/sale/:saleId" component={SaleItemPage} />);


                    routes.push(<Route exact path="/service-sales" component={ServiceSalesPage} />);
                    routes.push(<Route exact path="/service-sale-creator" component={ServiceSaleCreatorPage} />);
                    routes.push(<Route exact path="/service-sale/:serviceSaleId" component={ServiceSaleItemPage} />);

                    routes.push(<Route exact path="/sales-summary-report" key="sales-summary-report" component={SalesSummaryReport} />);
                    routes.push(<Route exact path="/sales-report" key="sales-report" component={SalesReportPage} />);
                    if (hasCapabilitySupport("thirdPartyPos")) {
                        routes.push(<Route exact path="/venue-sales-report" key="venue-sales-report" component={VenueSalesReportPage} />);
                    }

                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_SALES) {


                    //agala
                    if (hasCapabilitySupport("salesSystem")) {
                        routes.push(<Route exact path="/sales-carts" component={SalesCartListPage} />)
                        routes.push(<Route exact path="/sales-carts/:itemId" component={SalesCartPage} />);

                        routes.push(<Route exact path="/sales-cart-types" component={SalesCartTypesListPage} />);
                        routes.push(<Route exact path="/sales-cart-types/:itemId" component={SalesCartTypesDetailPage} />);

                        if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                            crudRoute('sales-work-inventory-statuses', SalesWorkInventoryStatusListPage, SalesWorkInventoryStatusDetailPage);
                            routes.push(<Route exact path="/sales-works" component={AdminSalesWorks} />)
                        }
                    }



                    crudRoute("services", ServiceListPage, ServiceDetailPage)
                    crudRoute("service-packages", ServicePackageListPage, ServicePackageDetailPage)
                    crudRoute("service-departments", ServiceDepartmentListPage, ServiceDepartmentDetailPage)
                    crudRoute("service-categories", ServiceCategoryListPage, ServiceCategoryDetailPage)
                    crudRoute("service-subcategories", ServiceSubcategoryListPage, ServiceSubcategoryDetailPage)



                    // routes.push(<Route exact path="/B1ERKy74jbj0GRzi" component={LabelDesignerPage} />);
                    routes.push(<Route exact path="/templates" component={TemplatesListPage} />)
                    routes.push(<Route exact path="/template-designer/:templateId" component={LabelDesignerPage} />)

                    routes.push(<Route exact path="/product-labels" component={ProductLabelsPage} />);
                    routes.push(<Route exact path="/bundle-labels" component={ProductBundleLabelsPage} />);

                    pages.product()

                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
                    routes.push(<Route exact path="/transaction/:transactionId" component={TransactionItemPage} />);

                    if (hasCapabilitySupport("terminalPos")) {
                        routes.push(<Route exact path="/pos-sales-summary-report" key="pos-sales-summary-report" render={props => <SalesSummaryReport {...props} posMode />} />);
                        routes.push(<Route exact path="/pos-sales-report" key="pos-sales-report" render={props => <SalesReportPage {...props} posMode />} />);
                        routes.push(<Route exact path="/work-period-report" key="work-period-report" component={POSSessionReportPage} />);
                        routes.push(<Route exact path="/cash-closed-report" key="cash-closed-report" render={props => <POSSessionReportPage {...props} cashClosedMode />} />);
                        routes.push(<Route exact path="/pos-product-performance-report" key="pos-sales-report" render={props => <ProductPerformanceReport {...props} posMode />} />);
                    }

                    routes.push(<Route exact path="/pos-session/:posSessionId" component={PosSessionItemPage} />);
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || isNonRestSupervisor || accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
                    routes.push(routeReport("SupplierBalanceReport"))
                    routes.push(routeReport("CustomerBalanceReport"))

                    routes.push(routeReport("SupplierOutstandingReport"))
                    routes.push(routeReport("CustomerOutstandingReport"))




                    routes.push(<Route exact path="/statement-of-accounts" component={StatementOfAccountsPage} />);
                    routes.push(<Route exact path="/outstanding-report" component={OutstandingReportPage} />);
                    if (hasCapabilitySupport("lms")) {
                        routes.push(<Route exact path="/outstanding-student-report" component={OutstandingStudentReportPage} />);
                    }

                    routes.push(<Route exact path="/account-ledger-groups" component={AccountLedgerGroupsListPage} />);
                    routes.push(<Route exact path="/account-ledger-groups/:itemId" component={AccountLedgerGroupsDetailPage} />);

                    routes.push(<Route exact path="/account-ledgers" component={AccountLedgersListPage} />);
                    routes.push(<Route exact path="/account-ledgers/:itemId" component={AccountLedgersDetailPage} />);

                    routes.push(<Route exact path="/vendors" component={VendorListPage} />);
                    routes.push(<Route exact path="/vendors/:itemId" component={VendorDetailPage} />);

                    routes.push(<Route exact path="/account-tree" component={AccountTreePage} />);
                    crudRoute("predefined-classes", PredefinedClassesListPage, PredefinedClassesDetailPage)

                    // routes.push(<Route exact path="/legacy-balance-sheet" key="balance-sheet" render={(props) => <StatementReportPage {...props} statementType={ACCOUNTING_STATEMENT_BALANCE_SHEET} />} />);
                    // routes.push(<Route exact path="/legacy-profit-and-loss-statement" key="income-statement" render={(props) => <StatementReportPage {...props} statementType={ACCOUNTING_STATEMENT_INCOME_SHEET} />} />);
                    // routes.push(<Route exact path="/legacy-trial-balance" key="trial-balance" render={(props) => <StatementReportPage {...props} statementType={ACCOUNTING_STATEMENT_TRIAL_BALANCE} />} />);
                    routes.push(<Route exact path="/balance-sheet" key="balance-sheet" render={(props) => <ReportPage {...props} reportName={"BalanceSheet"} />} />);
                    routes.push(<Route exact path="/profit-and-loss-statement" key="income-statement" render={(props) => <ReportPage {...props} reportName={"ProfitLoss"} />} />);
                    routes.push(<Route exact path="/trial-balance" key="trial-balance" render={(props) => <ReportPage {...props} reportName={"TrialBalance"} />} />);


                    // <DropdownBtn to={getReportLink("BalanceSheet")} Icon={Notebook16}>Balance Sheet</DropdownBtn>
                    // <DropdownBtn to={getReportLink("ProfitLoss")} Icon={Money16}>Profit & Loss</DropdownBtn>
                    // <DropdownBtn to={getReportLink("TrialBalance")} Icon={Table16}>Trial Balance</DropdownBtn>

                    // return <Route exact path={path} key={"reporting-" + reportName} render={props => <ReportPage {...props} reportName={reportName} />} />


                    // routes.push(<Route exact path="/general-ledger-report" component={GeneralLedgerReportPage} />);
                    // routes.push(<Route exact path="/general-ledger-report/:accountId" component={GeneralLedgerResultPage} />);

                    // routes.push(<Route exact path="/subsidiary-ledger-report" component={SubsidiaryLedgerReportPage} />);
                    // routes.push(<Route exact path="/subsidiary-ledger-report/:accountId/:subsidiaryId/:subsidiaryType" component={SubsidiaryLedgerResultPage} />);


                    routes.push(<Route exact path="/receipts-report" component={IncomeReportPage} />);
                    routes.push(<Route exact path="/payments-report" component={ExpenseReportPage} />);
                    routes.push(<Route exact path="/net-cash-flow-report" component={ProfitReportPage} />);






                    // routes.push(<Route exact path="/sales-summary-report" key="sales-summary-report" component={SalesSummaryReport} />);

                    // routes.push(<Route exact path="/purchases-report" component={PurchasesReportPage} />);
                    routes.push(routeReport("PurchaseReport"))

                    // routes.push(<Route exact path="/sales-report" key="sales-report" component={SalesReportPage} />);
                    // if (hasCapabilitySupport("thirdPartyPos")) {
                    //     routes.push(<Route exact path="/venue-sales-report" key="venue-sales-report" component={VenueSalesReportPage} />);
                    // }

                    // routes.push(<Route exact path="/product-performance-report" key="sales-report" component={ProductPerformanceReport} />);

                    if (isStockEntityFifoEngine()) {
                        routes.push(<Route exact path="/product-performance-report" component={ProductSalesReport} />);
                        routes.push(<Route exact path="/price-performance-report" component={ProductPriceReport} />);
                        routes.push(<Route exact path="/hourly-performance-report" component={ProductTimeReport} />);
                    }

                    if (hasCapabilitySupport("lms")) {
                        routes.push(<Route exact path="/payouts" component={PayoutsPage} />);
                        routes.push(<Route exact path="/payout-creator" component={PayoutCreatorPage} />);
                        routes.push(<Route exact path="/payout/:payoutId" component={PayoutItemPage} />);
                    }


                    routes.push(<Route exact path="/bills" component={BillsPage} />);
                    routes.push(<Route exact path="/bill-creator" component={BillCreatorPage} />);
                    routes.push(<Route exact path="/bill/:billId" component={BillItemPage} />);

                    routes.push(<Route exact path="/purchases" component={PurchasesPage} />);
                    routes.push(<Route exact path="/purchase-creator" component={PurchaseCreatorPage} />);
                    routes.push(<Route exact path="/purchase/:purchaseId" component={PurchaseItemPage} />);





                    routes.push(<Route exact path="/transactions" component={TransactionsPage} />);
                    routes.push(<Route exact path="/transaction-creator" component={TransactionCreatorPage} />);


                    routes.push(<Route exact path="/receipt-voucher-creator" key="receipt-voucher-creator" render={(props) => <TransactionCreatorPage {...props} receiptVoucher />} />);
                    routes.push(<Route exact path="/payment-voucher-creator" key="payment-voucher-creator" render={(props) => <TransactionCreatorPage {...props} paymentVoucher />} />);

                    routes.push(<Route exact path="/journal-entries" component={JournalEntriesPage} />);
                    routes.push(<Route exact path="/journal-entry-creator" component={JournalEntriesCreatorPage} />);
                    routes.push(<Route exact path="/journal-entry/:journalEntryId" component={JournalEntriesItemPage} />);

                    routes.push(routeReport("CourierReceipt"));


                    routes.push(routeReport("BookClosings"))

                    routes.push(<Route exact path="/taxes" component={TaxListPage} />);
                    routes.push(<Route exact path="/taxes/:itemId" component={TaxDetailPage} />);


                    routes.push(<Route exact path="/cheques" component={ChequeListPage} />);
                    routes.push(<Route exact path="/cheques/:itemId" component={ChequeDetailPage} />);



                    // if (hasCapabilitySupport("CRV")) {
                    //     routes.push(<Route exact path="/cash-receipt-vouchers" component={CRVPage} />);
                    //     routes.push(<Route exact path="/crv-creator" component={CRVCreatorPage} />);
                    //     routes.push(<Route exact path="/crv/:itemId" component={CRVItemPage} />);

                    //     routes.push(<Route exact path="/crv-report" component={CRVReportPage} />);
                    // }
                }

                if (accountRole == ACCOUNT_TYPE_DELIVERY_MANAGER || accountRole == ACCOUNT_TYPE_SALES) {
                    routes.push(routeReport("StockReport"))
                }


                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_ACCOUNTANT || accountRole == ACCOUNT_TYPE_DELIVERY_MANAGER) {
                    if (hasCapabilitySupport("CRV")) {
                        routes.push(<Route exact path="/cash-receipt-vouchers" component={CRVPage} />);
                        routes.push(<Route exact path="/crv-creator" component={CRVCreatorPage} />);
                        routes.push(<Route exact path="/crv/:itemId" component={CRVItemPage} />);

                        routes.push(<Route exact path="/crv-report-legacy" component={CRVReportPage} />);
                        routes.push(<Route exact path="/crv-report" key="crv-report" render={(props) => <ReportPage {...props} reportName={"CRVSummary"} />} />);
                        routes.push(<Route exact path="/crv-report-detail" key="crv-report-detail" render={(props) => <ReportPage {...props} reportName={"CRVDetail"} />} />);
                    }
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_SALES) {
                    pages.customerPage();

                    routes.push(<Route exact path="/customer-groups" component={CustomerGroupListPage} />);
                    routes.push(<Route exact path="/customer-groups/:itemId" component={CustomerGroupDetailPage} />);

                    pages.contactPage();
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_DELIVERY_MANAGER || accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
                    routes.push(<Route exact path="/stock-flow/:stockFlowId" component={StockFlowItemPage} />);
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_DELIVERY_MANAGER) {
                    routes.push(<Route exact path="/stock-flows" component={StockFlowsPage} />);
                    routes.push(<Route exact path="/stock-flow-creator" component={StockFlowCreatorPage} />);
                    routes.push(routeReport("InvAdjustmentList"));

                    routes.push(<Route exact path="/stock-flows-purchases" render={(props) => <StockFlowsPage {...props} key="purchase-stock-flow-list-filter-force" forcePurchaseFilter />} />);
                    routes.push(<Route exact path="/stock-flows-sales" render={(props) => <StockFlowsPage {...props} key="sale-stock-flow-list-filter-force" forceSalesFilter />} />);

                    if (hasCapabilitySupport("mobileUnit")) {
                        routes.push(<Route exact path="/mobile-units" component={MobileUnitListPage} />);
                        routes.push(<Route exact path="/mobile-units/:itemId" component={MobileUnitDetailPage} />);
                    }

                    if (hasCapabilitySupport("orderManagementSystem")) {
                        if (hasCapabilitySupport("restaurant")) {
                            routes.push(<Route exact path="/tel-dir" component={SearchTelPage} />);
                            routes.push(<Route exact path="/deliverables" render={props => <AllOrdersPage {...props} key={"all-order-items-page"} mainApp={this} />} />);
                            routes.push(<Route exact path="/deliverables/:itemId" render={props => <DeliverableItemPage {...props} key={"order-item-page"} mainApp={this} />} />);

                            if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                                routes.push(routeReport("SalesOrderReport"))
                            }
                            // routes.push(routeReport("RestOrderList"))
                        } else {
                            routes.push(<Route exact path="/deliverables" component={DeliverablesPage} />);
                            routes.push(<Route exact path="/deliverables/:itemId" component={DeliverableItemPage} />);
                        }

                        if (!hasCapabilitySupport("onlineStore")) {
                            routes.push(<Route exact path="/ordering" component={OnlineStoreAdminView} />);
                        }




                        routes.push(<Route exact path="/sales-order-states" component={SalesOrderStateListPage} />);
                        routes.push(<Route exact path="/sales-order-states/:itemId" component={SalesOrderStateDetailPage} />);
                    }
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_DELIVERY_MANAGER) {
                    if (hasCapabilitySupport("onlineStore")) {
                        routes.push(<Route exact path="/website-admin-view" component={OnlineStoreAdminView} />);
                    }
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
                    if (hasCapabilitySupport("realEstate")) {
                        routes.push(<Route exact path="/commissions" component={CommissionsPage} />);
                        routes.push(<Route exact path="/commission-creator" component={CommissionCreatorPage} />);
                        routes.push(<Route exact path="/commission/:commissionId" component={CommissionItemPage} />);

                        routes.push(<Route exact path="/property-deals" component={PropertyDealsPage} />);
                        routes.push(<Route exact path="/property-deal/:propertyDealId" component={PropertyDealItemPage} />);
                    }



                    routes.push(routeReport("FundTransferList"))
                    routes.push(<Route exact path="/fund-transfer/:fundTransferId" component={FundTransferDetailPage} />);

                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) {
                    if (hasCapabilitySupport("orderManagementSystem")) {
                        crudRoute('delivery-locations', DeliveryLocationListPage, DeliveryLocationDetailPage);
                        crudRoute('delivery-zones', DeliveryZoneListPage, DeliveryZoneDetailPage);
                        crudRoute('delivery-cities', DeliveryCityListPage, DeliveryCityDetailPage);
                    }

                    if (hasCapabilitySupport("restaurant")) {
                        routes.push(routeReport("IngredientList"))
                        routes.push(<Route exact path="/ingredient/:itemId" component={IngredientDetailPage} />);

                        routes.push(routeReport("FoodList"))
                        routes.push(<Route exact path="/food/:itemId" component={FoodDetailPage} />);

                        crudRoute('rest-components', RestComponentListPage, RestComponentDetailPage);

                        routes.push(<Route exact path="/bulk-recipe-manager" component={BulkRecipeManagerPage} />);
                        routes.push(<Route exact path="/estimated-daily-portions" component={FoodStockValuePage} />);
                    }


                    if ((hasCapabilitySupport("shipping"))) {
                        routes.push(routeReport("ShipmentList"));
                        routes.push(<Route exact path="/shipment-job/:shipmentId" component={ShipmentItemPage} />);

                        routes.push(routeReport("ShipmentMasterList"));
                        routes.push(<Route exact path="/shipment-master/:masterId" component={ShipmentMasterDetailPage} />);

                        crudRoute('shipment-ports', ShipmentPortListPage, ShipmentPortDetailPage);
                        crudRoute('shipment-package-types', ShipmentPackageTypeListPage, ShipmentPackageTypeDetailPage);

                        crudRoute('shipment-item-types', ShipmentItemTypeListPage, ShipmentItemTypeDetailPage);
                        crudRoute('shipment-consignees', ShipmentConsigneeListPage, ShipmentConsigneeDetailPage);
                        crudRoute('shipment-delivery-agents', ShipmentDeliveryAgentListPage, ShipmentDeliveryAgentDetailPage);

                        crudRoute('shipment-loading-mode-types', ShipmentLoadingModeTypeListPage, ShipmentLoadingModeTypeDetailPage);

                        routes.push(routeReport("LoadingListReport"));
                    }

                    if (hasCapabilitySupport("cinema")) {
                        crudRoute('cinemas', CinemaListPage, CinemaDetailPage);
                        crudRoute('movies', MovieListPage, MovieDetailPage);
                        crudRoute('movie-distributors', MovieDistributorListPage, MovieDistributorDetailPage);
                        crudRoute('theater-designs', SeatingPlanListPage, SeatingPlanDetailPage);
                        crudRoute('theater-types', TheaterTypeListPage, TheaterTypeDetailPage);

                        crudRoute('movie-languages', MovieLanguageListPage, MovieLanguageDetailPage);
                        crudRoute('movie-genres', MovieGenreListPage, MovieGenreDetailPage);

                        routes.push(<Route exact path="/showtime-scheduler" component={ShowtimeScheduler} />);

                        routes.push(<Route exact path="/payouts" component={PayoutsPage} />);
                        routes.push(<Route exact path="/payout-creator" component={PayoutCreatorPage} />);
                        routes.push(<Route exact path="/payout/:payoutId" component={PayoutItemPage} />);

                        routes.push(<Route exact path="/ticket-sales" component={TicketSalesPage} />);

                        routes.push(<Route exact path="/ticket-scans" component={TicketScanActivityPage} />);
                    }


                    if (hasCapabilitySupport("lms")) {
                        crudRoute('students', StudentsListPage, StudentsDetailPage);
                        crudRoute('tutors', TutorsListPage, TutorsDetailPage);
                        crudRoute('courses', CoursesListPage, CoursesDetailPage);
                        crudRoute('guardians', GuardianListPage, GuardianDetailPage);
                        crudRoute('lms-groups', LmsGroupListPage, LmsGroupDetailPage);

                        routes.push(<Route exact path="/course-enrolments/:itemId" component={CourseEnrolmentDetailPage} />);
                        crudRoute('enrolment-requests', EnrolmentRequestList, EnrolmentRequestDetail);

                        routes.push(<Route exact path="/student-entrance-activity" component={StudentEntranceActivityPage} />);

                        routes.push(routeReport("LmsNotificationList"));
                        routes.push(<Route exact path="/lms-notifications/:id" component={LmsNotificationDetail} />);
                        routes.push(<Route exact path="/lms-notifications-publish" component={LmsNotificationPublish} />);
                    }


                    if (hasCapabilitySupport("realEstate")) {
                        crudRoute('real-estate-city', RealEstateCityListPage, RealEstateCityDetailPage);
                        crudRoute('real-estate-area', RealEstateAreaListPage, RealEstateAreaDetailPage);
                        // crudRoute('real-estate-project', RealEstateProjectListPage, RealEstateProjectDetailPage);
                        // crudRoute('real-estate-property', RealEstatePropertyListPage, RealEstatePropertyDetailPage);
                        pages.realEstateProjectPage();
                        pages.realEstatePropertyPage();
                        crudRoute('real-estate-individual', RealEstateIndividualListPage, RealEstateIndividualDetailPage);

                        crudRoute('real-estate-developer', RealEstateDeveloperListPage, RealEstateDeveloperDetailPage);
                        crudRoute('real-estate-project-type', RealEstateProjectTypeListPage, RealEstateProjectTypeDetailPage);
                        crudRoute('real-estate-property-type', RealEstatePropertyTypeListPage, RealEstatePropertyTypeDetailPage);

                        if (hasCapabilitySupport("realEstateFeaturedOffPlans")) {
                            //crudRoute("featured-off-plans", FeaturedOffPlanListPage, FeaturedOffPlanDetailPage)
                            crudRoute("featured-projects", FeaturedOffPlanListPage, FeaturedOffPlanDetailPage)
                        }
                        if (hasCapabilitySupport("realEstateFeaturedProperties")) {
                            crudRoute("featured-properties", FeaturedPropertyListPage, FeaturedPropertyDetailPage)
                        }
                    }
                    if (hasCapabilitySupport("lead")) {
                        crudRoute("lead-sources", LeadSourcesListPage, LeadSourcesDetailPage)
                        crudRoute("lead-tags", LeadTagsListPage, LeadTagsDetailPage)

                        pages.leadPage();
                        routes.push(routeReport('LeadsReport'));
                        //routes.push(<Route exact path="/all-leads" component={AllLeadFlowList} />);
                    }



                    //HRM
                    // crudRoute('designations', DesignationListPage, DesignationDetailPage);
                    // crudRoute("leave-types", LeaveTypeListPage, LeaveTypeDetailPage);
                    // crudRoute("pay-heads", PayHeadListPage, PayHeadDetailPage);
                    // crudRoute("salary-packages", SalaryPackageListPage, SalaryPackageDetailPage);
                    // crudRoute("employees", EmployeeListPage, EmployeeDetailPage);
                    // crudRoute("leaves", LeaveListPage, LeaveDetailPage)


                    // routes.push(<Route exact path="/designations" component={DesignationListPage} />);
                    // routes.push(<Route exact path="/designations/:itemId" component={DesignationDetailPage} />);



                    if (hasCapabilitySupport("onlineStore")) {
                        pages.cms();
                    }


                    routes.push(<Route exact path="/monitoring" component={MonitoringPage} />);


                    routes.push(<Route exact path="/loyalty-cards" component={LoyaltyCardListPage} />);
                    routes.push(<Route exact path="/loyalty-cards/:itemId" component={LoyaltyCardDetailPage} />);
                    routes.push(<Route exact path="/loyalty-cards-issue/:itemId" component={LoyaltyCardIssueDetailPage} />);




                    //routes.push(<Route exact path="/stock-report" component={StockReportPage} />);
                    routes.push(routeReport("StockReport"))
                    routes.push(routeReport("ProductStockReport"))
                    routes.push(routeReport("SerialNoReport"))
                    // routes.push(<Route exact path="/product-stock-report2" component={ProductStockReportPage} />);
                    // routes.push(<Route exact path="/product-stock-report/:loadDefaultProductId" render={props => <ProductStockReportPage {...props} loadDefaultProduct />} />);





                    if (hasCapabilitySupport("continuousStockType")) {
                        routes.push(<Route exact path="/measurement-types" component={MeasurementTypeListPage} />);
                        routes.push(<Route exact path="/measurement-types/:itemId" component={MeasurementTypeDetailPage} />);
                    }



                    routes.push(<Route exact path="/stores" component={StoreListPage} />);
                    routes.push(<Route exact path="/stores/:itemId" component={StoreDetailPage} />);

                    routes.push(<Route exact path="/group-price-overrides/:itemId" component={GroupPriceOverrideDetailPage} />);
                    routes.push(<Route exact path="/price-overrides/:itemId" component={PriceOverrideDetailPage} />);



                    routes.push(<Route exact path="/suppliers" component={SupplierListPage} />);
                    routes.push(<Route exact path="/suppliers/:itemId" component={SupplierDetailPage} />);


                    routes.push(<Route exact path="/coupons" component={CouponListPage} />);
                    routes.push(<Route exact path="/coupons/:itemId" component={CouponDetailPage} />);
                    crudRoute("coupon-promotions", CouponPromotionListPage, CouponPromotionDetailPage)

                    routes.push(<Route exact path="/discounts" component={DiscountListPage} />);
                    routes.push(<Route exact path="/discounts/:itemId" component={DiscountDetailPage} />);

                    routes.push(<Route exact path="/warehouses" component={WarehouseListPage} />);
                    routes.push(<Route exact path="/warehouses/:itemId" component={WarehouseDetailPage} />);



                    if (hasCapabilitySupport("terminalPos")) {
                        routes.push(<Route exact path="/terminals" component={TerminalListPage} />);
                        routes.push(<Route exact path="/terminals/:itemId" component={TerminalDetailPage} />);
                    }










                    // routes.push(<Route exact path="/quotations" component={QuotationsPage} />);

                    routes.push(<Route exact path="/import-data" component={ImportDataPage} />);
                    routes.push(<Route exact path="/import-data/:objectType" component={ImportDataTypePage} />);
                }


                //if (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_VENUE_MANAGER) {
                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION) {
                    routes.push(<Route exact path="/staff-users" component={UserListPage} />);
                    routes.push(<Route exact path="/staff-users/:itemId" component={UserDetailPage} />);
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION) {
                    // routes.push(<Route exact path="/staff-users" component={UserListPage} />);
                    // routes.push(<Route exact path="/staff-users/:itemId" component={UserDetailPage} />);

                    if (hasCapabilitySupport("salesApp")) {
                        routes.push(<Route exact path="/sales-app" component={SalesAppPage} />);
                    }
                    if (hasCapabilitySupport("pocketPosApp")) {
                        routes.push(<Route exact path="/pocket-pos-app" component={PocketPosAppPage} />);
                    }
                    if (hasCapabilitySupport("orderManagementSystem")) {
                        routes.push(<Route exact path="/delivery-app" component={DeliveryAppPage} />);
                    }
                    if (hasCapabilitySupport("restaurant")) {
                        routes.push(<Route exact path="/esc-pos-print-server" component={EscPosPrintServerPage} />);
                    }
                }

                if (accountRole == ACCOUNT_TYPE_ADMINISTRATION) {
                    // routes.push(<Route exact path="/system-logs" component={SystemLogsPage} />)

                    routes.push(<Route exact path="/supported-currencies" component={SupportedCurrencyListPage} />);
                    routes.push(<Route exact path="/supported-currencies/:itemId" component={SupportedCurrencyDetailPage} />);

                    routes.push(<Route exact path="/system-values" component={SystemValuesPage} />)
                    routes.push(<Route exact path="/system-settings" component={SystemSettingsPage} />)

                    if (hasCapabilitySupport("lms")) {
                        routes.push(<Route exact path="/email-designer" component={EmailDesignerPage} />)
                    }

                    if (hasCapabilitySupport("restaurant")) {
                        routes.push(<Route exact path="/print-event-designer" component={PrintEventDesignerPage} />)
                    }

                    if (hasCapabilitySupport("templateEngine")) {
                        routes.push(<Route exact path="/markup-templates" component={MarkupTemplatesPage} />)
                    }

                    crudRoute('printers', PrinterListPage, PrinterDetailPage)

                    routes.push(<Route exact path="/cogs-calculator" component={CogsCalculator} />)
                    routes.push(routeReport('CogsTrailReport'));
                }
            }
        }

        return routes;
    }

    renderApp() {
        // return <div style={{ height: 'calc(100vh - 3rem)', maxHeight: 'calc(100vh - 3rem)', width: '100%', background: 'white' }}>
        //     <FrameTest path="/backend" />
        // </div>



        return (
            <div style={{ flex: 1, height: 0, width: '100%', background: 'white' }}>
                <Switch>
                    {this.renderRoutes()}
                    <Route component={(props) => <ErrorPage {...props} error404 />} />
                </Switch>

                <SingleBarcodeScannerDialog open={this.state.showScanBarcodeDialog} onClose={() => this.setState({ showScanBarcodeDialog: false })} />
            </div>
        )
    }

    renderThemeSelector() {
        if (hasCapabilitySupport("restaurant") && (window.location.pathname === "/pos" || window.location.pathname === "/")) {
            return null;
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'flex-end', gap: '0.5rem' }}>
                {THEME_LIST.filter(theme => theme.getName() != this.state.selectedTheme.getName())
                    .map(theme => <ThemeIcon key={theme.getName()} theme={theme} onClick={() => this.setState({ selectedTheme: theme }, () => saveTheme(this.state.selectedTheme.getName()))} />)}
            </div>
        )
    }

    renderHeader() {
        if (this.state.loggedIn && getAccountRole() === ACCOUNT_TYPE_SECOND_DISPLAY && window.location.pathname === '/monitor') {
            return <div style={{ marginBottom: '-3rem' }} />
        }

        const isNonRestCashier = !hasCapabilitySupport("restaurant") && getAccountRole() == ACCOUNT_TYPE_CASHIER;

        if (isMobileScreen) {
            return (
                <Header>
                    {!isNonRestCashier &&
                        getAccountRole() != ACCOUNT_TYPE_STORE_MASTER &&
                        getAccountRole() != ACCOUNT_TYPE_SHIPPING_PORT_STAFF && getAccountRole() != ACCOUNT_TYPE_TYPE_KITCHEN &&
                        getAccountRole() != ACCOUNT_TYPE_SECOND_DISPLAY &&
                        <HeaderGlobalAction
                            //aria-label="Open menu"
                            id="menu-btn"
                            className="bx--tooltip--hidden"
                            tooltipAlignment="start"
                            style={{ pointerEvents: !this.state.loggedIn ? 'none' : undefined }}
                            onClick={() => this.setState({ openMenu: !this.state.openMenu, openProfileCard: false })}
                            isActive={this.state.openMenu}>
                            {this.state.loggedIn ? (this.state.openMenu ? <Close20 /> : <Menu20 />) : <Login20 />}
                        </HeaderGlobalAction>}

                    {/* <HeaderName prefix="POS">App</HeaderName> */}
                    <div
                        style={{ height: '100%', paddingBottom: 5, paddingTop: 5, marginRight: '1rem', marginLeft: '1rem' }}>
                        <L to="/">
                            <img src={HAS_ROBOTIC_BRANDING ? RoboticLogo : Logo} style={{ objectFit: 'contain', objectPosition: 'center', height: '100%', cursor: 'pointer' }} />
                        </L>
                    </div>

                    <HeaderGlobalBar>
                        {this.state.loggedIn && (
                            <HeaderGlobalAction
                                //aria-label={getAccount().fullName}
                                className="bx--tooltip--hidden"
                                //onClick={() => this.logout()}
                                onClick={() => this.setState(prevState => ({ openProfileCard: !prevState.openProfileCard, openMenu: false }))}
                                style={{ marginRight: 25 }}
                                tooltipAlignment="end">

                                <ProfilePic size={20} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, getAccount().id)} />

                            </HeaderGlobalAction>
                        )}
                    </HeaderGlobalBar>



                    {!isNonRestCashier && getAccountRole() != ACCOUNT_TYPE_STORE_MASTER && getAccountRole() != ACCOUNT_TYPE_SHIPPING_PORT_STAFF &&
                        getAccountRole() != ACCOUNT_TYPE_TYPE_KITCHEN &&
                        getAccountRole() != ACCOUNT_TYPE_SECOND_DISPLAY &&
                        <HeaderPanel //expanded={this.state.openMenu} 
                            expanded
                            style={{
                                left: 0, right: 'none', opacity: this.state.openMenu ? 1 : 0, pointerEvents: this.state.openMenu ? undefined : 'none',
                                left: this.state.openMenu ? 0 : -400, transition: '150ms'
                            }}>
                            <Scrollbars renderThumbVertical={props => <div {...props} style={{ ...props.style, background: 'white', borderRadius: 25, opacity: 0.2 }} />}>
                                {/* <Menu onClose={() => this.setState({openMenu: false})} /> */}
                                <SideMenu key={"side-menu-lg-" + this.state.loggedIn} onClose={() => this.setState({ openMenu: false })} />
                            </Scrollbars>
                        </HeaderPanel>}
                </Header>



            )
        }

        return (
            <Header>
                {!isNonRestCashier && getAccountRole() != ACCOUNT_TYPE_STORE_MASTER && getAccountRole() != ACCOUNT_TYPE_SHIPPING_PORT_STAFF &&
                    getAccountRole() != ACCOUNT_TYPE_TYPE_KITCHEN &&
                    getAccountRole() != ACCOUNT_TYPE_SECOND_DISPLAY &&
                    <HeaderGlobalAction
                        //aria-label="Open menu"
                        id="menu-btn"
                        className="bx--tooltip--hidden"
                        tooltipAlignment="start"
                        style={{ pointerEvents: !this.state.loggedIn ? 'none' : undefined }}
                        onClick={() => this.setState({ openMenu: !this.state.openMenu, openProfileCard: false })}
                        isActive={this.state.openMenu}>
                        {this.state.loggedIn ? (this.state.openMenu ? <Close20 /> : <Menu20 />) : <Login20 />}
                    </HeaderGlobalAction>}

                {/* <HeaderName prefix="POS">App</HeaderName> */}
                <div
                    style={{ height: '100%', paddingBottom: 5, paddingTop: 5, marginRight: '1rem', marginLeft: '1rem' }}>
                    <L to="/">
                        <img src={HAS_ROBOTIC_BRANDING ? RoboticLogo : Logo} style={{ objectFit: 'contain', objectPosition: 'center', height: '100%', cursor: 'pointer' }} />
                    </L>
                </div>

                <div style={{ height: '100%', marginLeft: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                    {getAccountRole() == ACCOUNT_TYPE_STORE_MASTER ? <>
                        <p style={{ fontSize: 12, fontWeight: 'bolder', color: 'white', }}>Venue Master - {getAccount().venueName}</p>
                    </> : <>
                        {(
                            this.state.loggedIn &&
                            hasCapabilitySupport("terminalPos") &&
                            getAccountRole() != ACCOUNT_TYPE_REAL_ESTATE_AGENT &&
                            getAccountRole() != ACCOUNT_TYPE_STUDENT &&
                            getAccountRole() != ACCOUNT_TYPE_TUTOR &&
                            getAccountRole() != ACCOUNT_TYPE_GUARDIAN &&
                            getAccountRole() != ACCOUNT_TYPE_PRODUCT_MANAGER &&
                            //getAccountRole() != ACCOUNT_TYPE_REST_DELIVERY_MANAGER &&
                            getAccountRole() != ACCOUNT_TYPE_DELIVERY_MANAGER &&
                            getAccountRole() != ACCOUNT_TYPE_WAREHOUSE_KEEPER
                        ) && <Link
                            onClick={() => this.setState({ showSetTerminalDialog: true })}
                            style={{ fontSize: 12, fontWeight: 'bolder', color: 'white', cursor: 'pointer' }}>
                                {this.state.terminal !== undefined && this.state.terminal !== null ?
                                    this.state.terminal.terminalId + ' - ' + this.state.terminal.store : 'No terminal specified'}
                            </Link>}
                    </>}
                    <div style={{ width: '1rem' }} />
                    <ClockRender>{dateText => <p style={{ fontSize: 12 }}>{dateText}</p>}</ClockRender>
                </div>

                {this.state.loggedIn && getAccountRole() != ACCOUNT_TYPE_SECOND_DISPLAY && this.renderThemeSelector()}

                {/* <div>
                        <div style={{width: 45, height: 45, background: 'red'}} />
                    </div> */}

                {this.state.loggedIn && this.state.terminal !== undefined && this.state.terminal !== null &&
                    getAccountRole() == ACCOUNT_TYPE_SECOND_DISPLAY &&
                    <HeaderGlobalBar>
                        <div style={{ height: '100%', marginRight: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                            <RL to="/monitor">
                                <Link
                                    //onClick={() => window.open("/monitor", "_blank").focus()}
                                    //onClick={() => location.his}
                                    style={{ fontSize: 12, fontWeight: 'bolder', color: 'white', cursor: 'pointer' }}>
                                    Open Terminal Monitor
                                </Link>
                            </RL>
                        </div>
                    </HeaderGlobalBar>}

                {this.state.loggedIn && <HeaderGlobalBar>

                    {getAccountRole() == ACCOUNT_TYPE_STORE_MASTER ? (<>
                        <HeaderGlobalAction
                            aria-label="Refresh"
                            onClick={() => {
                                window.location.reload();
                            }}>
                            <Reset20 />
                        </HeaderGlobalAction>
                    </>) : (<>

                        {this.state.failedPrintTasks.length > 0 &&
                            <div style={{ height: '100%', marginRight: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                                <Link
                                    onClick={() => this.setState({ showFailedPrintTasksDialog: true })}
                                    style={{ fontSize: 12, marginLeft: '0.15rem', fontWeight: 'bolder', color: 'red', cursor: 'pointer' }}>
                                    {/* Failed Print Task{this.state.failedPrintTasks.length > 1 ? 's' : ''} ({this.state.failedPrintTasks.length}) */}
                                    Failed ({this.state.failedPrintTasks.length})
                                </Link>
                            </div>}

                        {this.thermalPrinter.driver !== undefined && this.thermalPrinter.driver.isConnectionBased() &&
                            <HeaderGlobalAction
                                onClick={() => this.thermalPrinter.refresh()}
                                style={{ pointerEvents: this.state.printerLoading ? 'none' : undefined }}
                                aria-label="Reload Printer Connection">
                                <Printer20 style={{ fill: this.state.printerLoading ? '#808080' : this.state.printerConnected ? 'green' : 'red' }} />
                            </HeaderGlobalAction>}

                        {this.thermalPrinter.driver !== undefined && this.thermalPrinter.driver.hasCashDrawerAccess() &&
                            <HeaderGlobalAction
                                onClick={() => this.setState({ showOpenCashDrawerDialog: true })}
                                aria-label="Open Cash Drawer">
                                <OpenPanelFilledBottom20 />
                            </HeaderGlobalAction>}

                        {/* {MENU_PACKAGE != "crm" &&
                            getAccountRole() != ACCOUNT_TYPE_CASHIER && getAccountRole() != ACCOUNT_TYPE_REAL_ESTATE_AGENT &&
                            getAccountRole() != ACCOUNT_TYPE_STUDENT && getAccountRole() != ACCOUNT_TYPE_TUTOR && getAccountRole() != ACCOUNT_TYPE_GUARDIAN &&
                            // <L to="/product-stock-report">
                            <L to={getReportLink("ProductStockReport")}>
                                <HeaderGlobalAction
                                    aria-label="Find Product">
                                    <Product20 />
                                </HeaderGlobalAction>
                            </L>} */}
                        {MENU_PACKAGE != "crm" &&
                            !hasCapabilitySupport("cargo") &&
                            getAccountRole() != ACCOUNT_TYPE_CASHIER && getAccountRole() != ACCOUNT_TYPE_REAL_ESTATE_AGENT &&
                            getAccountRole() != ACCOUNT_TYPE_TYPE_KITCHEN &&
                            getAccountRole() != ACCOUNT_TYPE_TYPE_WAITER &&
                            getAccountRole() != ACCOUNT_TYPE_REST_DELIVERY_MANAGER &&
                            getAccountRole() != ACCOUNT_TYPE_STUDENT &&
                            getAccountRole() != ACCOUNT_TYPE_TUTOR &&
                            getAccountRole() != ACCOUNT_TYPE_GUARDIAN &&
                            getAccountRole() != ACCOUNT_TYPE_SECOND_DISPLAY &&
                            <HeaderGlobalAction
                                aria-label="Scan Barcode"
                                onClick={() => this.setState({ showScanBarcodeDialog: true, openProfileCard: false })}>
                                <Barcode20 />
                            </HeaderGlobalAction>}

                        <HeaderGlobalAction
                            aria-label="Refresh"
                            onClick={() => {
                                window.location.reload();
                            }}>
                            <Reset20 />
                        </HeaderGlobalAction>
                        {/* {getAccountRole() == ACCOUNT_TYPE_CASHIER && hasTerminal() && window.location.pathname != '/returns-and-exchanges' &&
                            <L to="/returns-and-exchanges"> */}
                        {//getAccountRole() == ACCOUNT_TYPE_CASHIER && 
                            hasTerminal() && window.location.pathname != '/sales-return'
                            && !hasCapabilitySupport("cargo")
                            && getAccountRole() != ACCOUNT_TYPE_TUTOR
                            && getAccountRole() != ACCOUNT_TYPE_GUARDIAN
                            && getAccountRole() != ACCOUNT_TYPE_TYPE_KITCHEN
                            && getAccountRole() != ACCOUNT_TYPE_PRODUCT_MANAGER
                            && getAccountRole() != ACCOUNT_TYPE_REST_DELIVERY_MANAGER
                            && getAccountRole() != ACCOUNT_TYPE_WAREHOUSE_KEEPER
                            && !hasCapabilitySupport('restaurant')
                            && getAccountRole() != ACCOUNT_TYPE_STUDENT &&
                            getAccountRole() != ACCOUNT_TYPE_SECOND_DISPLAY &&
                            <L to="/sales-return">
                                <HeaderGlobalAction
                                    aria-label="Sales Return"
                                // aria-label="Sales & Exchanges"
                                >
                                    <DeliveryParcel20 />
                                </HeaderGlobalAction>
                            </L>}

                        {/* <HeaderGlobalAction
                                onClick={() => this.setState({ openNotifications: !this.state.openNotifications })}
                                isActive={this.state.openNotifications}
                                aria-label="Notifications">
                                <Notification20 />
                            </HeaderGlobalAction> */}
                        {/* <HeaderGlobalAction
                                onClick={() => {
                                    if (this.state.inFullScreen) {
                                        document.exitFullscreen();
                                        this.setState({inFullScreen: false})
                                    } else {
                                        this.rootRef.current.requestFullscreen()
                                        this.setState({inFullScreen: true})
                                    }
                                }}
                                aria-label={this.state.inFullScreen ? "Exit Full Screen" : "Full Screen"}>
                                <FitToScreen20 />
                            </HeaderGlobalAction> */}

                    </>)}

                    {/* <HeaderGlobalAction
                            aria-label="Search">
                            <Search20 />
                        </HeaderGlobalAction> */}




                    {this.state.loggedIn ? (
                        <HeaderGlobalAction
                            //aria-label={getAccount().fullName}
                            className="bx--tooltip--hidden"
                            //onClick={() => this.logout()}
                            onClick={() => this.setState(prevState => ({ openProfileCard: !prevState.openProfileCard, openMenu: false }))}
                            style={{ marginRight: 25 }}
                            tooltipAlignment="end">

                            <ProfilePic size={20} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, getAccount().id)} />

                        </HeaderGlobalAction>
                    ) : (
                        <HeaderGlobalAction
                            aria-label="Terminal Settings"
                            style={{ marginRight: 25 }}
                            tooltipAlignment="end">

                            <Settings20 />

                        </HeaderGlobalAction>
                    )}
                </HeaderGlobalBar>}

                {!isNonRestCashier && getAccountRole() != ACCOUNT_TYPE_SECOND_DISPLAY &&
                    <HeaderPanel //expanded={this.state.openMenu} 
                        expanded
                        style={{
                            left: 0, right: 'none', opacity: this.state.openMenu ? 1 : 0, pointerEvents: this.state.openMenu ? undefined : 'none',
                            left: this.state.openMenu ? 0 : -400, transition: '150ms'
                        }}>
                        <Scrollbars renderThumbVertical={props => <div {...props} style={{ ...props.style, background: 'white', borderRadius: 25, opacity: 0.2 }} />}>
                            {/* <Menu onClose={() => this.setState({openMenu: false})} /> */}
                            <SideMenu key={"side-menu-lg-" + this.state.loggedIn} onClose={() => this.setState({ openMenu: false })} />
                        </Scrollbars>
                    </HeaderPanel>}
            </Header>



        )
    }

    getLayout() {
        // return <MenuDesigner />

        const profileLink = (() => {
            switch (getAccountRole()) {
                case ACCOUNT_TYPE_ADMINISTRATION:
                    return "/staff-users/" + getAccount().id
                case ACCOUNT_TYPE_STUDENT: case ACCOUNT_TYPE_TUTOR: case ACCOUNT_TYPE_GUARDIAN:
                    return "/profile"
            }
            return null;
        })()
        const hasViewProfile = Util.isStringExists(profileLink);
        return (
            <div className={this.state.selectedTheme.getClassName()} ref={this.rootRef}
                style={{ height: 'var(--app-height)', width: '100%', display: 'flex', flexDirection: 'column', }}
            >
                {/* <SessionTimeout isLoggedIn={this.state.loggedIn} timeoutMs={1000} /> */}

                {this.state.loggedIn &&
                    <div
                        style={{
                            position: 'fixed', width: '100%', height: '100%', zIndex: 7999, background: 'rgba(0,0,0,0.75)',
                            transition: '150ms', opacity: this.state.openProfileCard ? 1 : 0, pointerEvents: this.state.openProfileCard ? undefined : 'none'
                        }}>
                        <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }} onClick={() => this.setState({ openProfileCard: false })} />


                        <div className="profile-card" style={{
                            position: 'absolute', transition: '150ms', top: this.state.openProfileCard ? '4rem' : (hasViewProfile ? -150 : -100), right: '1rem', overflow: 'hidden', width: 300, height: hasViewProfile ? 150 : 100,
                            display: 'flex', alignItems: 'center', padding: '1rem', borderRadius: 5, boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
                        }}>
                            <ProfilePic src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, getAccount().id)} size={65} />
                            <div style={{ flex: 1, color: 'white', marginLeft: '1rem' }}>
                                <h4>{getAccount().fullName}</h4>
                                <p style={{ fontSize: 12, opacity: 0.65 }}>{getAccount().typeName}</p>

                                {hasViewProfile &&
                                    <div style={{ marginTop: '1rem', }}>
                                        <RL to={profileLink} onClick={() => this.setState({ openProfileCard: false })}>
                                            <Link renderIcon={ArrowRight16}>View Profile</Link>
                                        </RL>
                                    </div>}
                            </div>
                        </div>

                        <div style={{ position: 'absolute', transition: '200ms', top: this.state.openProfileCard ? (hasViewProfile ? 195 : 145) : (hasViewProfile ? -150 : -100), right: '1rem', }}>
                            <Button style={{ borderRadius: 5, borderTopRightRadius: 0, boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)' }} kind="danger"
                                onClick={() => this.setState({ openProfileCard: false }, () => this.logout())}
                                renderIcon={Logout16}>Logout</Button>
                        </div>
                    </div>}

                {this.renderHeader()}

                <div style={{ height: '3rem' }} />

                {/* {this.state.loggedIn ? this.renderApp() : (
                    hasCapabilitySupport("lms") ? (
                        <LmsAuthView onAuth={() => this.setState({ loggedIn: true }, () => SocketSession.open())} />
                    ) : (
                        <AuthView onAuth={() => this.setState({ loggedIn: true }, () => SocketSession.open())} />
                    )
                )} */}
                {this.state.loggedIn ? this.renderApp() : (
                    hasCapabilitySupport("lms") ? (
                        <LmsAuthView onAuth={() => window.location.reload()} />
                    ) : (
                        <AuthView onAuth={() => window.location.reload()} />
                    )
                )}

                <TerminalSetDialog open={this.state.showSetTerminalDialog} onClose={() => this.setState({ showSetTerminalDialog: false })}
                    onTerminalUpdate={terminal => this.setState({ terminal, showSetTerminalDialog: false, posPageId: Util.newTempId() }, () => this.thermalPrinter.loadConfig())} />

                <ClearanceCodeDialog title="Opening Cash Drawer" open={this.state.showOpenCashDrawerDialog} onClose={() => this.setState({ showOpenCashDrawerDialog: false })} onAuth={() => {
                    this.thermalPrinter.driver.openCashDrawer()
                        .then(() => {
                            Api.logCashDrawerOpen();
                            UIUtil.showSuccess("Cash drawer opened");
                        })
                        .catch(() => UIUtil.showError("Failed to open cash drawer"))
                }} />

                <ComposedModal size="lg" open={this.state.showFailedPrintTasksDialog} onClose={() => this.setState({ showFailedPrintTasksDialog: false })}>
                    <ModalHeader label={"POS Print"} title={"Failed Print Task" + (this.state.failedPrintTasks.length > 1 ? 's' : '')} />
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        {this.state.failedPrintTasks.length == 0 && <p style={{ opacity: 0.65, fontSize: 12 }}>No failed tasks exist</p>}
                        {this.state.failedPrintTasks.map((task, index) => <FailedPrintTaskItem key={task.id} task={task} index={index} thermalPrinter={this.thermalPrinter} />)}
                    </ModalBody>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonSet style={{ width: 196 }}>
                            <Button kind="secondary" onClick={() => this.setState({ showFailedPrintTasksDialog: false })}>
                                Close
                            </Button>
                        </ButtonSet>
                    </div>
                </ComposedModal>

                <ScrollToTopBtn />

                {/* <ProductGroupSetAttribute groupId={44730} open /> */}

                <BitmapManager />
                <ImperativeRender />
            </div>
        )
    }


    isPageLoadable() {
        return false;
    }

    async logout() {
        const stopLoading = await UIUtil.pageLoader();
        try {
            await Api.asyncLogout();
            clearAccount();
            SocketSession.close();
            window.location.href = '/'
        } catch (e) {
            stopLoading();
        }
    }

}

export default App;
