import { useMemo, useState } from "react"
import { hasCapabilitySupport } from "../../../app/Capabilities"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24, Checkmark16, ArrowRight16 } from '@carbon/icons-react'
import { FormSection } from "../../../templates/form/form"
import { TableInput } from "../../../components/table-input"
import { Link, TextInput } from "carbon-components-react"
import { openPdf } from "../../../markup-template-engine"
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view"
import { OBJECT_TYPE_CARGO_RECEIPT_NOTE } from "../../../constants/ObjectTypes"
import UIUtil from "../../../util/UIUtil"
import Api from "../../../session/Api"
import Button from "../../../components/Button"
import Util, { big } from "../../../util/Util"
import { DateRange2 } from "../../components/basic-filter/date-range"
import { MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { getReportLink } from "../../base/report-page"
import { ComboBoxField } from "../../../components/ComboBoxField"

export const CargoReceiptNoteList = newListDetail({
    title: "Cargo Receipt Note",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: Form,

    openDetailOnCreate: true,
    clearStateOnSave: true,
    skipInlinePadding: true,
    canDelete: item => item.status == "DRAFT",

    defaultValues: {
        date: Date.now(),
        items: [],
    },

    isAvailable: () => {
        if (!hasCapabilitySupport("cargo")) {
            return;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },

    renderOptions: (props) => <Actions {...props} />,
})

function ItemListField({ value, setValue, readonly, suppliers }) {
    const cols = useMemo(() => [
        {
            key: "id", title: "ID", flex: 1, Component: ({ value, setValue }) => {
                return <TextInput value={typeof value === 'number' ? value : "(auto)"} />
            }
        },

        {
            key: "code", title: "Code", group: "Item", flex: 2, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },
        {
            key: "description", title: "Description", flex: 3, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },
        {
            key: "supplierId", title: "Supplier", flex: 3, Component: ({ value, setValue }) => {
                return (
                    <div style={{ width: '100%' }}>
                        <ComboBoxField value={value} setValue={setValue} options={suppliers} />
                    </div>
                )
            }
        },
        {
            key: "hsCode", title: "H.S. Code", flex: 2, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },
        {
            key: "origin", title: "CoA", flex: 2, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },



        {
            key: "length", title: "Length", group: "Measurements", flex: 1, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },
        {
            key: "width", title: "Width", flex: 1, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },
        {
            key: "height", title: "Height", flex: 1, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },
        {
            key: "weight", title: "Weight", flex: 1, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },

        {
            key: "cbm", title: "CBM", group: "Calculations", flex: 1, Component: ({ useEntry }) => {
                const [length] = useEntry('length');
                const [width] = useEntry('width');
                const [height] = useEntry('height');

                const cbm = useMemo(() => {
                    return big(length).times(big(width)).times(big(height)).toFixed(2);
                }, [length, width, height])

                return <TextInput value={cbm ?? ""} />
            }
        },
    ], [readonly])

    return <TableInput minimal columns={cols} value={value} setValue={setValue} hasGroups readonly={readonly} hideDummy={readonly} />;
}

const StatusIndicator = ({ status }) => {
    const { text, color } = (() => {
        switch (status) {
            case "DRAFT": return {
                text: "Draft",
                color: "#ffcc00"
            }

            case "POSTED": return {
                text: "Posted",
                color: "#0f62fe"
            }

            case "REVOKED": return {
                text: "Revoked",
                color: "#990000"
            }

            default: return {
                text: "Unknown",
                color: "#1c1c1c"
            }
        }
    })();

    return <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', paddingLeft: '0.25rem' }}>
            <div style={{ width: 6, height: 6, background: color, borderRadius: 50, boxShadow: `0 0 50px 15px ${color}CC` }} />
            <span>{text}</span>
        </div>
    </>
};

const InfoCard = ({ data }) => {
    const { } = data;

    return <>
        <div style={{
            borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.05) ', padding: '0.5rem',
            display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            marginBottom: '2rem', marginTop: '-1rem'
        }}>
            <div>
                <h6>Status</h6>
                <StatusIndicator status={data.status} />
            </div>
            <div>
                <h6>Voucher No</h6>
                {Util.getVoucherNumber(data.id)}
            </div>
            <div>
                <h6>Created by</h6>
                {data.createdByName}
            </div>
            <div>
                <h6>Created on</h6>
                {Util.formatDate(data.createdOn, 'DD-MM')}
            </div>
            {data.status === 'POSTED' && <>
                <div>
                    <h6>Posted by</h6>
                    {data.postedByName}
                </div>
                <div>
                    <h6>Posted on</h6>
                    {Util.formatDate(data.postedOn, 'DD-MM')}
                </div>
            </>}
            <div>
                <h6>Document</h6>
                <Link style={{ cursor: 'pointer', lineHeight: 'unset' }} onClick={() => openPdf('CargoReceiptNoteDoc', data.id)}>Open PDF</Link>
            </div>
        </div>
    </>;
}

function Actions({ item, onUpdate }) {
    const [loading, setLoading] = useState(false);
    // const onRevokeBtn = async () => {
    //     const confirmed = await UIUtil.confirmPrompt({
    //         message: "Are you sure you want to revoke this Purchase Order?",
    //     })
    //     if (!confirmed) {
    //         return;
    //     }

    //     const note = await UIUtil.inputPrompt({
    //         title: "Revoke Reason",
    //         message: `Enter the reason for revoking the request.`,
    //         label: "Reason",
    //         textArea: true,
    //     })
    //     if (!note) {
    //         UIUtil.showInfo("Reason is required!");
    //         return;
    //     }

    //     setLoading(true);
    //     try {
    //         const [success, response] = await Api.try((api, listener) => api.v1PoRevoke(item.id, note, listener));
    //         if (!success) {
    //             return;
    //         }

    //         onUpdate(response)
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    const onFinalizeBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            message: "Are you sure you want to post this Cargo Receipt Note and add the items to the inventory?",
        })
        if (!confirmed) {
            return;
        }

        setLoading(true);
        try {
            const [success, response] = await Api.try((api, listener) => api.postCargoReceiptNote(item.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response)
        } finally {
            setLoading(false);
        }
    }

    if (item.status === 'DRAFT') {
        return (<>
            {/* <Button onClick={onRevokeBtn} loading={loading} size="sm" style={{ borderRadius: 25, marginLeft: '0.25rem' }} renderIcon={Close16} kind="danger">
                Revoke
            </Button> */}
            <Button onClick={onFinalizeBtn} className="green-btn" loading={loading} size="sm" style={{ borderRadius: 25, marginLeft: '0.25rem' }} renderIcon={Checkmark16}>
                Post/Finalize
            </Button>
        </>)
    }

    return null;
}

function Form({ endpoint, form, data }) {
    const readonly = data && data.status !== "DRAFT";
    const isPosted = data?.status === 'POSTED';

    return (
        <div>
            <div style={{ paddingInline: '3rem' }}>
                {data && <InfoCard data={data} />}

                <div style={{ pointerEvents: readonly ? 'none' : undefined }} inert={readonly ? '' : undefined}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem' }}>
                        <form.DateField fieldKey="date" title="Date" />
                        <form.ComboBoxField fieldKey="customerId" title="Customer" options={endpoint.customers} />
                        {/* <form.ComboBoxField fieldKey="warehouseId" title="Warehouse" options={endpoint.warehouses} /> */}
                    </div>
                </div>
            </div>

            <div>
                {isPosted && <div style={{ marginBottom: '1rem', paddingInline: '3rem' }}>
                    <Button size="md" style={{ borderRadius: 5 }} onClick={() => window.open(getReportLink("CargoInventory", {
                        crn: Util.getVoucherNumber(data.id),
                        filters: [{ property: 'crn', operator: 'EQ', value: Util.getVoucherNumber(data.id) }]
                    }))} renderIcon={ArrowRight16}>View in Inventory</Button>
                </div>}
                <FormSection title={"Items"} titleStyle={{ paddingInline: '3rem' }}>
                    <form.CustomField fieldKey="items" childrenProps={{ readonly, suppliers: endpoint.suppliers }}>
                        {ItemListField}
                    </form.CustomField>
                </FormSection>
            </div>


            {data && <div style={{ paddingInline: '3rem' }}>
                <FormSection title={"Notes/Docs"}>
                    <div style={{ marginTop: '0rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                        <AdvancedNotesView title="" objectId={data.id} objectType={OBJECT_TYPE_CARGO_RECEIPT_NOTE} />
                    </div>
                </FormSection>
            </div>}
        </div>
    )
}

const STATUSES = [
    'Draft',
    'Posted',
]

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="date" />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Status" fieldKey="cargorecenotestatuses" options={STATUSES} state={state} property="status" />
        </div>

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Customer" fieldKey="cargorecenotecustomers" options={endpoint.customers.map(c => c.value)} state={state} property="customer" />
        </div>
    </>)
}
