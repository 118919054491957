import Api from "../session/Api";


function mapData(data) {
    return {
        ...data,
        templateOptions: Object.fromEntries(data.templateOptions.map(opt => [opt.name, opt]))
    }
}


let resolvePromise, rejectPromise;

const loadPromise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
})

export function loadAppData() {
    Api.getAppData(response => {
        if (response.status === true) {
            resolvePromise(mapData(response.payload));
        } else {
            rejectPromise();
        }
    })
}


export const getAppData = () => loadPromise;



