

// 12 => 71
// 14 => 60
// 16 => 53
// 18 => 47
// 20 => 42
// 22 => 38
// 40 => 21

import Logo from '../../../images/SquareLogo.png';
import ReceiptLogo from '../../../images/ReceiptLogo.png'

import QRCode from "react-qr-code"
import Util from "../../../util/Util"
import { paymentMethodTextWithArabic } from "../../../pages/transaction/TransactionEditor"
import { THERMAL_RECEIPT_LOGO } from '../../../app/Config';
import { POS_SESSION_DISCOUNT_TYPE_COUPON } from '../../../constants/Constants';
import { KOT_ITEM_STATUS_AWAITING, KOT_ITEM_STATUS_DONE, KOT_ITEM_STATUS_STARTED, REST_ORDER_TYPE, getRestOrderDate, getRestOrderNote, restOrderTypeName } from '../../../domain/rest-sales-order';
import { Checkmark24, Time24, OverflowMenuHorizontal24, Unknown24 } from '@carbon/icons-react'
import React, { Fragment } from 'react';
import { useMemo } from 'react';
import { getAddressCustomerName } from '../../../pages/sales-order/base/sales-order';


const Line = () => <br />
const Divider = () => <p style={{ fontSize: 12, color: 'black' }}>{Array(71).fill({}).map(i => '-')}</p>
const FullDivider = () => <div style={{ width: '100%', height: 4, background: 'black' }} />

const Text = ({ bold, italic, size, center, right, underline, children, strike = true }) => <p
    className={strike ? "strikethrough-xl" : ""}
    style={{
        fontWeight: bold ? "bold" : "bold", textAlign: center ? "center" : right ? "right" : "left", fontSize: size,
        textDecoration: underline ? "underline" : undefined,
        // textDecorationLine: strike ? "line-through" : undefined,
        // textDecorationThickness: strike ? 5 : undefined,
        // textDecorationColor: strike ? "#000000" : undefined,
        color: strike ? '#000000CC' : undefined,
        fontStyle: italic ? "italic" : undefined,
        whiteSpace: 'pre-wrap'
    }}>{children}</p>

const Normal = ({ children }) => <Text bold={false} size={20}>{children}</Text>
const Italic = ({ children }) => <Text italic size={20}>{children}</Text>
const Underline = ({ children }) => <Text underline bold={false} size={20}>{children}</Text>
const ArabicNormal = ({ children }) => <Text right bold={false} size={20}>{children}</Text>
const ArabicBold = ({ children }) => <Text right bold={true} size={20}>{children}</Text>
const ArabicUnderline = ({ children }) => <Text right underline={true} size={20}>{children}</Text>

const BoldCenterNormal = ({ children }) => <Text bold center size={20}>{children}</Text>
const CenterNormal = ({ children }) => <Text center size={20}>{children}</Text>
const CenterBold = ({ children }) => <Text center bold size={20}>{children}</Text>
const RightNormal = ({ children }) => <Text right size={20}>{children}</Text>
const DoubleRightNormal = ({ children }) => <Text right size={40}>{children}</Text>

const Bold = ({ children }) => <Text bold size={20}>{children}</Text>
const MdBold = ({ children }) => <Text bold size={25}>{children}</Text>
const LgBoldRight = ({ children }) => <Text right bold size={30}>{children}</Text>
const LgNormal = ({ children }) => <Text size={30}>{children}</Text>
const LgBold = ({ children }) => <Text bold size={30}>{children}</Text>
const MdNormal = ({ children }) => <Text size={25}>{children}</Text>
const DoubleBold = ({ children }) => <Text bold size={40}>{children}</Text>
const CenterDoubleBold = ({ children }) => <Text center bold size={40}>{children}</Text>
const CenterMdBold = ({ children }) => <Text center bold size={25}>{children}</Text>
const CenterLgBold = ({ children }) => <Text center bold size={30}>{children}</Text>


// export const KOT_ITEM_STATUS_AWAITING = 0;
// export const KOT_ITEM_STATUS_STARTED = 1;
// export const KOT_ITEM_STATUS_DONE = 2;

function itemStatus(status) {
    switch (status) {
        case KOT_ITEM_STATUS_AWAITING: return "Awaiting";
        case KOT_ITEM_STATUS_STARTED: return "Started";
        case KOT_ITEM_STATUS_DONE: return "Done";
        default: return "Unknown";
    }
}

function itemIcon(status) {
    switch (status) {
        case KOT_ITEM_STATUS_AWAITING: return OverflowMenuHorizontal24;
        case KOT_ITEM_STATUS_STARTED: return Time24;
        case KOT_ITEM_STATUS_DONE: return Checkmark24;
        default: return Unknown24;
    }
}

function subtitle(order) {
    if (order.type === REST_ORDER_TYPE.DINE_IN) {
        return 'Table ' + order.tableName
    } else if (order.type === REST_ORDER_TYPE.TAKE_OUT) {
        if (Util.isStringExists(order.memo)) {
            return order.memo
        } else {
            return 'no memo'
        }
    } else if (order.type === REST_ORDER_TYPE.DELIVERY || order.type === REST_ORDER_TYPE.PICK_UP) {
        if (order.shippingAddress && (Util.isStringExists(order.shippingAddress.firstName) || Util.isStringExists(order.shippingAddress.lastName))) {
            const fullName = [order.shippingAddress.firstName, order.shippingAddress.lastName].filter(Util.isStringExists).join(" ");
            return fullName;
        } else {
            return "no name";
        }
    } else {
        return null;
    }
}

function OrderPreferencesView({ order }) {
    const preferences = order.preferences;
    const data = useMemo(() => JSON.parse(preferences ?? "[]"), [preferences])
    const shouldPrint = $ => true;

    const items = data?.filter?.(shouldPrint) ?? [];

    if (!items.length) {
        return null;
    }

    return <>
        <Line />
        <Line />
        <div style={{ border: '4px solid black', width: '100%' }}>
            {items.map((item, index) => <Fragment key={item.id}>
                {index > 0 ? <Line /> : null}
                <Text underline size={20}>{item.label}</Text>
                <Text size={25}>{typeof item.value === "boolean" ? (item.value ? 'Yes' : 'No') : item.value}</Text>
            </Fragment>)}
        </div>
    </>
}

export const KotPrint = ({ request: { kot, order, filterClasses, bottomText, ...request } }) => {
    const orderDate = getRestOrderDate(order);
    let orderNote = getRestOrderNote(order);

    const kotItems = useMemo(() => {
        if (filterClasses?.length && kot?.itemGroups?.length) {
            const filterValues = filterClasses.map($ => $.label)
            const visible = kot.itemGroups.filter(group => filterValues.includes(group.name));
            const hidden = kot.itemGroups.filter(group => !filterValues.includes(group.name))

            if (hidden.length) {
                return [...visible, {
                    id: Util.newTempId(), items: [], name: (
                        hidden.length === 1 ?
                            `${hidden.length} class hidden` :
                            `${hidden.length} classes hidden`
                    )
                }]
            } else {
                return visible;
            }
        } else {
            return kot.itemGroups;
        }
    }, [kot, filterClasses])



    // orderNote = "this is the n toe!!!"
    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            <Line />
            <FullDivider />
            <Text center size={30}>KOT - Order #{order.orderNo}</Text>
            <FullDivider />

            <Line />
            <Line />
            <Line />
            <Line />
            <Text underline center size={40}>{restOrderTypeName(order.type)}</Text>
            {/* <Text center size={40}>Order #{order.orderNo}</Text> */}
            <Text center size={40}>KOT #{kot.kotNo}</Text>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 2 }}>
                    <Text underline size={25}>Customer</Text>
                    <Text size={35}>{(Util.isStringExists(order.customerName) && order.customerName !== 'Guest') ? order.customerName.trim() : getAddressCustomerName(order.shippingAddress)}</Text>
                </div>

                {order.type === REST_ORDER_TYPE.DINE_IN ? (
                    <div style={{ flex: 1 }}>
                        <Text right underline size={25}>Table</Text>
                        <Text right size={35}>{order.tableName}</Text>
                    </div>
                ) : order.type === REST_ORDER_TYPE.PICK_UP && Util.isStringExists(order.memo) ? (
                    <div style={{ flex: 1 }}>
                        <Text right underline size={25}>Memo</Text>
                        <Text right size={35}>{order.memo}</Text>
                    </div>
                ) : null}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 2 }}>
                    <Text underline size={25}>Staff</Text>
                    <Text size={35}>{request.__printed_by}</Text>
                </div>
            </div>
            {Util.isNumberExist(orderDate) && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 1 }}>
                    <Text size={35}>{Util.formatDate(orderDate, "DD.MMM.YY")}</Text>
                </div>
                <div style={{ flex: 1 }}>
                    <Text right size={35}>{Util.formatDate(orderDate, "hh:mm A")}</Text>
                </div>
            </div>}

            <Line />
            <Line />

            <FullDivider />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ flex: 1 }}>
                    <Text size={30}>Qty</Text>
                </div>
                <div style={{ flex: 1 }}>
                    <Text size={30}>Item</Text>
                </div>
            </div>
            <FullDivider />
            <Line />
            {kotItems.map(group => (<>
                <div key={group.id}>
                    {/* <Text center size={35}>{group.name}</Text> */}

                    {group.items.map(item => (<>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                            <div style={{ marginRight: 30 }}>
                                <Text size={40} strike={item.flagRemovedItemRecord}>{item.qty}</Text>
                            </div>
                            <div style={{ flex: 1 }}>
                                <Text size={40} strike={item.flagRemovedItemRecord}>{item.name}</Text>
                                {Util.isStringExists(item.recipeOptionSummary) && (
                                    <Text size={40}>{item.recipeOptionSummary}</Text>
                                )}
                                <FullDivider />
                            </div>
                        </div>
                        <Line />
                    </>))}
                </div>
            </>))}

            <OrderPreferencesView order={order} />

            {Util.isStringExists(orderNote) && <>
                <Line />
                <Line />
                <div style={{ display: 'flex', justifyContent: 'space-between', border: '4px solid black' }}>
                    <div style={{ flex: 2 }}>
                        <Text underline size={25}>Note</Text>
                        <Text size={35}>{orderNote}</Text>
                    </div>
                </div>
            </>}

            <Line />
            <Line />

            <Text center size={20}>Printed on {request.__current_date}</Text>
            {bottomText && <Text center size={20}>{bottomText}</Text>}

            <Line />
        </div>
    )


    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            <Line />
            <CenterBold>{request.__company_name} - KOT</CenterBold>
            <CenterLgBold>{restOrderTypeName(order.type)}</CenterLgBold>
            <Line />


            <Bold>{"#" + weightText(kot.kotNo, 6, true) + " " + weightText(subtitle(order), 34, false)}</Bold>
            {/* <Divider /> */}
            <Line />

            {Util.isStringExists(orderNote) && (<>
                <LgBold>Note: {orderNote}</LgBold>
                <Line />
            </>)}

            {kotItems.map(group => (<>
                <div key={group.id} st>
                    <CenterMdBold>{group.name}</CenterMdBold>

                    {group.items.map(item => (<>
                        <div>


                            <LgNormal>{item.name}</LgNormal>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {React.createElement(itemIcon(item.status))}
                                <Normal> </Normal>
                                <Bold>{itemStatus(item.status)}</Bold>
                            </div>

                            <LgBoldRight>{item.qty}x</LgBoldRight>

                            {Util.isStringExists(item.recipeOptionSummary) && (
                                <LgBold>{item.recipeOptionSummary}</LgBold>
                            )}
                        </div>
                        <Divider />
                        <Line />
                        <Line />
                    </>))}
                </div>
            </>))}

            <CenterNormal>As of {request.__current_date}</CenterNormal>
            <CenterNormal>Printed By {request.__printed_by}</CenterNormal>
            <Line />
        </div>
    )

}

function weightText(text, weight, leftAligned) {
    if (text == null) {
        text = "";
    }

    if (!text || !text.substring) {
        text = text + "";
    }

    if (text.length > weight) {
        text = text.substring(0, weight - 3) + "...";
    } else {
        const textLength = text.length;
        for (let i = 0; i < (weight - textLength); i++) {
            if (leftAligned) {
                text += ' ';
            } else {
                text = " " + text;
            }
        }
    }
    return text;
}