import { withLoadablePagePropsAndProps } from "../../../base/Page";
import ProfilePic from "../../../components/ProfilePic";
import { MinimalTable } from "../../../components/minimal-table";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";
import Api from "../../../session/Api";
import { Link } from 'react-router-dom'
import Util from "../../../util/Util";

const COLUMNS = [
    {
        name: "user",
        title: "User",
        flex: 1,
        renderCell: item => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }}>
                <ProfilePic src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.userId)} size={32} />
                <Link target="_blank" to={"/staff-users/" + item.userId}>
                    {item.user}
                </Link>
            </div>
        ),
    },
    {
        name: "date",
        title: "Date",
        flex: 1,
        renderCell: item => Util.getDateOnly(item.date),
    },
    {
        name: "time",
        title: "Time",
        flex: 1,
        renderCell: item => item.time,
    },
    {
        name: "staus",
        title: "Status",
        flex: 1,
        renderCell: item => item.statusSet,
    },
    {
        name: "note",
        title: "Action Note",
        flex: 2,
        renderCell: item => item.note,
    },
]


function View({ payload }) {

    return (
        <div style={{ paddingBlock: '1rem', }}>
            <MinimalTable
                columns={COLUMNS}
                items={payload}
                large
            />
        </div>
    )

}


export const OrderHistory = withLoadablePagePropsAndProps(props => listener => Api.getRestSalesOrderHistoryOverview(props.salesOrder?.id, listener), View);